import { withLDConsumer } from 'ldclient-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IEntity, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import { sendAbandon, shapeUtility } from '../../../utility/helper';
import { validateWebsite } from '../../../utility/validation';
import { Button } from '../../ui/Button';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

const entityTypes = {
  Corporation: 'Corporation',
  LimitedLiabilityCompany: 'Limited Liability Company',
  NonProfitOrganization: 'Non-Profit Organization',
  Partnership: 'Partnership',
  SoleProprietor: 'Sole Proprietor',
};
const entityTypesList = Object.values(entityTypes);
const publicCompanies = [entityTypes.NonProfitOrganization];

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IBusinessInformationState {
  businessName: string;
  businessWebsite: string;
  businessPublic: boolean;
  businessType: string;
  showCheckbox: boolean;
  valid: boolean;
  websiteError: string;
  showRequired: boolean;
  requiredMsg: string;
  forceBizPublicSelection: boolean;
  userBizPublicSelection: boolean;
}

export class BusinessInformation extends Component<
  IMerchantSignupProps,
  IBusinessInformationState
> {
  state: IBusinessInformationState = {
    businessName: '',
    businessPublic: false,
    businessType: '',
    businessWebsite: '',
    forceBizPublicSelection: false,
    requiredMsg: '*Required',
    showCheckbox: true,
    showRequired: false,
    userBizPublicSelection: false,
    valid: false,
    websiteError: '',
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (this.props.form && this.props.form.entity) {
      const { entity } = this.props.form;
      this.setState({
        businessName: entity.name ? entity.name : '',
        businessPublic: entity.public ? entity.public : false,
        businessType: entity.type ? entity.type : '',
        businessWebsite: entity.website ? entity.website : '',
        forceBizPublicSelection: publicCompanies.includes(entity.type || ''),
        userBizPublicSelection: entity.public ? entity.public : false,
      });
      this.inputValidation({
        ...this.state,
        ...{
          businessName: entity.name ? entity.name : '',
          businessPublic: entity.public ? entity.public : false,
          businessType: entity.type ? entity.type : '',
          businessWebsite: entity.website ? entity.website : '',
        },
      });
    }
  }

  updateForm = (state: IBusinessInformationState) => {
    const {
      businessName,
      businessPublic,
      businessType,
      businessWebsite,
    } = state;
    const { form } = this.props;
    const entity: IEntity = shapeUtility(form);
    entity.name = businessName ? businessName : entity.name;
    entity.type = businessType ? businessType : entity.type;
    entity.public = businessPublic ? true : false;
    entity.website = businessWebsite;
    return { ...form, entity };
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    if (this.state.valid) {
      const updateForm: ISignUpForm = this.updateForm(this.state);
      sendAbandon(updateForm, this.props.flags, this.props.preloadedFlags);
      this.props.nextPage(updateForm);
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  handleBizWebsiteChange = (event: any) => {
    let result = false;
    const businessWebsite = event.target.value.replace(/\s/g, '');
    this.setState({ businessWebsite });
    if (validateWebsite(event.target.value)) {
      this.setState({ websiteError: '' });
      result = true;
    } else {
      this.setState({ websiteError: '* Invalid website' });
    }
    this.inputValidation({ ...this.state, businessWebsite });
    return result;
  };

  handleBizNameChange = (event: any) => {
    this.setState({ businessName: event.target.value });
    if (!event.target.value) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation({ ...this.state, businessName: event.target.value });
    return true;
  };

  handleBizTypeChange = (event: any) => {
    const forcePublicCompany = publicCompanies.includes(event.target.value);
    this.setState({
      businessPublic: this.state.userBizPublicSelection || forcePublicCompany,
      forceBizPublicSelection: forcePublicCompany,
      showCheckbox: forcePublicCompany ? false : true,
    });
    this.setState({ businessType: event.target.value });
    this.inputValidation({ ...this.state, businessType: event.target.value });
    return (
      event.target.value !== '0' && entityTypesList.includes(event.target.value)
    );
  };

  handleBizPublic = (event: any) => {
    this.setState({
      businessPublic: event.target.checked,
      userBizPublicSelection: event.target.checked,
    });
    this.inputValidation({
      ...this.state,
      businessPublic: event.target.checked,
    });
    return event.target.checked;
  };

  inputValidation = async (state: IBusinessInformationState) => {
    let result = false;
    const { businessName, businessType, businessWebsite } = state;
    if (
      !businessName ||
      !businessType ||
      !entityTypesList.includes(businessType) ||
      !validateWebsite(businessWebsite)
    ) {
      this.setState({ valid: false });
    } else if (
      businessName &&
      businessName.length <= 100 &&
      validateWebsite(businessWebsite)
    ) {
      this.setState({ valid: true });
      result = true;
    } else {
      this.setState({ valid: false });
    }
    return result;
  };

  render() {
    const { websiteError, requiredMsg } = this.state;
    return (
      <>
        <TitleSubtitle title='Tell us about your business.' subtitle='' />
        <form>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${styles.inputfield}`}
              type='text'
              maxLength={100}
              minLength={1}
              placeholder='Business name'
              value={this.state.businessName}
              onChange={this.handleBizNameChange}
            />
          </div>
          {!this.state.businessName && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <select
              id='bizType'
              className={`form-control input-lg pl-3 mt-3 mb-2 text-left ${styles.inputfield}`}
              value={this.state.businessType}
              onChange={this.handleBizTypeChange}
            >
              <option value='0'>Business type</option>
              <option value='Corporation'>Corporation</option>
              <option value='Limited Liability Company'>
                Limited Liability Company
              </option>
              <option value='Partnership'>Partnership</option>
              <option value='Non-Profit Organization'>
                Non-Profit Organization
              </option>
            </select>
          </div>
          {!entityTypesList.includes(this.state.businessType) &&
            this.state.showRequired && (
              <div className={styles.msg}>
                <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
              </div>
            )}
          <div className={`input-group ml-1 my-3 h-4 ${styles.msg}`}>
            <label>
              <input
                type='checkbox'
                disabled={this.state.forceBizPublicSelection}
                checked={this.state.businessPublic}
                onChange={this.handleBizPublic}
              />
              <h4
                className={`pl-3 ${styles.inlineBlock} ${styles.textSecondary}`}
              >
                Public company
              </h4>
            </label>
          </div>
          <div className='input-group mb-1 h-4'>
            <input
              className={`input form-control input-lg pl-4 my-3 text-left ${styles.inputfield}`}
              type='text'
              placeholder='Website (optional)'
              minLength={2}
              maxLength={100}
              value={this.state.businessWebsite}
              onChange={this.handleBizWebsiteChange}
            />
          </div>
          <div>
            <h6 className={`text-danger ml-1 ${styles.msg}`}>{websiteError}</h6>
          </div>
          <div>
            <h4 className={`ml-1 ${styles.msg} ${styles.textSecondary}`}>
              Please include 'http://' or 'https://' in your website URL.
            </h4>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={3} incomplete={4} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(BusinessInformation));
