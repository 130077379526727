import React from 'react';
import * as styles from './styles.css';

const Norton = () => {
  return (
    <div className={styles.security}>
      <img
        className={`text-center input-group mb-3 h-4 mx-2 row`}
        src='/images/security.png'
      />
    </div>
  );
};

export default Norton;
