// tslint:disable:jsx-no-lambda
import React, { useState } from 'react';
import * as styles from './styles.css';

interface IToolTipProps {
  label?: string;
  content: string[] | string;
}

const ToolTip: React.FunctionComponent<IToolTipProps> = ({
  label,
  content,
}) => {
  const [clicked, OnClick] = useState(false);

  return (
    <div className={styles.icon} onClick={() => OnClick(!clicked)}>
      {clicked ? <Modal label={label} content={content} /> : <div />}
    </div>
  );
};

const renderArrContent = (content: string[]) => {
  return content.map((info: string) => {
    return <p key={info}>{info}</p>;
  });
};

const Modal: React.FunctionComponent<IToolTipProps> = ({ label, content }) => {
  return (
    <>
      <div className={styles.tooltip}>
        <div className={styles.tooltipArrow} />
        <h4 className={styles.tooltipLabel}>{label}</h4>
        <div>
          {Array.isArray(content) ? renderArrContent(content) : content}
        </div>
      </div>
    </>
  );
};

export default ToolTip;
