import * as React from 'react';
import * as styles from '../../../styles/form.css';
import { NavBar } from '../../MerchantSignup/NavBar';

export default (props: any) => {
  return (
    <>
      <NavBar />
      <div className={styles.msg}>
        <h1 className={styles.textPrimary}>
          Express Tech-Financing, LLC Privacy Policy:
        </h1>
        <h4 className={styles.textSecondary}>Updated 1/21/2022</h4>
        <p>
          This website is owned and operated by Express Tech-Financing, LLC dba
          Currency. We want you to understand what information we gather about
          you, how we use it and the safeguards we have in place to protect it.
          This Privacy Policy applies to information collected through this
          website and otherwise. By using this website and our services, through
          an advertisement or linking from a third party site, you consent to
          this Privacy Policy, including your consent to our use and disclosure
          of information about you as required under current Federal, State,
          and/or local law, as well as in the manner described below in this
          Privacy Policy.
        </p>
        <p>
          Any disputes arising from use of this site and/or Currency services,
          is subject to this Privacy Policy as well as the website&rsquo;s Terms
          of Use and all of its dispute resolution provisions, including
          arbitration, limitation on damages and choice of law.
        </p>
        <p>
          This website is a general audience site and we do not knowingly
          collect personal information from children under the age of 13. For
          more information about the Children&rsquo;s Online Privacy Protection
          Act (COPPA), view the FTC website at{' '}
          <a href='http://ftc.gov/'>www.ftc.gov</a>.
        </p>
        <p>
          Information We Collect. We collect the following categories of
          personally identifiable information about you: Identifiers, such as
          your name, address, telephone number, mobile number, e-mail address,
          IP address, unique personal identifier, business name, Social Security
          Number, date of birth, driver&rsquo;s license number, passport number,
          employment information, bank account information, credit sore and
          related information in credit reports, landlords&rsquo; names,
          geolocation, network activity, browsing history, search history and
          information regarding your interactions with websites, applications or
          advertisements ("Personally Identifiable Information" or "PII"). We
          also collect information such as demographic data and data about your
          online activity on our website that does not identify you
          ("Non-Personally Identifiable Information" or "Non-PII"). PII and
          Non-PII are known together as "Information". We may also collect and
          store Information that you provide to us about other people (such as
          co-borrowers), including their name, address, telephone number, mobile
          devices, email address and Social Security Number.
        </p>
        <p>
          We use Plaid Inc. ("Plaid") to gather your data from financial
          institutions. By using our service, you grant us and Plaid the right,
          power, and authority to act on your behalf to access and transmit your
          personal and financial information from the relevant financial
          institution. You agree to your personal and financial information
          being transferred, stored, and processed by Plaid in accordance with
          the Plaid Privacy Policy.{' '}
          <a href='https://plaid.com/legal'>https://plaid.com/legal</a>. We also
          collect information about you from other 3<sup>rd</sup> party sources
          including from you, credit reporting agencies, public records,
          internet activities through cookie use and from identity verification
          partners.
        </p>
        <h3 className={styles.textSecondary}>
          <strong>1. How We Collect Information About You.</strong>
        </h3>
        <p>
          a. From You. We collect Information you provide to us: (i) in
          connection with inquiring about or obtaining our services; and (ii) in
          connection with a job application. The Information we collect from you
          will vary depending on the product or service requested.
        </p>
        <p>
          b. Other Sources. We may obtain Information about you from third
          parties such as: credit reports, information about your home and
          demographic information such as Social Security Number (SSN), date of
          birth (DOB), address and phone number may be verified by third parties
          to assist in the prevention of identity theft, consumer identification
          and verification. Currency takes your privacy seriously and performs
          these checks using best practices according to industry standards and
          does not alter or change the information you provide in your inquiry
          form. Your information is protected both in-transit to our servers and
          at rest on our servers by the latest security standards &amp;
          protocols. In addition, our networks and servers are constantly
          monitored for &lsquo;advanced, persistent&rsquo; and other threats to
          your information.
        </p>
        <p>
          c. Automatic Collection, Cookies and Other Internet Technologies. We
          use cookies, web beacons and other standard internet technologies to
          collect information from your computer and about your activities on
          our website. We automatically collect Non-PII from your browser when
          you visit our website. This information includes, but is not
          necessarily limited to, your IP address, your browser type and
          language, access times, the content of any undeleted cookies that your
          browser previously accepted from us and the referring website address.
        </p>
        <p>
          A cookie is a small text file that is stored on a user&rsquo;s
          computer for record keeping purposes. When you visit our website, we
          may assign your computer one or more cookies to facilitate access to
          our website, personalize your online experience and record information
          about your visit. Through the use of a cookie, we also may
          automatically collect information about your online activity on our
          website, such as the web pages you visit, the links or advertisements
          you click on and other actions you take while on our website. The
          cookies do not store any PII about you. Most browsers automatically
          accept cookies, but you can usually modify your browser setting to
          decline cookies. If you choose to decline cookies, please note that
          some of the features and services of our website may not function
          properly. We also use cookies for purposes such as maintaining
          continuity during an online session; gathering data about the use of
          our site; monitoring online promotions; and anti-fraud and information
          security purposes.
        </p>
        <p>
          We may allow third-parties, advertising companies and ad networks
          ("Advertisers") to display advertisements on our website. Advertisers
          may use tracking technologies, such as cookies, to collect information
          about users who view or interact with their advertisements. Our
          website does not provide any PII to these Advertisers, but they may
          collect information about where you, or others who are using your
          computer, saw and/or clicked on the advertisements they delivered, and
          possibly associate this information with your subsequent visits to the
          advertised websites. They also may combine this information with
          personal information they collect directly from you. The collection
          and use of that personal information is subject to the particular
          Advertiser&rsquo;s privacy policy. Some of these Advertisers may be
          members of the Network Advertising Initiative, which offers a single
          location to opt out of ad targeting from member companies.
        </p>
        <p>
          Other Technologies. We may use web beacons and other standard Internet
          technologies to track your use on our websites. Web beacons (also
          known as clear gifs, pixel tags, targeting and re-targeting pixels,
          third party pixels or web bugs) are tiny graphics with a unique
          identifier, similar in function to cookies, and are used to track the
          online movements of web users or to access cookies. We also may
          include web beacons in e-mail messages or newsletters to determine
          whether messages have been opened and acted upon.
        </p>
        <br />
        <h3 className={styles.textSecondary}>
          <strong>2. How We Use Information.</strong>
        </h3>
        <h4 className={styles.textSecondary}>
          <strong>We do not sell your personal information.</strong>
        </h4>
        <p>
          a. In General. We may use Information to:
          <br />
          Deliver the products and services you request;
          <br />
          Manage your customer relationship and provide you with customer
          support;
          <br />
          Research and analyze your use of, or interest in, our products,
          services and content and the products, services and content offered by
          others;
          <br />
          Communicate with you via e-mail, telephone, postal mail and/or mobile
          devices about products or services that may be of interest to you;
          <br />
          Develop and display content and services tailored to your interests on
          our website and other websites;
          <br />
          Develop and deliver targeted advertisements;
          <br />
          Measure the overall effectiveness of our online advertising, content,
          programming and other activities;
          <br />
          Verify your eligibility and deliver prizes in connection with
          promotions, contests and sweepstakes;
          <br />
          Manage our business; and
          <br />
          Perform functions as described to you at the time of collection.
        </p>
        <p>
          b. Financial Information. Depending on the service requested, we may
          use financial information to: match you with lenders, provide you with
          lenders&rsquo; conditional loan offers, auto dealers, auto lenders,
          personal loan providers, etc. as well as to verify your identity,
          protect against or identify possible fraudulent transactions, or
          otherwise as needed to manage our business.
          <br />
          c. Job Applicants. If your PII is submitted when applying for a
          position with us, the information will be used solely in connection
          with considering and acting upon your application. We may retain your
          PII for a period of time, but only for the purpose of considering your
          application for current or future available positions or as required
          by law.
        </p>
        <h4 className={styles.textSecondary}>
          <strong>3. With Whom we Share Your Information.</strong>
        </h4>
        <p>
          a. Currency Businesses. We may share your Information with Currency
          Businesses (which include our affiliates). All Currency Businesses
          adhere to this Privacy Policy. Except as described in this policy, we
          will not disclose your PII to others outside of the Express
          Tech-Financing Businesses.
        </p>
        <p>
          b. Lenders or Matching Service Providers. In submitting an inquiry for
          a credit product or other service offered through this website, when
          you click on any button indicating an acceptance or agreement to
          terms, a continuance of processing or submission, you signify your
          agreement to proceed you are consenting, acknowledging and agreeing to
          the disclosure of your PII, as described herein, with lenders in our
          network ("Lenders") with whom you are or may be matched. NOTE THAT
          even if you do not provide your Social Security number, we may access
          your credit file to assist in the prevention of identity theft and to
          verify your request is accurate and complete. Lastly, not all network
          "Lenders" will obtain your SSN and each "Lender" has its own
          requirements, disclosures and consent requirements.
        </p>
        <p>
          BE AWARE that the Lenders with whom you are matched may retain or use
          your Information whether or not you use their services and you should
          contact these Lenders directly concerning their privacy and
          information sharing practices which may differ from Express
          Tech-Financing.
        </p>
        <p>
          c. Referrals for your Business. When you express interest in other
          products, services, promotions that we offer (by, for example,
          completing an inquiry for such services), we will share your request
          or expression of interest with the businesses with which we partner to
          offer you those products, services and promotions, and, as applicable,
          with their affiliates and third party finder or marketing companies
          (collectively, "Business Providers"). When you choose to participate
          in a particular offer or program, you authorize us to provide PII to
          those Business Providers. Be aware the Business Providers with whom we
          share your Information may retain or use your Information whether or
          not you use their services and you should contact these parties
          directly concerning their privacy and information sharing practices
          which may differ from ours.
        </p>
        <p>
          d. Services Providers. We may share your personal information with our
          authorized service providers that perform certain services on our
          behalf including, but not limited to service providers that perform
          services on our behalf in connection with your request for a credit
          product or the funding, sale and/or servicing of your credit product.
          These third parties include, without limitation Lenders, credit
          reporting agencies, title companies, flood or other insurance
          companies, appraisal companies, closing agents, marketing and customer
          services firms, credit card, or entities that provide business
          analysis or website support. These third parties may have access to
          PII needed to perform their functions.
        </p>
        <p>
          e. Regulatory/Government Agencies. We may share your PII without
          notice to you with regulators in the normal course of our compliance
          with federal, state and local law, in response to any legal process,
          to enforce any agreement between you and us or to detect and/or
          prevent fraud.
        </p>
        <p>
          <strong>For Vermont Residents: </strong>We will not share information
          we collect about you with nonaffiliated third parties, except as
          permitted by Vermont law, such as to process your transactions or to
          maintain your account. In addition, we will not share information
          about your creditworthiness with our affiliates.
        </p>
        <p>
          <strong>For California Residents: </strong>If you are a California
          resident, you may be entitled to additional rights over your personal
          information. For more information about those rights and how to
          exercise them, please click this{' '}
          <a
            href='https://www.gocurrency.com/ccpa-privacy-policy/'
            target='_blank'
          >
            link
          </a>{' '}
          to our California privacy notice, which is incorporated into this
          Privacy Policy.
        </p>
        <p>
          <strong>Contacting Us:</strong>
        </p>
        <p>
          If there are any questions regarding this privacy policy you may
          contact us{' '}
          <a href='mailto:customerservice@gocurrency.com'>
            customerservice@gocurrency.com
          </a>{' '}
          or at the address below:
        </p>
        <p>
          Express Tech-Financing, LLC dba Currency
          <br />
          8930 East Raintree Drive
          <br />
          Scottsdale, AZ 85260
          <br />
          United States
        </p>
      </div>
    </>
  );
};
