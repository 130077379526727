import React from 'react';
import { removeSpecialCharsAndSpace } from '../../../utility/helper';
import * as styles from './styles.css';

interface ICheckBoxProps {
  children?: any;
  disabled?: boolean;
  label: string;
  isChecked: boolean;
  onChange: (e: any) => void;
}

const CheckBox: React.FunctionComponent<ICheckBoxProps> = ({
  children,
  disabled = false,
  label,
  isChecked,
  onChange,
}) => {
  return (
    <div className={styles.checkbox}>
      <label>
        <input
          id={removeSpecialCharsAndSpace(label)}
          className={styles.box}
          type='checkbox'
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className={styles.label}>
          {label} {children}
        </span>
      </label>
    </div>
  );
};

export default CheckBox;
