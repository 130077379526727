import { withLDProvider } from 'ldclient-react';
import React from 'react';
import { hot } from 'react-hot-loader';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';

// Import app-wide settings and tools such as tag styles and home made mix-ins (Component-specific CSS is kept adjacent to component code)
// NOTE -- this should appear before other components are imported, to ensure that global styles appear higher up in the document
import '../styles/styles.css';
import { WithAnalytics } from './Analytics';
import LeadIntake from './LeadIntake';
import Privacy from './Legal/Privacy/Privacy';
import Terms from './Legal/Terms/Terms';
import { MerchantSignup } from './MerchantSignup';

interface IAppProps {
  location: {
    search: string;
    pathname: string;
  };
  match: {
    path: string;
    isExact: boolean;
    params: {
      [key: string]: string;
    };
  };
  history: any;
}

const merchantsignup = (props: any) => (
  <MerchantSignup {...props} location={props.location} />
);

function createApp(clientSideID: string, user: { key: string }) {
  const App: React.FunctionComponent<IAppProps> = (props: any) => (
    <>
      <WithAnalytics
        pathname={props.location.pathname}
        search={props.location.search}
      >
        <Switch>
          <Route exact path='/merchant/signup/' component={merchantsignup} />
          <Route exact path='/levelup' component={LeadIntake} />
          <Route exact path='/merchant/terms' component={Terms} />
          <Route exact path='/merchant/privacy' component={Privacy} />
          <Redirect to='/merchant/signup/' />
        </Switch>
      </WithAnalytics>
    </>
  );

  return compose(
    hot(module),
    withLDProvider({
      clientSideID,
      user,
    }),
    withRouter,
  )(App);
}

export default createApp;
