import React from 'react';
import * as styles from '../../../styles/form.css';

interface IRadioProps {
  identifier: string;
  onChange: (e: any) => void;
  options: ISelectOptions[];
  value: string | number;
}

interface ISelectOptions {
  label: string | number;
  value: string | number;
}

const Select: React.FunctionComponent<IRadioProps> = ({
  identifier,
  options,
  onChange,
  value,
}) => {
  const renderSelectOptions = (options: ISelectOptions[]) => {
    const result: any = [];
    options.map((option: any) => {
      result.push(
        <option key={option.label} value={option.value}>
          {option.label}
        </option>,
      );
    });
    return result;
  };
  return (
    <div className='input-group h-4'>
      <select
        id={identifier}
        className={`form-control input-lg mt-3 mb-2 ${styles.inputfield}`}
        onChange={onChange}
        value={value}
      >
        {renderSelectOptions(options)}
      </select>
    </div>
  );
};

export default Select;
