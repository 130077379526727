import React, { useEffect } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { formatPhone } from '../../../utility/helper';
import * as mainStyles from '../styles.css';

interface IContactInfoProps {
  email: string;
  errors: any;
  first: string;
  goBack: any;
  handleChange: any;
  last: string;
  next: any;
  phone: string;
  submitted: boolean;
  validation: any;
}

export const ContactInfo: React.FunctionComponent<IContactInfoProps> = props => {
  const { errors, submitted } = props;

  useEffect(() => {
    props.validation();
  });

  return (
    <div>
      <h1
        className={`mt-4 ${mainStyles.textPrimary} ${mainStyles.regularWeight} text-center`}
      >
        Let's Talk
      </h1>
      <h3
        className={`${mainStyles.textFont} ${mainStyles.textSecondary} mx-5 text-center`}
      >
        Tell us a little about yourself.
      </h3>
      <form>
        <div className='input-group h-4'>
          <DebounceInput
            className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
            type='text'
            maxLength={100}
            placeholder='First Name'
            value={props.first}
            name='first'
            onChange={props.handleChange}
          />
        </div>
        <div className={mainStyles.msg}>
          <h6 className='text-danger ml-1 mb-0'>
            {errors && errors.first && submitted ? errors.first : ''}
          </h6>
        </div>
        <div className='input-group h-4'>
          <DebounceInput
            className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
            type='text'
            maxLength={100}
            placeholder='Last Name'
            value={props.last}
            name='last'
            onChange={props.handleChange}
          />
        </div>
        <div className={mainStyles.msg}>
          <h6 className='text-danger ml-1 mb-0'>
            {errors && errors.last && submitted ? errors.last : ''}
          </h6>
        </div>
        <div className='input-group h-4'>
          <DebounceInput
            className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
            type='text'
            maxLength={100}
            placeholder='Email Address'
            value={props.email}
            name='email'
            onChange={props.handleChange}
          />
        </div>
        <div className={mainStyles.msg}>
          <h6 className='text-danger ml-1 mb-0'>
            {errors && errors.email && submitted ? errors.email : ''}
          </h6>
        </div>
        <div className='input-group h-4'>
          <DebounceInput
            className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
            type='text'
            maxLength={12}
            placeholder='Phone Number'
            value={formatPhone(props.phone.replace(/\D/g, ''))}
            name='phone'
            onChange={props.handleChange}
          />
        </div>
        <div className={mainStyles.msg}>
          <h6 className='text-danger ml-1 mb-0'>
            {errors && errors.phone && submitted ? errors.phone : ''}
          </h6>
        </div>
        <button
          className={`btn btn-primary btn-lg btn-block mx-auto ${mainStyles.nextBtn}`}
          type='submit'
          onClick={props.next}
        >
          Submit
        </button>
        <div className='text-center mt-2'>
          <h3>
            <u
              className={`${mainStyles.textPrimary} ${mainStyles.pointer}`}
              onClick={props.goBack}
            >
              Previous
            </u>
          </h3>
        </div>
      </form>
    </div>
  );
};
