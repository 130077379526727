import React from 'react';
import reactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createProcessEnv } from '../config/process';

import createAppComponent from '../components/App';
import configureStore from '../store';

// Grab the state from a global variable injected into the HTML generated by webpack
const preloadedState = (window as any).__PRELOADED_STATE__ || {};
delete (window as any).__PRELOADED_STATE__; // cleanup

// If there is no preloaded state from the server, populate state.processEnv with default values
if (!preloadedState.processEnv) {
  preloadedState.processEnv = createProcessEnv({});
}

// Pass in preloaded state as initialState
const NODE_ENV =
  preloadedState.processEnv && preloadedState.processEnv.NODE_ENV
    ? preloadedState.processEnv.NODE_ENV
    : 'development';
const store = configureStore(NODE_ENV, preloadedState);

const AppComponent: any = createAppComponent(
  preloadedState.processEnv.LAUNCH_DARKLY.clientSideKey,
  preloadedState.processEnv.LAUNCH_DARKLY.user,
);

// Use 'hydrate' method with server rendering in production, 'render' method with server rendering in dev
const renderMethod =
  process.env.NODE_ENV === 'development' ? 'render' : 'hydrate';

reactDOM[renderMethod](
  <Provider store={store}>
    <BrowserRouter>
      <AppComponent />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// Hot module replacement logic to accept updates from webpack-dev-server
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept();
  module.hot.accept('../reducers', () => {
    store.replaceReducer(require('../reducers').default);
  });
}
