import React from 'react';
import * as mainStyles from '../../../styles/form.css';

interface ITitleSubtitleProps {
  subtitle?: any;
  subtitle2?: string;
  title: string;
  titleToolTip?: any;
}

const TitleSubtitle = (props: ITitleSubtitleProps) => {
  return (
    <div className='container'>
      <h1 className={mainStyles.textPrimary}>
        {props.title} {props.titleToolTip}
      </h1>
      <h3 className={mainStyles.textSecondary}>{props.subtitle}</h3>
      <h3 className={mainStyles.textSecondary}>{props.subtitle2}</h3>
    </div>
  );
};

export default TitleSubtitle;
