import * as LD from 'ldclient-js';

let ldclient: any;

async function init(LAUNCH_DARKLY: any) {
  if (!ldclient) {
    ldclient = LD.initialize(LAUNCH_DARKLY.clientSideKey, LAUNCH_DARKLY.user);
    await ldclient.waitForInitialization();
  }
}

export async function getFlags() {
  try {
    return ldclient.allFlags();
  } catch (err) {
    return {};
  }
}

export async function getFlag(flagName: string, defaultValue: any) {
  try {
    return ldclient.variation(flagName, defaultValue);
  } catch (err) {
    return defaultValue;
  }
}

export default async function(LAUNCH_DARKLY: any) {
  await init(LAUNCH_DARKLY);

  return {
    getFlag,
    getFlags,
  };
}
