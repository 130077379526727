import axios from 'axios';
import React from 'react';
import ReactGA from 'react-ga';
import {
  validateEmail,
  validateOfferCode,
  validatePhone,
} from '../../utility/validation';
import ContactInfo from './ContactInfo';
import OfferCode from './OfferCode';
import ThankYou from './ThankYou';

interface ILeadIntakeState {
  email: string;
  errors: {
    email: string;
    offerCode: string;
    phone: string;
  };
  first: string;
  last: string;
  offerCode: string;
  phone: string;
  slide: number;
  submitted: boolean;
  valid: boolean;
}

class LeadIntake extends React.Component<ILeadIntakeState> {
  state = {
    email: '',
    errors: {
      email: '',
      offerCode: '',
      phone: '',
    },
    first: '',
    last: '',
    offerCode: '',
    phone: '',
    slide: 0,
    submitted: false,
    valid: false,
  };
  componentDidMount() {
    // Crazy Egg tracking script - manually creating / attaching as it's only supposed to appear on this route (/levelup), and we're using HtmlWebpackPlugin so it would be tricky to conditionally include in the document head
    if (typeof window !== 'undefined' && document && document.head) {
      const trackingScript = document.createElement('script');
      trackingScript.src = '//script.crazyegg.com/pages/scripts/0085/7769.js';
      trackingScript.async = true;
      document.head.appendChild(trackingScript);
    }
  }

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
    this.runValidation(this.state);
    return true;
  };

  next = (event: any) => {
    event.preventDefault();
    this.runValidation(this.state);
    this.setState({ submitted: true });
    if (this.state.valid) {
      // since next() only handles the first step, it should be safe to fire this event here.
      ReactGA.event({
        action: 'stepCompleted',
        category: 'leadIntake - cPay',
        label: 'offerCode',
      });
      this.setState({ slide: this.state.slide += 1, submitted: false });
      return true;
    } else {
      return false;
    }
  };

  goBack = () => {
    this.setState({ slide: this.state.slide -= 1 });
    return true;
  };

  submit = async (event: any) => {
    event.preventDefault();
    this.runValidation(this.state);
    this.setState({ submitted: true });
    if (this.state.valid) {
      this.setState({ slide: this.state.slide += 1, submitted: false });
      const payload = formatPayload(this.state);
      const submitted = await axios.post('/api/currencypay/lead', payload);
      const response =
        submitted && submitted.data && submitted.data.statusCode === 200
          ? true
          : false;
      ReactGA.event({
        action: 'stepCompleted',
        category: 'leadIntake - cPay',
        label: 'contactInfo',
      });
      return response;
    } else {
      console.log('invalid');
      return false;
    }
  };

  runValidation = (state: ILeadIntakeState) => {
    if (state.slide === 0) {
      return this.offerCodeSlideValidation();
    }
    if (state.slide === 1) {
      return this.contactInfoValidation();
    }
  };

  offerCodeSlideValidation = () => {
    const { offerCode } = this.state;
    if (validateOfferCode(offerCode)) {
      this.setState({ valid: true, errors: { offerCode: '' } });
      return true;
    } else {
      this.setState({
        errors: { offerCode: 'Please enter a valid, 14-digit number.' },
        valid: false,
      });
      return false;
    }
  };

  contactInfoValidation = () => {
    const { email, first, last, phone } = this.state;
    const validEmail = validateEmail(email);
    const validPhone = validatePhone(phone);
    const validFirst = first.length > 1 ? true : false;
    const validLast = last.length > 1 ? true : false;
    this.setState({
      errors: {
        email:
          email.length === 0
            ? '* Required'
            : validEmail
            ? ''
            : '* Invalid Email',
        first: validFirst ? '' : '* Required',
        last: validLast ? '' : '* Required',
        phone:
          phone.length === 0
            ? '* Required'
            : validPhone
            ? ''
            : '* Invalid Phone',
      },
    });
    if (validEmail && validPhone && validFirst && validLast) {
      this.setState({ valid: true });
      return true;
    } else {
      this.setState({ valid: false });
      return false;
    }
  };

  render() {
    const { slide } = this.state;
    return (
      <div>
        <br />
        {slide === 0 ? (
          <OfferCode
            errors={this.state.errors}
            handleChange={this.handleChange}
            offerCode={this.state.offerCode}
            next={this.next}
            submitted={this.state.submitted}
          />
        ) : (
          ''
        )}
        {slide === 1 ? (
          <ContactInfo
            errors={this.state.errors}
            handleChange={this.handleChange}
            email={this.state.email}
            first={this.state.first}
            goBack={this.goBack}
            last={this.state.last}
            next={this.submit}
            phone={this.state.phone}
            submitted={this.state.submitted}
            validation={this.contactInfoValidation}
          />
        ) : (
          ''
        )}
        {slide === 2 ? <ThankYou /> : ''}
      </div>
    );
  }
}

export function formatPayload(state: ILeadIntakeState) {
  const { email, first, last, offerCode, phone } = state;
  const data = {
    email,
    first,
    last,
    offerCode,
    phone: phone.replace(/\D/g, ''), // double check cleaned phone format
  };
  return data;
}

export default LeadIntake;
