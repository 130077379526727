import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions, submitStatusActions } from '../../actions';

import { IMember, ISignUpForm } from '../../interfaces';
import { pages, SOLE_PROPRIETOR } from '../../utility/constants';
import { AdditionalOwnerContact } from './AdditionalOwnerContact';
import { AdditionalOwnership } from './AdditionalOwnership';
import { AdditionalOwnershipPersonal } from './AdditionalOwnershipPersonal';
import { AnnualCreditCardSales } from './AnnualCreditCardSales';
import { BankingInformation } from './BankingInformation';
import { BusinessInformation } from './BusinessInformation';
import { BusinessStatement } from './BusinessStatement';
import { FirearmsComplianceAck } from './FirearmsComplianceAck';
import { Footer } from './Footer';
import { LegalBusinessInfo } from './LegalBusinessInfo';
import { MoreBusinessInformation } from './MoreBusinessInformation';
import { NavBar } from './NavBar';
import { PaymentOptions } from './PaymentOptions';
import { PrimaryOwnerContact } from './PrimaryOwnerContact';
import { PrimaryOwnerPersonal } from './PrimaryOwnerPersonal';
import { PrimaryOwnership } from './PrimaryOwnership';
import { SetLogin } from './SetLogin';
import { Success } from './Success';
import { Terminal } from './Terminal';
import { Terms } from './TermsAndConditions';

import { withLDConsumer } from 'ldclient-react';
import {
  getMerchantQueryParams,
  paymentProcessingRequested,
  shapeUtility,
} from '../../utility/helper';

import * as mainStyles from '../../styles/form.css';
import { AccountType } from './AccountType';
import { Feedback } from './Feedback';

interface IMerchantSignupProps {
  error?: Error | null;
  location: any;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  preloadedFlags: any;
  processEnv: any;
  submitStatus: any;
}

interface IMerchantSignupState {
  currentPage: number;
  currentAdditionalOwnerIndex: number;
  currentAdditionalOwner: IMember | null;
  currentTotalOwnership: number;
  totalCountOwners: number;
}

export const wizard: number[] = [
  pages.PrimaryOwnerContact,
  pages.PaymentOptions,
  pages.AccountType,
  pages.AnnualCreditCardSales,
  pages.BusinessInformation,
  pages.BusinessStatement,
  pages.LegalBusinessInfo,
  pages.MoreBusinessInformation,
  pages.PrimaryOwnership,
  pages.PrimaryOwnerPersonal,
  pages.AdditionalOwnership,
  pages.AdditionalOwnerContact,
  pages.AdditionalOwnershipPersonal,
  pages.BankingInformation,
  pages.FirearmsComplianceAck,
  pages.Terminal,
  pages.Terms,
  pages.SetLogin,
  pages.Success,

];
// spWizard = sole proprietors
// TODO - don't like keeping multiple orders here, complicates things below. would be much better to do like EC and have any conditional logic attached to the step component itself.
export const spWizard: number[] = [
  pages.PrimaryOwnerContact,
  pages.PaymentOptions,
  pages.AccountType,
  pages.AnnualCreditCardSales,
  pages.BusinessStatement,
  pages.MoreBusinessInformation,
  pages.PrimaryOwnerPersonal,
  pages.BankingInformation,
  pages.FirearmsComplianceAck,
  pages.Terminal,
  pages.Terms,
  pages.SetLogin,
  pages.Success,
];

const FirearmsAckCodes = ['5941', '5999', '7299', '8999', 'Other'];

export class MerchantSignup extends React.Component<
  IMerchantSignupProps,
  IMerchantSignupState
> {
  state = {
    currentAdditionalOwner: null,
    currentAdditionalOwnerIndex: 0,
    currentPage: 20,
    currentTotalOwnership: 0,
    totalCountOwners: 1,
  };

  componentDidMount() {
    const initForm = getMerchantQueryParams(this.props.location.search);
    this.props.formActions.fetchForm(initForm);
  }

  // utility to get the next (or previous) page in a wizard-agnostic way
  // TODO - should refactor this, and rely on it entirely -- the below nextPage() and previousPage() methods are unwieldy, impenatrable, and unsuitable for scaling / reconfiguration of the flow --- ideally this method is only responsible for adding or subtracting 1 from the stepIndex, and conditional show/hide logic is moved to the sub-components themselves.
  getNextPage = (currentWizard: any, moveForward: boolean) => {
    const pageIndex = currentWizard.findIndex(
      (page: any) => this.state.currentPage === page,
    );

    const direction = moveForward ? 1 : -1;

    if (pageIndex === -1) {
      this.setState({ currentPage: 1 });
      return 1;
    } else {
      this.setState({ currentPage: currentWizard[pageIndex + direction] });
      return currentWizard[pageIndex + direction];
    }
  };

  // Very long function to advance to the next page, handles a lot of circumstancial step skipping and such. TODO - this needs to be refactored, badly.
  nextPage = (fields: any) => {
    ReactGA.event({
      action: 'flowAdvance',
      category: 'cpaySignupFlow',
      label: pages[this.state.currentPage].toString(),
    });

    if (fields) {
      this.props.formActions.updateForm(fields);
    }

    // determine which wizard to use (based on whether or not entity.type is SOLE_PROPRIETOR) -- if soleProp, use spWizard; else, use wizard
    const serviceRequestContainsPaymentProcessing =
      !!this.props?.form?.entity?.servicesRequested?.length &&
      paymentProcessingRequested(this.props.form.entity.servicesRequested);
    const industryCodeIsFirmarmsCode =
      !!this.props?.form?.entity?.industry &&
      FirearmsAckCodes.includes(this.props.form.entity.industry);
    if (
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.type === SOLE_PROPRIETOR
    ) {
      // skip firearms compliance step under certain circumstances
      if (
        this.state.currentPage === pages.BankingInformation &&
        !industryCodeIsFirmarmsCode
      ) {
        if (!serviceRequestContainsPaymentProcessing) {
          this.setState({
            currentPage: pages.Terms,
          });
          return pages.Terms;
        }

        this.setState({
          currentPage: pages.Terminal,
        });
        return pages.Terminal;
      }

      if (
        this.state.currentPage === pages.FirearmsComplianceAck &&
        !serviceRequestContainsPaymentProcessing
      ) {
        this.setState({
          currentPage: pages.Terms,
        });
        return pages.Terms;
      }

      if (
        this.state.currentPage === pages.Terminal &&
        !serviceRequestContainsPaymentProcessing
      ) {
        this.setState({ currentPage: pages.Terms });
        return pages.Terms;
      }

      return this.getNextPage(spWizard, true);
    } else {
      if (
        this.state.currentPage === pages.PrimaryOwnerPersonal ||
        this.state.currentPage === pages.AdditionalOwnershipPersonal
      ) {
        if (
          this.props.form &&
          this.props.form.entity &&
          this.props.form.entity.merchant &&
          this.props.form.entity.merchant.members
        ) {
          const {
            currentTotalOwnership,
            updatedOwners,
          } = this.countAndWipeTotalOwnership(
            this.props.form.entity.merchant.members,
          );
          this.setState({
            currentTotalOwnership,
          });
          if (currentTotalOwnership < 75) {
            // redirect to AdditionalOwnershipPage
            if (this.state.currentPage === pages.PrimaryOwnerPersonal) {
              if (updatedOwners && updatedOwners.length > 1) {
                const copyMember = {
                  ...updatedOwners[1],
                };
                this.setState({
                  currentAdditionalOwner: copyMember,
                });
              }
              this.setState({
                currentAdditionalOwnerIndex: 1,
                currentPage: pages.AdditionalOwnership,
                totalCountOwners: updatedOwners.length,
              });
            } else {
              if (
                updatedOwners &&
                this.state.currentAdditionalOwnerIndex + 1 <
                  updatedOwners.length
              ) {
                const copyMember = {
                  ...updatedOwners[this.state.currentAdditionalOwnerIndex + 1],
                };
                this.setState({
                  currentAdditionalOwner: copyMember,
                });
              }
              this.setState({
                currentAdditionalOwnerIndex:
                  this.state.currentAdditionalOwnerIndex + 1,
                currentPage: pages.AdditionalOwnership,
                totalCountOwners: updatedOwners.length,
              });
            }
            return pages.AdditionalOwnership;
          } else {
            // user goes back to Primary Owner Section but filling Additional Owners' info already
            if (
              updatedOwners &&
              this.state.currentPage === pages.PrimaryOwnerPersonal &&
              updatedOwners.length > 1
            ) {
              const copyMember = {
                ...updatedOwners[1],
              };
              this.setState({
                currentAdditionalOwner: copyMember,
                currentAdditionalOwnerIndex: 1,
                currentPage: pages.AdditionalOwnership,
                totalCountOwners: updatedOwners.length,
              });
              return pages.AdditionalOwnership;
            }
            // jump to bank information
            this.setState({ currentPage: pages.BankingInformation });
            return pages.BankingInformation;
          }
        }
      }

      if (
        this.state.currentPage === pages.BankingInformation &&
        !industryCodeIsFirmarmsCode
      ) {
        if (!serviceRequestContainsPaymentProcessing) {
          this.setState({
            currentPage: pages.Terms,
          });
          return pages.Terms;
        }

        this.setState({
          currentPage: pages.Terminal,
        });
        return pages.Terminal;
      }

      if (
        this.state.currentPage === pages.FirearmsComplianceAck &&
        !serviceRequestContainsPaymentProcessing
      ) {
        this.setState({
          currentPage: pages.Terms,
        });
        return pages.Terms;
      }

      if (
        this.state.currentPage === pages.Terminal &&
        !serviceRequestContainsPaymentProcessing
      ) {
        this.setState({ currentPage: pages.Terms });
        return pages.Terms;
      }

      return this.getNextPage(wizard, true);
    }
  };

  previousPage = () => {
    ReactGA.event({
      action: 'flowPrevious',
      category: 'cpaySignupFlow',
      label: pages[this.state.currentPage].toString(),
    });

    if (this.state.currentPage === pages.Success) {
      const environment =
        this.props.form &&
        this.props.form.entity &&
        this.props.form.entity.merchant &&
        this.props.form.entity.merchant.environment
          ? this.props.form.entity.merchant.environment
          : '';
    }

    const serviceRequestContainsPaymentProcessing =
      this.props?.form?.entity?.servicesRequested &&
      paymentProcessingRequested(this.props.form.entity.servicesRequested);
    const industryCodeIsFirmarmsCode =
      this.props?.form?.entity?.industry &&
      FirearmsAckCodes.includes(this.props.form.entity.industry);

    if (
      this.state.currentPage === pages.Terms &&
      !serviceRequestContainsPaymentProcessing
    ) {
      if (industryCodeIsFirmarmsCode) {
        this.setState({
          currentPage: pages.FirearmsComplianceAck,
        });
        return pages.FirearmsComplianceAck;
      }

      this.setState({
        currentPage: pages.BankingInformation,
      });
      return pages.BankingInformation;
    }

    if (
      this.state.currentPage === pages.Terminal &&
      !industryCodeIsFirmarmsCode
    ) {
      this.setState({
        currentPage: pages.BankingInformation,
      });
      return pages.BankingInformation;
    }

    if (
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.type === SOLE_PROPRIETOR
    ) {
      return this.getNextPage(spWizard, false);
    } else {
      if (this.state.currentPage === pages.BankingInformation) {
        if (
          this.props.form &&
          this.props.form.entity &&
          this.props.form.entity.merchant &&
          this.props.form.entity.merchant.members &&
          this.props.form.entity.merchant.members.length === 1
        ) {
          // no additional owner - From BankingInformation back to PrimaryOwnerPersonal
          this.setState({
            currentPage: pages.PrimaryOwnerPersonal,
          });
          return pages.PrimaryOwnerPersonal;
        }
      }
      if (this.state.currentPage === pages.AdditionalOwnership) {
        if (this.state.currentAdditionalOwnerIndex >= 2) {
          // stays at AdditionalOwnership
          this.setState({
            currentAdditionalOwnerIndex:
              this.state.currentAdditionalOwnerIndex - 1,
            currentPage: pages.AdditionalOwnershipPersonal,
          });
          return pages.AdditionalOwnershipPersonal;
        }
      }

      return this.getNextPage(wizard, false);
    }
  };

  countAndWipeTotalOwnership = (members: IMember[]) => {
    let ownership = 0;
    let currentOwners = members.map((m: IMember) => ({ ...m }));
    members.forEach((m: IMember) => (ownership += m.ownership));
    // wipe out the extra owners
    if (ownership > 100) {
      currentOwners = [];
      let index = 0;
      let currentOwnership = 0;
      while (
        index < members.length &&
        currentOwnership + members[index].ownership <= 100
      ) {
        currentOwners.push({ ...members[index] });
        currentOwnership += members[index++].ownership;
      }
      const entity = shapeUtility(this.props.form);
      entity.merchant.members = currentOwners;
      ownership = 0;
      currentOwners.forEach((m: IMember) => (ownership += m.ownership));
      this.props.formActions.updateForm({ ...this.props.form, entity });
    }
    return {
      currentTotalOwnership: ownership,
      updatedOwners: currentOwners,
    };
  };

  render() {
    const { currentPage } = this.state;
    return (
      <>
        <NavBar />
        <div className='row'>
          <div className='col-xs-0 col-sm-0 col-md-2 col-lg-2 col-xl-2' />
          <div
            className={`col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 ${mainStyles.form}`}
          >
            {currentPage === pages.PrimaryOwnerContact && (
              <PrimaryOwnerContact nextPage={this.nextPage} {...this.props} />
            )}
            {currentPage === pages.PaymentOptions && (
              <PaymentOptions
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.AccountType && (
              <AccountType
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.AnnualCreditCardSales && (
              <AnnualCreditCardSales
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.BusinessInformation && (
              <BusinessInformation
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.BusinessStatement && (
              <BusinessStatement
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.LegalBusinessInfo && (
              <LegalBusinessInfo
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.MoreBusinessInformation && (
              <MoreBusinessInformation
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.PrimaryOwnership && (
              <PrimaryOwnership
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.PrimaryOwnerPersonal && (
              <PrimaryOwnerPersonal
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.AdditionalOwnership && (
              <AdditionalOwnership
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
                currentAdditionalOwnerIndex={
                  this.state.currentAdditionalOwnerIndex
                }
                totalCountOwners={this.state.totalCountOwners}
                currentAdditionalOwner={this.state.currentAdditionalOwner}
                currentTotalOwnership={this.state.currentTotalOwnership}
              />
            )}
            {currentPage === pages.AdditionalOwnerContact && (
              <AdditionalOwnerContact
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
                currentAdditionalOwnerIndex={
                  this.state.currentAdditionalOwnerIndex
                }
                totalCountOwners={this.state.totalCountOwners}
                currentAdditionalOwner={this.state.currentAdditionalOwner}
              />
            )}
            {currentPage === pages.AdditionalOwnershipPersonal && (
              <AdditionalOwnershipPersonal
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
                currentAdditionalOwnerIndex={
                  this.state.currentAdditionalOwnerIndex
                }
                totalCountOwners={this.state.totalCountOwners}
                currentAdditionalOwner={this.state.currentAdditionalOwner}
              />
            )}
            {currentPage === pages.BankingInformation && (
              <BankingInformation
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.FirearmsComplianceAck && (
              <FirearmsComplianceAck
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.Terminal && (
              <Terminal
                previousPage={this.previousPage}
                nextPage={this.nextPage}
                {...this.props}
              />
            )}
            {currentPage === pages.Terms && (
              <Terms
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.SetLogin && (
              <SetLogin
                nextPage={this.nextPage}
                previousPage={this.previousPage}
                {...this.props}
              />
            )}
            {currentPage === pages.Feedback && (
              <Feedback
                nextPage={() => {this.setState({ currentPage: 19 })}}
                {...this.props}
              />
            )}
            {currentPage === pages.Success && (
              <Success
                status={
                  (this.props.submitStatus && this.props.submitStatus.status) ||
                  'ok'
                }
              />
            )}
          </div>
          <div className='col-xs-0 col-sm-0 col-md-5 col-lg-5 col-xl-6' />
        </div>
        <Footer />
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
    submitStatus: state.submitStatus,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
    submitStatusActions: bindActionCreators(submitStatusActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(MerchantSignup));
