import React from 'react';
import * as mainStyles from '../../../styles/form.css';

const NavBar = () => {
  return (
    <nav className={`${mainStyles.navBar} mb-5`}>
      <img
        className={`pl-2 float-left ${mainStyles.logo}`}
        src='/images/currencypay_logo.svg'
      />
      <div
        className={`navbar-brand float-right d-flex align-items-center row-hl ${mainStyles.frameAdjust}`}
      >
        <a
          href='tel:+18447247376'
          className={`${mainStyles.textSecondary} ${mainStyles.navPhone}`}
        >
          (844) 724-7376
        </a>
      </div>
    </nav>
  );
};

export default NavBar;
