import redux from 'redux';
import * as types from './action-types';

export function beginfetchSubmitStatus() {
  return {
    type: types.FETCH_SUBMIT_STATUS,
  };
}

export function fetchSubmitStatusSuccess(status: string) {
  return {
    status,
    type: types.FETCH_SUBMIT_STATUS_SUCCESS,
  };
}

export function fetchSubmitStatusError(error: Error) {
  return {
    error,
    type: types.FETCH_SUBMIT_STATUS_ERROR,
  };
}

export function fetchSubmitStatus(status: string) {
  return async (dispatch: redux.Dispatch) => {
    dispatch(beginfetchSubmitStatus());

    try {
      dispatch(fetchSubmitStatusSuccess(status));
    } catch (err) {
      dispatch(fetchSubmitStatusError(err));
    }
  };
}
