// tslint:disable:jsx-no-lambda
import React, { useState } from 'react';
import { IEntity, ISignUpForm } from '../../../interfaces';
import * as mainStyles from '../../../styles/form.css';
import * as styles from '../../../styles/form.css';
import { PAYMENT_OPTIONS } from '../../../utility/constants';
import { Button, CheckBox } from '../../ui';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';
interface IPaymentOptionsProps {
  error?: Error | null;
  location: any;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  preloadedFlags: any;
  previousPage: any;
  processEnv: any;
}
export const PaymentOptions: React.FunctionComponent<IPaymentOptionsProps> = ({
  form,
  previousPage,
  nextPage,
}) => {
  const { entity } = form;
  const [financing, clickFinancing] = useState(
    entity?.servicesRequested?.includes(PAYMENT_OPTIONS.FINANCING) || false,
  );
  const [processing, clickProcessing] = useState(
    entity?.servicesRequested?.includes(PAYMENT_OPTIONS.PROCESSING) || false,
  );
  const [errorMsg, setErrorMsg] = useState('');
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const entity = {} as IEntity;
    entity.servicesRequested = [];
    if (!financing && !processing) {
      setErrorMsg('* Required');
      return;
    } else {
      setErrorMsg('');
    }
    if (financing) {
      entity.servicesRequested.push(PAYMENT_OPTIONS.FINANCING);
    }
    if (processing) {
      entity.servicesRequested.push(PAYMENT_OPTIONS.PROCESSING);
    }
    nextPage({ ...form, entity });
  };
  return (
    <>
      <TitleSubtitle title='Select payment option(s) for your customers.' />
      <form>
        <div className='container'>
          <CheckBox
            label='Card processing (credit card, debit card, e-check)'
            onChange={() => clickProcessing(!processing)}
            isChecked={processing}
          />
          <CheckBox
            label='Financing (competitive loans with instant approval for A-D credit profiles)'
            onChange={() => clickFinancing(!financing)}
            isChecked={financing}
          />
        </div>
        <div>
          <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>{errorMsg}</h6>
        </div>
        <br />
        <br />
        <div className={mainStyles.buttonContainer}>
          <div className={mainStyles.next}>
            <Button onClick={handleSubmit} title='Next' type='primary' />
          </div>
          <div className={mainStyles.prev}>
            <Button onClick={previousPage} title='Previous' type='secondary' />
          </div>
        </div>
        <ProgressDots complete={2} incomplete={5} />
      </form>
    </>
  );
};
