import * as types from '../actions/action-types';

const initialState = {
  LAUNCH_DARKLY: {},
};

// for holding process.env variables (populated in server render)
export default function env(state = initialState, action: any) {
  switch (action.type) {
    case types.SET_PROCESS_ENV:
      return { ...action.env };
    default:
      return state;
  }
}
