import { withLDConsumer } from 'ldclient-react';
import { isNaN } from 'lodash';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IEntity, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import {
  formatMoney,
  sendAbandon,
  shapeUtility,
} from '../../../utility/helper';
import { Button } from '../../ui/Button';
import CurrencyInput from '../Inputs/Currency';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IAnnualCreditCardSalesState {
  monthlyCCSales: string;
  monthlyCCTrxns: string;
  valid: boolean;
  showRequired: boolean;
  requiredMsg: string;
}

export class AnnualCreditCardSales extends Component<
  IMerchantSignupProps,
  IAnnualCreditCardSalesState
> {
  state: IAnnualCreditCardSalesState = {
    monthlyCCSales: '',
    monthlyCCTrxns: '',
    requiredMsg: '*Required',
    showRequired: false,
    valid: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.merchant
    ) {
      const {
        monthlyCCSales,
        monthlyCCTrxns,
      } = this.props.form.entity.merchant;
      if (typeof monthlyCCSales === 'number') {
        const accsStr = formatMoney(monthlyCCSales.toString());
        this.setState({
          monthlyCCSales: accsStr,
          valid: true,
        });
      }
      if (typeof monthlyCCTrxns === 'number') {
        this.setState({ monthlyCCTrxns: monthlyCCTrxns.toString() });
      }
    }
  }

  handleSales = ({
    value,
    formattedValue,
  }: {
    value: string;
    formattedValue: string;
  }) => {
    if (!value) {
      this.setState({
        monthlyCCSales: value,
        showRequired: true,
        valid: false,
      });
      return false;
    }
    this.setState({
      monthlyCCSales: formattedValue,
      showRequired: false,
      valid: true,
    });
    return true;
  };

  handleTrxnInput = (input: any) => {
    this.setState({ monthlyCCTrxns: input.value });
    return true;
  };

  inputValidation = (state: IAnnualCreditCardSalesState) => {
    let result = false;
    const { monthlyCCSales, monthlyCCTrxns } = state;
    const parsedSales = parseInt(monthlyCCSales.replace(/\$|,/g, ''), 10);
    const parsedTrxns = parseInt(monthlyCCTrxns, 10);
    if (parsedSales > 0 && parsedTrxns >= 0) {
      this.setState({ valid: true });
      result = true;
    } else if (parsedSales === 0) {
      this.setState({ valid: true });
      result = true;
    } else {
      this.setState({ valid: false });
    }
    return result;
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    if (this.inputValidation(this.state)) {
      const { monthlyCCSales, monthlyCCTrxns } = this.state;
      const amountStr = formatMoney(monthlyCCSales).replace(/[,|$]/g, '');
      const trxnAmountStr = monthlyCCTrxns.replace(/[,|$]/g, '');
      const trxnAmount = parseInt(trxnAmountStr, 10);
      const accsNumber = parseFloat(amountStr);
      const { form } = this.props;
      const entity: IEntity = shapeUtility(form);
      if (typeof accsNumber === 'number') {
        entity.merchant.monthlyCCSales = accsNumber;
        entity.merchant.new = accsNumber === 0 ? 1 : 0;
        entity.merchant.monthlyCCTrxns =
          typeof trxnAmount === 'number' && !isNaN(trxnAmount) ? trxnAmount : 0;
        sendAbandon(
          { ...form, entity },
          this.props.flags,
          this.props.preloadedFlags,
        );
      } else {
        this.setState({ valid: false });
        return false;
      }
      this.props.nextPage({ ...form, entity });
      return true;
    } else {
      this.setState({ showRequired: true });
      return false;
    }
  };

  render() {
    return (
      <>
        <TitleSubtitle title='Tell us about your business.' />
        <form>
          <div className='input-group h-4'>
            <CurrencyInput
              className={`form-control input-lg pl-4 mt-5 mb-2 text-left bfh-phone ${styles.inputfield}`}
              id={'monthlyCCSales'}
              onChange={this.handleSales}
              placeholder='$ Monthly credit card sales'
              required={true}
              value={this.state.monthlyCCSales}
            />
          </div>
          {!this.state.monthlyCCSales && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>
                {this.state.requiredMsg}
              </h6>
            </div>
          )}
          <div className='input-group h-4'>
            <NumberFormat
              className={`form-control input-lg pl-4 mt-5 mb-2 text-left bfh-phone ${styles.inputfield}`}
              allowEmptyFormatting={true}
              allowLeadingZeros={false}
              allowNegative={false}
              onValueChange={this.handleTrxnInput}
              required={false}
              thousandSeparator={true}
              value={this.state.monthlyCCTrxns}
              placeholder='Number of monthly credit card transactions'
            />
          </div>
          {this.state.monthlyCCSales &&
            !this.state.monthlyCCTrxns &&
            this.state.showRequired && (
              <div className={styles.msg}>
                <h6 className='text-danger ml-1 mb-0'>
                  {this.state.requiredMsg}
                </h6>
              </div>
            )}
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={3} incomplete={4} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(AnnualCreditCardSales));
