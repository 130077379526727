import { withLDConsumer } from 'ldclient-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IEntity, ISignUpForm } from '../../../interfaces';
import * as mainStyles from '../../../styles/form.css';
import {
  bankAccountTypes,
  sendAbandon,
  shapeUtility,
} from '../../../utility/helper';
import {
  isValidAccountNumber,
  isValidRoutingNumber,
  isValidRoutingNumberLength,
} from '../../../utility/validation';
import { Button, Select } from '../../ui';
import { Norton } from '../Norton';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';
import * as styles from './styles.css';

const INVALID_ROUTING_NUMBER_LENGTH_MSG = '* Routing number must be 9 digits';
const INVALID_ROUTING_NUMBER_MSG = '* Invalid Routing Number';
const INVALID_ACCOUNT_NUMBER_MSG = '* Account number must be 5-20 digits';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IBankingInformationState {
  accountNumber: string;
  routing: string;
  bankAccountType: string;
  routingError: string;
  accountError: string;
  showCheck: boolean;
  showRequired: boolean;
  requiredMsg: string;
}

export class BankingInformation extends Component<
  IMerchantSignupProps,
  IBankingInformationState
> {
  state: IBankingInformationState = {
    accountError: '',
    accountNumber: '',
    bankAccountType: '',
    requiredMsg: '*Required',
    routing: '',
    routingError: '',
    showCheck: false,
    showRequired: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (this.props.form) {
      const { entity } = this.props.form;
      if (entity && entity.account) {
        const { type, routing, number } = entity.account;
        this.setState({
          accountNumber: number,
          bankAccountType: type,
          routing,
        });
        this.inputValidation({
          ...this.state,
          ...{
            accountNumber: number,
            bankAccountType: type,
            routing,
          },
        });
      }
    }
  }

  updateForm = (state: IBankingInformationState) => {
    const { accountNumber, bankAccountType, routing } = state;
    const { form } = this.props;
    const entity: IEntity = shapeUtility(form);
    if (entity.account) {
      entity.account.type = bankAccountType;
      entity.account.number = accountNumber;
      entity.account.routing = routing;
    } else {
      entity.account = {
        number: accountNumber,
        routing,
        type: bankAccountType,
      };
    }
    return { ...form, entity };
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    if (this.inputValidation(this.state)) {
      const updateForm: ISignUpForm = this.updateForm(this.state);
      sendAbandon(updateForm, this.props.flags, this.props.preloadedFlags);
      this.props.nextPage(updateForm);
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  handleRoutingChange = (event: any) => {
    const { value } = event.target;
    this.setState({ routing: value });
    if (!event.target.value) {
      this.setState({
        routingError: '',
      });
      return false;
    }
    if (!isValidRoutingNumberLength(value)) {
      this.setState({ routingError: INVALID_ROUTING_NUMBER_LENGTH_MSG });
      return false;
    } else if (!isValidRoutingNumber(value)) {
      this.setState({ routingError: INVALID_ROUTING_NUMBER_MSG });
      return false;
    }
    this.setState({ routingError: '' });
    return true;
  };

  handleBankAccountTypeChange = (event: any) => {
    this.setState({ bankAccountType: event.target.value });
    return event.target.value !== '0';
  };

  handleAccountNumberChange = (event: any) => {
    if (!isValidAccountNumber(event.target.value)) {
      this.setState({
        accountError: INVALID_ACCOUNT_NUMBER_MSG,
        accountNumber: event.target.value,
      });
    } else {
      this.setState({
        accountError: '',
        accountNumber: event.target.value,
      });
    }
    return !!event.target.value;
  };

  inputValidation = (state: IBankingInformationState) => {
    const { accountNumber, bankAccountType, routing } = state;
    if (
      !accountNumber ||
      !bankAccountType ||
      bankAccountType === '0' ||
      !routing ||
      !isValidRoutingNumberLength(routing) ||
      !isValidRoutingNumber(routing) ||
      !isValidAccountNumber(accountNumber)
    ) {
      return false;
    }
    return true;
  };

  showCheck = (event: any) => {
    event.preventDefault();
    this.setState({
      showCheck: !this.state.showCheck,
    });
  };

  renderOptions = (options: any) => {
    const result: any[] = [];
    options.map((option: any) => {
      result.push(<option value={option.value}>{option.name}</option>);
    });
    return result;
  };

  render() {
    const { accountError, routingError, requiredMsg } = this.state;
    return (
      <>
        <TitleSubtitle
          title='Banking information.'
          subtitle='Where should we send your money?'
        />
        <form>
          <Select
            identifier='bankAccountType'
            onChange={this.handleBankAccountTypeChange}
            options={bankAccountTypes}
            value={this.state.bankAccountType}
          />
          {(!this.state.bankAccountType ||
            this.state.bankAccountType === '0') &&
            this.state.showRequired && (
              <div className={mainStyles.msg}>
                <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
              </div>
            )}
          <div className='input-group h-4'>
            <input
              className={`input form-control input-lg mt-3 mb-2 pl-4 text-left ${mainStyles.inputfield}`}
              type='number'
              placeholder='Routing number'
              minLength={1}
              maxLength={9}
              value={this.state.routing}
              onChange={this.handleRoutingChange}
            />
          </div>
          {!this.state.routing && this.state.showRequired && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${mainStyles.msg}`}>
              {routingError}
            </h6>
          </div>
          <div className='input-group h-4'>
            <input
              className={`input form-control input-lg mt-3 mb-2 pl-4 text-left ${mainStyles.inputfield}`}
              type='number'
              placeholder='Account number'
              minLength={1}
              maxLength={20}
              value={this.state.accountNumber}
              onChange={this.handleAccountNumberChange}
            />
          </div>
          {!this.state.accountNumber && this.state.showRequired && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>
                {this.state.requiredMsg}
              </h6>
            </div>
          )}
          {accountError !== '' && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{accountError}</h6>
            </div>
          )}
          <button
            className={`${mainStyles.inputfield} ${mainStyles.btnColor} ${styles.bankCheckbtn}`}
            onClick={this.showCheck}
          >
            How do I find my bank account numbers?
          </button>
          <div
            className={`input-group mb-3 h-4 mx-auto pt-2 ${
              this.state.showCheck ? `` : mainStyles.disappear
            }`}
          >
            <div className={`mx-auto ${styles.bankCheck}`}>
              <img
                className={styles.bankCheckImg}
                src='/images/bank_check.gif'
              />
            </div>
          </div>
          <div className={mainStyles.buttonContainer}>
            <div className={mainStyles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={mainStyles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={5} incomplete={2} {...this.props} />
          <Norton />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(BankingInformation));
