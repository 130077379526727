import { combineReducers } from 'redux';

import featureFlags from './feature-flags';
import formReducer from './form';
import processEnv from './process-env';
import submitStatus from './submit';

const rootReducer = combineReducers({
  featureFlags,
  formReducer,
  processEnv,
  submitStatus,
});

export default rootReducer;
