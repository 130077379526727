import Axios from 'axios';
import { withLDConsumer } from 'ldclient-react';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { ISignUpForm } from '../../../interfaces';
import * as mainStyles from '../../../styles/form.css';
import { formatPhone, sendAbandon, shapeEmail } from '../../../utility/helper';
import loggerClient, { ILoggerClient } from '../../../utility/logger';
import { validateEmail, validateName } from '../../../utility/validation';
import { Button } from '../../ui/Button';
import PhoneInput from '../Inputs/Phone';
import { Norton } from '../Norton';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  location: any;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IPrimaryOwnerContactState {
  email: string;
  emailError: string;
  phone: string;
  phoneError: string;
  first: string;
  last: string;
  valid: boolean;
  showRequired: boolean;
  requiredMsg: string;
  nextDisabled: boolean;
}

export class PrimaryOwnerContact extends React.Component<
  IMerchantSignupProps,
  IPrimaryOwnerContactState
> {
  state: IPrimaryOwnerContactState = {
    email: '',
    emailError: '',
    first: '',
    last: '',
    phone: '',
    phoneError: '',
    requiredMsg: '*Required',
    showRequired: false,
    valid: false,
    nextDisabled: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (this.props.form) {
      const { email = '', phone = '', first = '', last = '' } = this.props.form;
      const fPhone = formatPhone(phone);
      this.setState({
        email,
        first,
        last,
        phone: fPhone,
      });
      if (email && phone && first && last) {
        this.setState({ valid: true });
      }
    }
  }

  handleEmailChange = async (event: any) => {
    let result = false;
    const email = event.target.value;
    const logger = (await loggerClient(
      this.props.processEnv.INSTRUMENTATION_KEY,
    )) as ILoggerClient;
    try {
      this.setState({ email });
      if (!email) {
        this.setState({
          emailError: '',
          valid: false,
        });
        return result;
      }
      if (validateEmail(email)) {
        this.setState({ nextDisabled: true });
        const { data } = await Axios.get(`/api/currencypay/email/${email}`);
        if (!data || data.matchCount !== 0) {
          this.setState({
            emailError:
              'Email already in use. Please try again or login to your existing account.',
            valid: false,
            nextDisabled: false,
          });
        } else {
          this.setState({
            emailError: '',
            nextDisabled: false,
          });
          result = true;
        }
      } else {
        this.setState({ emailError: '* Invalid email' });
      }
      this.inputValidation(this.state);
      return result;
    } catch (error) {
      logger.error(error, {
        name: 'primary-owner-contact-handler',
        properties: JSON.stringify({ email }),
      });
      this.setState({
        emailError: `Unable to validate email: ${email}`,
        valid: false,
        nextDisabled: false,
      });
      result = false;
      return result;
    }
  };

  handlePhoneChange = ({
    value,
    formattedValue,
  }: {
    value: string;
    formattedValue: string;
  }) => {
    let result = false;
    if (!value) {
      this.setState({ phone: value, phoneError: '', valid: false });
    } else if (value?.length >= 10) {
      this.setState({ phone: formattedValue, phoneError: '', valid: true });
      result = true;
    } else {
      this.setState({
        phone: formattedValue,
        phoneError: '* Invalid phone',
        valid: false,
      });
    }
    this.inputValidation(this.state);
    return result;
  };

  handleFNameChange = (event: any) => {
    this.setState({ first: event.target.value });
    if (!validateName(event.target.value)) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation(this.state);
    return true;
  };

  handleLNameChange = (event: any) => {
    this.setState({ last: event.target.value });
    if (!validateName(event.target.value)) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation(this.state);
    return true;
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    this.inputValidation(this.state);
    if (this.state.valid) {
      const { email, first, last, phone } = this.state;
      const { form } = this.props;
      const purePhone = phone ? phone.replace(/-|_/g, '') : '';
      sendAbandon(
        { ...form, email, phone: purePhone, first, last },
        this.props.flags,
        this.props.preloadedFlags,
      );
      this.props.nextPage({
        ...form,
        email: shapeEmail(email),
        first,
        last,
        phone: purePhone,
      });
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  inputValidation = async (state: IPrimaryOwnerContactState) => {
    let result = false;
    const { email, phone, first, last, emailError } = state;
    if (!email || !phone || !first || !last) {
      this.setState({ valid: false });
      return result;
    }
    const purePhone = phone ? phone.replace(/-|_/g, '') : '';
    if (
      validateEmail(email) &&
      purePhone &&
      purePhone.length >= 10 &&
      purePhone.length <= 15 &&
      validateName(first) &&
      validateName(last) &&
      !emailError
    ) {
      this.setState({ valid: true });
      result = true;
    } else {
      this.setState({ valid: false });
    }
    return result;
  };

  render() {
    const { emailError, phoneError, requiredMsg } = this.state;
    return (
      <>
        <TitleSubtitle
          {...this.props}
          title='Let&rsquo;s get started.'
          subtitle='CurrencyPay is the first payment platform designed for buying and selling heavy equipment, machinery, and other large-ticket items.'
        />
        <form>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={100}
              minLength={2}
              placeholder='First name'
              value={this.state.first}
              onChange={this.handleFNameChange}
            />
          </div>
          {!validateName(this.state.first) && this.state.showRequired && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={100}
              placeholder='Last name'
              value={this.state.last}
              onChange={this.handleLNameChange}
            />
          </div>
          {!validateName(this.state.last) && this.state.showRequired && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <DebounceInput
              className={`input form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              placeholder='Contact email'
              debounceTimeout={500}
              maxLength={100}
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
          <div className={mainStyles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{emailError}</h6>
          </div>
          {!this.state.email && this.state.showRequired && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <PhoneInput
              className={`form-control input-lg pl-4 my-3 text-left bfh-phone ${mainStyles.inputfield}`}
              id={'businessPhone'}
              onChange={this.handlePhoneChange}
              placeholder='Contact phone'
              required={true}
              value={this.state.phone}
            />
          </div>
          <div className={mainStyles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{phoneError}</h6>
          </div>
          {!this.state.phone &&
            !this.state.phoneError &&
            this.state.showRequired && (
              <div className={mainStyles.msg}>
                <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
              </div>
            )}
          <div className={mainStyles.buttonContainer}>
            <div className={mainStyles.prev} />
            <div className={mainStyles.next}>
              <Button
                onClick={this.handleSubmit}
                title='Next'
                type='primary'
                disabled={this.state.nextDisabled}
              />
            </div>
          </div>
          <ProgressDots complete={1} incomplete={6} {...this.props} />
          <Norton />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(PrimaryOwnerContact));
