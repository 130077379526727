import React from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import { sendAbandon, shapeUtility } from '../../../utility/helper';
import { Button } from '../../ui/Button';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IFirearmsComplianceAckState {
  first: string;
  last: string;
  entityName: string;
  firearms: number;
  valid: boolean;
}

export class FirearmsComplianceAck extends React.Component<
  IMerchantSignupProps,
  IFirearmsComplianceAckState
> {
  state: IFirearmsComplianceAckState = {
    entityName: '',
    firearms: 0,
    first: '',
    last: '',
    valid: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (this.props.form) {
      const { entity } = this.props.form;
      this.setState({
        entityName: this.props.form.entity ? this.props.form.entity.name : '',
        firearms: entity && entity.firearms ? 1 : 0,
        first: this.props.form.first ? this.props.form.first : '',
        last: this.props.form.last ? this.props.form.last : '',
        valid: entity && entity.firearms ? true : false,
      });
    }
  }

  handleFirearmsCheck = (event: any) => {
    this.setState({
      firearms: event.target.checked ? 1 : 0,
      valid: event.target.checked ? true : false,
    });
    return event.target.checked ? true : false;
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    if (this.state.valid) {
      const { firearms } = this.state;
      const { form } = this.props;
      const entity = shapeUtility(form);
      entity.firearms = firearms ? 1 : 0;
      sendAbandon(
        { ...form, entity },
        this.props.flags,
        this.props.preloadedFlags,
      );
      this.props.nextPage({ ...form, entity });
      return true;
    }
    return false;
  };

  render() {
    return (
      <>
        <TitleSubtitle
          title='Firearms compliance acknowledgement'
          subtitle=''
        />
        <form>
          <h5 className={`${styles.textSecondary} mx-5 mb-5 ${styles.msg}`}>
            I, {this.state.first} {this.state.last}, on behalf of{' '}
            {this.state.entityName} affirm that no payments whatsoever for
            firearms or ammunitions will be made through the processing account.
            Payments for these items will be taken via alternative means only. I
            understand that non-compliance will result in termination of the
            merchant account.
          </h5>
          <div className={`input-group ml-5 mb-3 h-4 ${styles.msg}`}>
            <label>
              <input
                type='checkbox'
                checked={this.state.firearms === 0 ? false : true}
                onChange={this.handleFirearmsCheck}
              />
              <h4
                className={`pl-3 ${styles.inlineBlock} ${styles.textSecondary}`}
              >
                I agree to the{' '}
                <a
                  href='/merchant/terms'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms and Conditions
                </a>
                .
              </h4>
            </label>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={5} incomplete={2} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirearmsComplianceAck);
