import React, { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';

export interface ICurrencyInput {
  className: string;
  id: string;
  onChange: (input: { value: string; formattedValue: string }) => void;
  placeholder: string;
  required: boolean;
  value: string;
}

const CurrencyInput: FunctionComponent<ICurrencyInput> = ({
  className,
  id,
  onChange,
  placeholder,
  required,
  value,
}) => (
  <NumberFormat
    className={className}
    allowEmptyFormatting={true}
    allowLeadingZeros={false}
    allowNegative={false}
    decimalScale={2}
    id={id}
    onValueChange={({ value, formattedValue }) =>
      onChange({ value, formattedValue })
    }
    placeholder={placeholder}
    prefix={value ? '$' : undefined}
    required={required}
    thousandSeparator={true}
    value={value}
  />
);

export default CurrencyInput;
