import React from 'react';
import ReactGA from 'react-ga';

// initialize google analytics tracking
ReactGA.initialize('UA-136956044-1', {
  titleCase: false,
});

interface IWithAnalyticsProps {
  pathname: string;
  search: string;
  children: any;
}

class WithAnalytics extends React.Component<IWithAnalyticsProps> {
  componentDidMount() {
    const page = this.props.pathname + this.props.search;
    this.trackPage(page);
  }

  shouldComponentUpdate(nextProps: IWithAnalyticsProps) {
    const currentPage = this.props.pathname + this.props.search;
    const nextPage = nextProps.pathname + nextProps.search;

    if (nextPage !== currentPage) {
      this.trackPage(nextPage);
      return true;
    }

    return false;
  }

  trackPage(page: string) {
    ReactGA.pageview(page);
  }

  render() {
    return this.props.children;
  }
}

export default WithAnalytics;
