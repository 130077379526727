import React from 'react';
import * as styles from './styleslo.css';

const Loading: React.FunctionComponent<{}> = () => (
  <div className={`${styles.loader}`}>
    <div className={`${styles.loader__content}`}>
      <h1>
        Wait just one moment
        <div className={`${styles.ellipsis}`}>
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </h1>
      <h2>We're loading your details now</h2>
      <p>For optimal experience, please use Chrome or Safari</p>
    </div>
  </div>
);

export default Loading;
