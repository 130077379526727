import URLSearchParams from '@ungap/url-search-params';
import Axios from 'axios';
import moment from 'moment-timezone';
import {
  AccountType,
  IAccount,
  IEntity,
  IMember,
  IMerchant,
  ISignUpForm,
} from '../interfaces';
import {
  ACCOUNT_TYPES,
  CURRENCY_PAY_SIGNUP,
  NON_PROFIT_ORG,
  PAYMENT_OPTIONS,
  SOLE_PROPRIETOR,
} from './constants';
import { validateEmail } from './validation';

const ALPHA_NUMERIC_REGEX = new RegExp(/^[a-zA-Z0-9]*$/g);

export function formatPayload(
  form: ISignUpForm,
  flags: any,
  preloadedFlags: any,
) {
  form.leadType = CURRENCY_PAY_SIGNUP;
  form.campaign = CURRENCY_PAY_SIGNUP;
  if (
    form.entity &&
    form.entity.type === SOLE_PROPRIETOR &&
    form.entity.merchant &&
    form.entity.merchant.members &&
    form.entity.merchant.members[0]
  ) {
    form.entity.public = false;
    form.entity.merchant.members[0].ownership = 100;
    form.entity.merchant.members[0].title = 'Owner';
    form.entity.phone = form.phone;
    form.entity.line1 = form.entity.merchant.members[0].line1;
    form.entity.name = form.entity.merchant.dba
      ? form.entity.merchant.dba
      : `${form.first} ${form.last}`;
    form.entity.email = form.email;
    if (!form.entity.ein) {
      form.entity.ein = form.entity.merchant.members[0].ssn;
    }
  }
  if (
    form &&
    form.entity &&
    form.entity.type &&
    form.entity.type === NON_PROFIT_ORG
  ) {
    form.entity.public = true;
  }
  // RFC 5321 case sensitive for local-part & case insensitive for domain part
  if (form.email) {
    form.email = shapeEmail(form.email);
  }
  if (form.entity && form.entity.email) {
    form.entity.email = shapeEmail(form.entity.email);
  }
  if (
    form.entity &&
    form.entity &&
    form.entity.merchant &&
    form.entity.merchant.members
  ) {
    form.entity.merchant.members.forEach((m: IMember) => {
      if (m.email) {
        m.email = shapeEmail(m.email);
      }
    });
  }
  return form;
}

export function shapeEmail(email: string) {
  if (validateEmail(email)) {
    const arr = email.split('@');
    if (arr.length === 2) {
      return arr[0] + '@' + arr[1].toLowerCase();
    }
  }
  return email;
}

export function shapeUtility(form: ISignUpForm) {
  const entity = {} as IEntity;
  const merchant = {} as IMerchant;
  entity.merchant = merchant;
  entity.merchant.members = [];
  if (form && form.entity) {
    entity.sendFreeTerminal = form.entity.sendFreeTerminal || false;
    entity.address = form.entity.address;
    entity.phone = form.entity.phone;
    entity.public = form.entity.public;
    entity.email = form.entity.email;
    entity.ein = form.entity.ein;
    entity.firearms = form.entity.firearms;
    entity.industry = form.entity.industry;
    entity.name = form.entity.name;
    entity.type = form.entity.type;
    entity.website = form.entity.website;
    entity.customerPhone = form.entity.customerPhone;
    entity.line1 = form.entity.line1;
    entity.city = form.entity.city;
    entity.state = form.entity.state;
    entity.zip = form.entity.zip;
    entity.numOfEmployees = form.entity.numOfEmployees;
    entity.tcVersion = form.entity.tcVersion;
    entity.tcDate = form.entity.tcDate;
    entity.tcAcceptDate = form.entity.tcAcceptDate;
    entity.servicesRequested = form.entity.servicesRequested;
    entity.initialAccountType = determineInitialAccType(
      form.entity.servicesRequested,
    );
    if (form.entity.merchant) {
      entity.merchant.annualCCSales = parseFloat(
        ((form.entity.merchant.monthlyCCSales || 0) * 12).toFixed(2),
      );
      entity.merchant.monthlyCCSales = form.entity.merchant.monthlyCCSales;
      entity.merchant.monthlyCCTrxns = form.entity.merchant.monthlyCCTrxns;
      entity.merchant.environment = form.entity.merchant.environment;
      entity.merchant.new = form.entity.merchant.new;
      entity.merchant.dba = form.entity.merchant.dba;
      entity.merchant.established = form.entity.merchant.established;
      if (form.entity.merchant.members) {
        form.entity.merchant.members.forEach((m: IMember) => {
          const copyMember = { ...m };
          entity.merchant.members.push(copyMember);
        });
      }
    }
    if (form.entity.account) {
      entity.account = {} as IAccount;
      entity.account.type = form.entity.account.type;
      entity.account.number = form.entity.account.number;
      entity.account.routing = form.entity.account.routing;
    }
  }
  return entity;
}

export const statesList = [
  {
    Abbrev: '0',
    State: 'State',
  },
  {
    Abbrev: 'AS',
    State: 'American Samoa',
  },
  {
    Abbrev: 'AL',
    State: 'Alabama',
  },
  {
    Abbrev: 'AK',
    State: 'Alaska',
  },
  {
    Abbrev: 'AZ',
    State: 'Arizona',
  },
  {
    Abbrev: 'AR',
    State: 'Arkansas',
  },
  {
    Abbrev: 'CA',
    State: 'California',
  },
  {
    Abbrev: 'CO',
    State: 'Colorado',
  },
  {
    Abbrev: 'CT',
    State: 'Connecticut',
  },
  {
    Abbrev: 'DC',
    State: 'District of Columbia ',
  },
  {
    Abbrev: 'DE',
    State: 'Delaware',
  },
  {
    Abbrev: 'FM',
    State: 'Federated States of Micronesia',
  },
  {
    Abbrev: 'FL',
    State: 'Florida',
  },
  {
    Abbrev: 'GA',
    State: 'Georgia',
  },
  {
    Abbrev: 'GU',
    State: 'Guam',
  },
  {
    Abbrev: 'HI',
    State: 'Hawaii',
  },
  {
    Abbrev: 'ID',
    State: 'Idaho',
  },
  {
    Abbrev: 'IL',
    State: 'Illinois',
  },
  {
    Abbrev: 'IN',
    State: 'Indiana',
  },
  {
    Abbrev: 'IA',
    State: 'Iowa',
  },
  {
    Abbrev: 'KS',
    State: 'Kansas',
  },
  {
    Abbrev: 'KY',
    State: 'Kentucky',
  },
  {
    Abbrev: 'LA',
    State: 'Louisiana',
  },
  {
    Abbrev: 'ME',
    State: 'Maine',
  },
  {
    Abbrev: 'MD',
    State: 'Maryland',
  },
  {
    Abbrev: 'MH',
    State: 'Marshall Islands',
  },
  {
    Abbrev: 'MA',
    State: 'Massachusetts',
  },
  {
    Abbrev: 'MI',
    State: 'Michigan',
  },
  {
    Abbrev: 'MN',
    State: 'Minnesota',
  },
  {
    Abbrev: 'MS',
    State: 'Mississippi',
  },
  {
    Abbrev: 'MO',
    State: 'Missouri',
  },
  {
    Abbrev: 'MT',
    State: 'Montana',
  },
  {
    Abbrev: 'NE',
    State: 'Nebraska',
  },
  {
    Abbrev: 'NV',
    State: 'Nevada',
  },
  {
    Abbrev: 'NH',
    State: 'New Hampshire',
  },
  {
    Abbrev: 'NJ',
    State: 'New Jersey',
  },
  {
    Abbrev: 'NM',
    State: 'New Mexico',
  },
  {
    Abbrev: 'NY',
    State: 'New York',
  },
  {
    Abbrev: 'NC',
    State: 'North Carolina',
  },
  {
    Abbrev: 'ND',
    State: 'North Dakota',
  },
  {
    Abbrev: 'MP',
    State: 'Northen Mariana Islands',
  },
  {
    Abbrev: 'OH',
    State: 'Ohio',
  },
  {
    Abbrev: 'OK',
    State: 'Oklahoma',
  },
  {
    Abbrev: 'OR',
    State: 'Oregon',
  },
  {
    Abbrev: 'PW',
    State: 'Palau',
  },
  {
    Abbrev: 'PA',
    State: 'Pennsylvania',
  },
  {
    Abbrev: 'PR',
    State: 'Puerto Rico',
  },
  {
    Abbrev: 'RI',
    State: 'Rhode Island',
  },
  {
    Abbrev: 'SC',
    State: 'South Carolina',
  },
  {
    Abbrev: 'SD',
    State: 'South Dakota',
  },
  {
    Abbrev: 'TN',
    State: 'Tennessee',
  },
  {
    Abbrev: 'TX',
    State: 'Texas',
  },
  {
    Abbrev: 'UT',
    State: 'Utah',
  },
  {
    Abbrev: 'VT',
    State: 'Vermont',
  },
  {
    Abbrev: 'VA',
    State: 'Virginia',
  },
  {
    Abbrev: 'VI',
    State: 'Virign Islands',
  },
  {
    Abbrev: 'WA',
    State: 'Washington',
  },
  {
    Abbrev: 'WV',
    State: 'West Virginia',
  },
  {
    Abbrev: 'WI',
    State: 'Wisconsin',
  },
  {
    Abbrev: 'WY',
    State: 'Wyoming',
  },
];

export const monthList = [
  {
    label: 'Business start month',
    value: 'Busienss start month',
  },
  {
    label: 'January',
    value: 0,
  },
  {
    label: 'February',
    value: 1,
  },
  {
    label: 'March',
    value: 2,
  },
  {
    label: 'April',
    value: 3,
  },
  {
    label: 'May',
    value: 4,
  },
  {
    label: 'June',
    value: 5,
  },
  {
    label: 'July',
    value: 6,
  },
  {
    label: 'August',
    value: 7,
  },
  {
    label: 'September',
    value: 8,
  },
  {
    label: 'October',
    value: 9,
  },
  {
    label: 'November',
    value: 10,
  },
  {
    label: 'December',
    value: 11,
  },
];

export const maxDaysInMonth: { [m: string]: number } = {
  '01': 31,
  '02': 29,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  '10': 31,
  '11': 30,
  '12': 31,
};

export const industryList = [
  {
    label: 'Industry',
    value: '0',
  },
  {
    label: 'Auto Dealer (New/Used)',
    value: '5511',
  },
  {
    label: 'Chiropractor',
    value: '8041',
  },
  {
    label: 'Equipment/Tool/Furniture Rental or Leasing',
    value: '7394',
  },
  {
    label: 'Industrial Supplies',
    value: '5085',
  },
  {
    label: 'Kitchen/Refrigerator Equipment',
    value: '5422',
  },
  {
    label: 'Misc. Specialty Retail Store',
    value: '5999',
  },
  {
    label: 'Misc. Personal Services',
    value: '7299',
  },
  {
    label: 'Other Professional Services',
    value: '8999',
  },
  {
    label: 'Real Estate',
    value: '6513',
  },
  {
    label: 'Special Trade Contractor',
    value: '1799',
  },
];

export const bankAccountTypes = [
  {
    label: 'Bank account type',
    value: '0',
  },
  {
    label: 'Checking',
    value: 'Checking',
  },
  {
    label: 'Savings',
    value: 'Savings',
  },
  {
    label: 'Corporate checking',
    value: 'Corporate Checking',
  },
  {
    label: 'Corporate savings',
    value: 'Corporate Savings',
  },
];

export const yearList: any = [{ label: 'Business start year', value: 0 }];
for (let i = new Date().getUTCFullYear(); i >= 1870; i--) {
  const blah = {
    label: i.toString(),
    value: i,
  };
  yearList.push(blah);
}

const numberOfEmployees: string[] = [
  '1-10',
  '11-50',
  '51-100',
  '101-500',
  '501-1,000',
  '1,001-5,000',
  '5,001-10,000',
  '10,000+',
];

export const employeesList = numberOfEmployees.map((employee: string) => {
  return {
    label: employee,
    value: employee,
  };
});

export function formatPhone(phone: string) {
  if (!phone) {
    return '';
  }
  const purePhone = phone.replace(/-|_/g, '');
  const p1 = purePhone.slice(0, 3);
  const p2 = purePhone.slice(3, 6);
  const p3 = purePhone.slice(6, 10);
  let formatPhone = p1 && p1.length === 3 ? p1 + '-' : p1;
  formatPhone +=
    formatPhone && formatPhone.length === 4 && p2 && p2.length === 3
      ? p2 + '-'
      : p2;
  formatPhone += formatPhone && formatPhone.length === 8 ? p3 : '';
  return formatPhone;
}

export function formatSsn(str: string) {
  if (!str) {
    return '';
  }
  const pureStr = str.replace(/-/g, '');
  const p1 = pureStr.slice(0, 3);
  const p2 = pureStr.slice(3, 5);
  const p3 = pureStr.slice(5);
  let formatStr = p1 && p1.length === 3 ? p1 + '-' : p1;
  formatStr +=
    formatStr && formatStr.length === 4 && p2 && p2.length === 2
      ? p2 + '-'
      : p2;
  formatStr += formatStr && formatStr.length === 7 ? p3 : '';
  return formatStr;
}

export function formatEin(str: string) {
  if (!str) {
    return '';
  }
  const pureStr = str.replace(/-/g, '');
  const p1 = pureStr.slice(0, 2);
  const p2 = pureStr.slice(2);
  let formatStr = p1 && p1.length === 2 ? p1 + '-' : p1;
  formatStr += formatStr && formatStr.length === 3 ? p2 : '';
  return formatStr;
}

export function formatMoney(
  amount: string,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) {
  try {
    amount = amount.replace(/[,|$]/g, '');
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
      10,
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    return (
      '$' +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(Number(amount) - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log('format Money error: ', e);
    return '';
  }
}

export function isValidDate(year: number, month: number, day: number) {
  const date = new Date(year, month, day);
  const isValidDate = Boolean(date) && date.getDate() === day;
  return isValidDate;
}

export const getEastCoastDateTime = (date: Date) => {
  const now = moment(date).tz('America/New_York');
  const formatted = now.format('M/D/YYYY, HH:mm:ss'); // purposefully using M and D instead of MM/DD to replicate .toLocaleString
  return formatted;
};

export const formatToPayrix = (date: string) => {
  // Payrix wants dates in this format - YYYYMMDDHHII
  if (
    date.indexOf('/') < 0 ||
    date.lastIndexOf('/') < 0 ||
    date.indexOf(':') < 0 ||
    date.lastIndexOf(':') < 0
  ) {
    return 'date not in correct format';
  }
  const month = parseInt(
    date.substring(0, date.indexOf('/')),
    10,
  ).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const day = date.substring(date.indexOf('/') + 1, date.lastIndexOf('/'));
  const year = date.substring(
    date.lastIndexOf('/') + 1,
    date.lastIndexOf('/') + 5,
  );
  const hour = date.substring(date.indexOf(':') - 2, date.indexOf(':'));
  const minute = date.substring(date.indexOf(':') + 1, date.indexOf(':') + 3);
  return year + month + day + hour + minute;
};

export function sendAbandon(
  updateForm: ISignUpForm,
  flags: any,
  preloadedFlags: any,
) {
  // not using await bc user doesnt need to wait for this info to move to next page
  Axios.put(
    `/api/currencypay/abandon`,
    formatPayload(updateForm, flags, preloadedFlags),
  );
}

export function isValidSfId(sfId: string) {
  if (sfId.length !== 18 || !ALPHA_NUMERIC_REGEX.test(sfId)) {
    return false;
  }
  const sf32Arr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ012345'.split('');
  const sumMap = '16,8,4,2,1'.split(',');
  const checkSums = sfId.substring(15);
  sfId = sfId.replace(/[a-z1-9]/g, '0');
  sfId = sfId.replace(/[A-Z]/g, '1');
  for (let i = 0; i < 3; i++) {
    const idArr = sfId
      .substring(i * 5, (i + 1) * 5)
      .split('')
      .reverse();
    let idSum = 0;
    for (let j = 0; j < 5; j++) {
      if (idArr[j] === '1') {
        idSum += parseInt(sumMap[j], 10);
      }
    }
    if (sf32Arr[idSum] !== checkSums[i]) {
      return false;
    }
  }
  return true;
}

export function getMerchantQueryParams(search: any) {
  const params = new URLSearchParams(search);
  const initForm: any = {
    accountId: params.get('accountId'),
    curPaySiteRef: params.get('ref') ? params.get('ref') : '',
    partnerId: params.get('partnerId'),
    utm: {
      campaign: params.get('utm_campaign'),
      content: params.get('utm_content'),
      medium: params.get('utm_medium'),
      name: params.get('utm_name'),
      source: params.get('utm_source'),
      type: params.get('utm_type'),
    },
  };
  return initForm;
}

export function determineInitialAccType(types: AccountType[]): string {
  let financing = false;
  let processing = false;

  types.forEach(type => {
    if (type === PAYMENT_OPTIONS.FINANCING) {
      financing = true;
    } else if (type === PAYMENT_OPTIONS.PROCESSING) {
      processing = true;
    }
  });

  if (processing && financing) {
    return ACCOUNT_TYPES.CORE;
  } else {
    return ACCOUNT_TYPES.LITE;
  }
}

export const paymentProcessingRequested = (
  servicesRequested: AccountType[],
): boolean => servicesRequested.includes(PAYMENT_OPTIONS.PROCESSING);

export const removeSpecialCharsAndSpace = (str: string) => {
  return str.replace(/[^A-Z0-9]/gi, '');
};
