import { withLDConsumer } from 'ldclient-react';
import React from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IEntity, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import { sendAbandon, shapeUtility } from '../../../utility/helper';
import { Button } from '../../ui/Button';
import { Radio } from '../../ui/Radio';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IAccountTypeState {
  accountType: string;
  errorMsg: string;
  valid: boolean;
}
const BUSINESS = 'Business';
const ORGANIZATION = 'Organization';
const SOLEPROPRIETOR = 'Sole Proprietor';

export class AccountType extends React.Component<
  IMerchantSignupProps,
  IAccountTypeState
> {
  state = {
    accountType: '',
    errorMsg: '',
    valid: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.type
    ) {
      this.setState({ accountType: this.props.form.entity.type });
      if (
        this.props.form.entity.type === BUSINESS ||
        this.props.form.entity.type === ORGANIZATION ||
        this.props.form.entity.type === SOLEPROPRIETOR
      ) {
        this.setState({ valid: true });
      }
    }
  }

  handleSubmit = async (event: any) => {
    event.preventDefault();
    this.selectedTypeCheck(this.state.accountType);
    if (this.state.valid) {
      const { accountType } = this.state;
      const { form } = this.props;
      const entity: IEntity = shapeUtility(form);
      switch (accountType) {
        case BUSINESS:
        case ORGANIZATION:
          entity.type = accountType;
          entity.email = '';
          entity.phone = '';
          entity.public = false;
          entity.merchant.members = [];
          break;
        case SOLEPROPRIETOR:
          if (form && form.email && form.phone) {
            entity.email = form.email;
            entity.phone = form.phone;
          }
          entity.type = SOLEPROPRIETOR;
          entity.public = false;
          entity.merchant.members[0] = {
            address: '',
            city: '',
            dob: '',
            email: this.props.form.email,
            first: this.props.form.first,
            last: this.props.form.last,
            line1: '',
            ownership: 100,
            phone: this.props.form.phone,
            primary: true,
            ssn: '',
            state: '',
            title: 'Owner',
            zip: '',
          };
          break;
        default:
          this.setState({ valid: false });
          return;
      }
      sendAbandon(
        { ...form, entity },
        this.props.flags,
        this.props.preloadedFlags,
      );
      this.props.nextPage({ ...form, entity });
      return true;
    }
    return false;
  };

  selectedTypeCheck = (accountType: string) => {
    if (
      accountType &&
      (accountType === BUSINESS ||
        accountType === ORGANIZATION ||
        accountType === SOLEPROPRIETOR)
    ) {
      this.setState({ valid: true, errorMsg: '' });
      return true;
    } else {
      this.setState({ valid: false, errorMsg: '* Required' });
    }
    return false;
  };

  selectType = (event: any) => {
    this.setState({
      accountType: event.target.value || event.target.id,
      valid: true,
    });
    return this.selectedTypeCheck(event.target.value || event.target.id);
  };

  render() {
    const options = [
      {
        label: BUSINESS,
        name: BUSINESS,
        value: BUSINESS,
      },
      {
        label: ORGANIZATION,
        name: ORGANIZATION,
        value: ORGANIZATION,
      },
      {
        label: SOLEPROPRIETOR,
        name: SOLEPROPRIETOR,
        value: SOLEPROPRIETOR,
      },
    ];
    const { errorMsg } = this.state;
    return (
      <>
        <TitleSubtitle
          {...this.props}
          title='Choose your account type.'
          subtitle=''
        />
        <form>
          <Radio
            {...this.props}
            options={options}
            onClick={this.selectType}
            isChecked={this.state.accountType}
          />
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {errorMsg}
            </h6>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={3} incomplete={4} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(AccountType));
