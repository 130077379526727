export function validateEmail(input: string) {
  if (!input || input.length > 100) {
    return false;
  }
  const regExp = /^(([^<>()\[\],;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(input);
}

export function validateWebsite(input: string) {
  if (!input) {
    // optional field
    return true;
  }
  if (input.length > 500) {
    return false;
  }
  const regExp = /^(http|https)(:\/\/).+/;
  return regExp.test(input);
}

export function validateState(input: string) {
  if (!input) {
    return false;
  }
  const regExp = /^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$/;
  return regExp.test(input);
}

export const isValidRoutingNumberLength = (routing: string) => {
  if (!(routing.length === 9)) return false;
  return true;
};

export const isValidRoutingNumber = (routing: string) => {
  let sum = 0;
  for (let i = 0; i < routing.length; i += 3) {
    sum +=
      parseInt(routing.charAt(i), 10) * 3 +
      parseInt(routing.charAt(i + 1), 10) * 7 +
      parseInt(routing.charAt(i + 2), 10);
  }

  // If the resulting sum is an even multiple of ten (but not zero),
  // the ABA routing number is good. From: http://www.brainjar.com/js/validation/
  return sum !== 0 && sum % 10 === 0;
};

export const isValidAccountNumber = (account: string) => {
  return !(account.length < 5 || account.length > 20);
};

export function validatePassword(input: string) {
  if (!input || input.length > 100) {
    return false;
  }
  const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regExp.test(input);
}

export function isNotAlphaNumeric(input: string) {
  const reg = new RegExp(/[^a-z0-9]/gi);
  return reg.test(input);
}

export function validateOfferCode(offerCode: string) {
  if (offerCode.length >= 14) {
    return true;
  } else {
    return false;
  }
}

export function validatePhone(phone: string) {
  if (phone.length >= 12) {
    return true;
  } else {
    return false;
  }
}

export function validateName(input: string) {
  if (!input) {
    return false;
  }
  if (input.length < 2 || input.length > 100) {
    return false;
  }
  return true;
}

export function validateCity(input: string) {
  if (!input) {
    return false;
  }
  if (input.length < 2 || input.length > 20) {
    return false;
  }
  return true;
}

export function validateLine1(input: string) {
  return input?.length >= 2;
}
