import * as types from '../actions/action-types';

const initialState = {
  error: null,
  loadingFlags: false,
  preloadedFlags: {},
};

export default function demo(state = initialState, action: any) {
  const { flags, error } = action;

  switch (action.type) {
    case types.FETCH_FLAGS:
      return {
        ...state,
        error: null,
        loadingFlags: true,
      };
    case types.FETCH_FLAGS_SUCCESS:
      return {
        ...state,
        error: null,
        loadingFlags: false,
        preloadedFlags: flags,
      };
    case types.FETCH_FLAGS_ERROR:
      return {
        ...state,
        error,
        loadingFlags: false,
        preloadedFlags: {},
      };
    default:
      return state;
  }
}
