import * as React from 'react';
import * as styles from '../../../styles/form.css';
import { NavBar } from '../../MerchantSignup/NavBar';

export default () => {
    return (
        <>
            <NavBar />
            <div className={styles.msg}>
                <h1 className={styles.textPrimary}>Terms of Use</h1>
                <h4 className={styles.textSecondary}>Updated 1/21/2022</h4>
                <h3 className={styles.textSecondary}>
                    <strong>1. TERMS OF USE AGREEMENT FOR ALL SERVICES AND THE WEBSITES</strong>
                </h3>
                &nbsp;
                <p className={styles.regularWeight}>
                    Express Tech-Financing, LLC dba Currency, requires that you carefully read, understand and agree to the following Terms and
                    Conditions contained in this Terms of Use Agreement ("Agreement"). By accessing, browsing or using this website, and the websites
                    of the below listed companies, divisions or service groups, or any page thereof, through any direct or indirect means
                    (individually or collectively, "Websites" or "Website"), or by using the goods, facilities or services offered in or through the
                    Websites through alternative methods (including, for example, telephone, mail, text, email or facsimile), you accept and agree to
                    be bound by these Terms of Use (the terms "Website" and "Websites" include use through these alternative methods).
                </p>
                <p>
                    THIS AGREEMENT CONTAINS AN AGREEMENT TO ARBITRATE ALL CLAIMS AND CONTAINS DISCLAIMERS OF WARRANTIES AND LIABILITY (please see
                    sections 12 and 5). These provisions form an essential basis of our bargain. If you do not agree to these terms and conditions,
                    you are not authorized to access or use the Websites and you are to cease accessing or otherwise using the Websites. For purposes
                    of this Agreement, "You" or "Your" means the person(s) using the Websites, and/or the goods, facilities or services of Express
                    Tech-Financing, LLC (Site and/or Services) and its companies offered through alternative methods, including persons that allow
                    others to provide information about themselves to Express Tech-Financing (as defined below), the banks, lenders, financial
                    institutions, providers, and brokers on Express Tech-Financing's network ("Lenders"), Providers (as defined in section 3), or
                    partners (as defined in sections 3). "Express Tech-Financing", "us" or "we" means, but is not limited to the following entities:
                    Express Tech-Financing, LLC, their affiliates, parents, subsidiaries, successors or assigns, and their collective members,
                    managers, directors, employees, contractors, agents and service providers.
                </p>
                <p>
                    THE DISCLOSURES AND CONSENT REQUIRED UNDER CERTAIN STATE LAWS ARE DEEMED TO BE PROVIDED, RECEIVED AND AGREED TO BY USE OF THE
                    WEBSITES. Express Tech-Financing, operates the Websites and shall have the right at any time to change or discontinue any aspect
                    or feature of the Websites and the right to modify the terms and conditions applicable to users of the Websites, including these
                    Terms of Use, or any part hereof. Such changes, modification, additions or deletions shall be effective immediately upon posting
                    on the Websites. Any use by you of the Websites or the goods, facilities or services offered in or through the Websites shall be
                    deemed to constitute acceptance of such changes. By accessing our Sites you agree to be bound by these Terms of Use. The Sites are
                    intended for individuals who are at least 18 years of age and reside in the United States and the District of Columbia. If you are
                    under 18 years of age or reside outside of the United States or the District of Columbia, you should not be visiting this Site or
                    utilizing Express Tech-Financing Services.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>2. LOAN REQUEST SERVICES</strong>
                </h3>
                <p>
                    Express Tech-Financing offers loan and lease request services, explained in more detail below. NOTE: Pursuant to the contracts
                    between Express Tech-Financing and the Lenders on its Network, Express Tech-Financing may also receive information from your
                    Lender regarding the status of your application including, but not limited to, the amount or disposition of your loan. Information
                    specific to your Lenders use of your information will be found in your Lender’s specific Privacy Policy. NOTE: You are providing
                    express written consent for Express Tech- Financing and the selected Lender to share this private personally identifiable
                    information for these purposes, litigation, regulatory request(s), law enforcement requests, internal analytics and/or for
                    marketing purposes.
                </p>
                <h4 className={styles.textSecondary}>A. Terms Applicable to Loan Request Services</h4>
                <p>
                    You should rely on your own judgment in deciding which available loan product, terms or Lender best suits your needs and financial
                    means. The Lender is solely responsible for its services to you, and you agree that Express Tech-Financing shall not be liable for
                    any damages or costs of any type arising out of or in any way connected with your use of such services. You understand that
                    Lenders may keep your loan request information and any other information provided by Express Tech-Financing or received by them in
                    the processing of your loan request, whether or not you are qualified for a loan with them or if you make a loan with them. You
                    agree to notify any particular Lender directly if you no longer want to receive communications from them.
                </p>
                <p>
                    The Websites and the services provided by Express Tech-Financing are available in connection with non-mortgage loans unless
                    otherwise specified. Loans may only be made to residents of states where Lenders are licensed or authorized to make such loans.
                    Lenders are not attempting to make loans outside of their authorized states or country by participating in and offering their
                    products on the Websites. Express Tech-Financing and Lenders expressly reserve the right to discontinue, suspend or terminate the
                    offering of any loan product in any specific state through the Websites at any time, without prior notice.
                </p>
                <p>
                    Express Tech-Financing does not guarantee acceptance into any particular loan program or specific loan terms or conditions with
                    itself any other Lender; loan approval standards are established and maintained solely by individual Lenders. Likewise, Express
                    Tech-Financing does not guarantee that the loan terms or rates offered and made available by Lenders are the best terms or lowest
                    rates available in the market or that they will not change before a loan agreement is signed. A Lender's conditional loan offer
                    may be subject to market conditions, approval and qualification. The rates and fees actually provided by Lenders may be higher or
                    lower depending on your complete credit profile, collateral/property considerations (if applicable) including but not limited to
                    location, equity and value and income/asset consideration including but not limited to loan to value and debt to income ratios.
                    Unless expressly stated in writing, nothing contained herein shall constitute an offer or promise for a loan commitment or
                    interest rate lock-in agreement. Lenders may not offer all products as well as not offer products in all states. You might not be
                    matched with the Lender making any specific offer. To help the government fight identity theft, the funding of terrorism and money
                    laundering activities, Express Tech- Financing and/or its Lenders may obtain, verify and record information that identifies each
                    person who opens an account with them. They may ask for your name, Social Security Number, address, telephone number, date of
                    birth and other important information that will allow them to properly identify you. During the Lenders' formal application
                    process, they may also ask to see your driver’s license or other identifying documentation to further assist in certifying your
                    identity.
                </p>
                <p>
                    Express Tech-Financing is paid a marketing lead generation fee by Lenders for the goods, facilities and services provided. Your
                    use of the Websites and/or Express Tech-Financing's services constitutes your agreement with this compensation arrangement. At
                    closing, you will be responsible for paying for any closing costs associated with your loan (such as loan processing, underwriting
                    or funding fees).
                </p>
                <p>
                    By clicking on any button indicating an acceptance or agreement to terms, a continuance of processing or submission ("submission")
                    you understand that you are consenting, acknowledging and agreeing to the stated terms and conditions of that submission and that
                    you are submitting an inquiry as to a lending product through Express Tech-Financing to each of the Lenders to whom your loan
                    request is transmitted. You are also indicating that you are consenting, acknowledging and agreeing to receive important notices,
                    disclosures and other communications ("Disclosures and Communications") in electronic form (either by email, SMS message or via
                    the Internet). In addition, you acknowledge that we may access your credit file even if your Social Security Number is not
                    provided and that you have received and reviewed and, where applicable, signed the necessary required disclosures. Not all lenders
                    will receive and/or utilize your SSN to access your credit report and/or credit score. Express Tech-Financing does not perform
                    hard inquiries into credit, rather it does a soft inquiry to adhere to Anti-Money Laundering requirements and to assist in
                    identification and verification of consumers as well as to prevent fraud. Lenders to whom your application is submitted by Express
                    Tech-Financing may perform hard inquiries, meaning that Lenders’ inquiries will appear on your credit report and may adversely
                    affect your credit score. The information Express Tech-Financing receives from your credit report and/or credit score may be
                    shared with Lenders, and is also used by internal personnel on a need to know basis. From time-to-time Express Tech-Financing is
                    examined by State and Federal Regulators and as such is required to maintain your Personally Identifiable Information to adhere to
                    those requirements. Your information may also be obtained from Lenders after you are matched to adhere to state and federal
                    reporting and record retention requirements. You authorize Express Tech-Financing and its participating Lenders to share such
                    information as required, including for the purpose of providing you with the services for which you apply.
                </p>
                <p>
                    By submitting the loan request, you are extending an express invitation to Express Tech-Financing and each Lender you have been
                    matched with to contact you by telephone at the numbers (whether landline or cellular) you have provided so they may assist you
                    with your transaction, and you hereby consent to any such calls even if your phone number is on any Do Not Call list, or by email
                    at the email address you provided or at another address that may be associated with you that we receive from Lenders or other
                    parties and you hereby consent to any such email so it will not be considered spam or unauthorized by any local, state or federal
                    law or regulation. In addition, you consent, acknowledge and agree that Express Tech-Financing, a lender with whom you have been
                    matched or an associated third party may use an automatic dialing system, prerecorded call or SMS text message in connection with
                    calls made to any telephone number you entered, even if it is a cellular phone number or other service for which the called
                    person(s) could be charged for such call. By saving your information with Express Tech- Financing or by submitting information,
                    you give Express Tech-Financing permission to retain all such provided information and to make live or recorded calls to discuss,
                    provide or remind you of any information in regards to your submission, including incomplete loan requests, the delivery of loan
                    request matches, deadlines, quality of services or other matters in connection with your loan request. For any service, you
                    represent that all of the information you have provided in your submission and loan request is true and complete.&nbsp;
                    <strong>
                        You expressly consent to receive email and text (SMS) communications for marketing and collections purposes.&nbsp;
                    </strong>
                    You have the right to receive any document in non-electronic form and to withdraw your consent to electronic delivery at any time
                    by contacting Express Tech-Financing Customer Service at (844) 724-7376 or by emailing&nbsp;
                    <a href='mailto:Customerservice@gocurrency.com'>Customerservice@gocurrency.com.</a>
                </p>
                <p>
                    When you "submit" information, create an account or otherwise register for services through Express Tech-Financing, and its
                    website or otherwise, you understand and agree that you have established a business relationship between you and Express
                    Tech-Financing, which is the owner of this site. Accordingly, Express Tech-Financing may send your information to its affiliates
                    as provided in the Privacy Policy and you agree that Express Tech-Financing may contact you using information you provided with
                    information and offers of services available through Express Tech-Financing and the Websites. You hereby consent to any such
                    communication or phone calls even if your phone number is on any Do Not Call list. If at any time you do not wish to continue to
                    receive communications from Express Tech-Financing, LLC or its Companies’, Lenders’ or its affiliates’ communications, you may
                    email your request to&nbsp;
                    <a href='mailto:unsubscribe@gocurrency.com'>unsubscribe@gocurrency.com&nbsp;</a>
                    or follow the unsubscribe process within email communication sent to you. In addition, you agree to notify Express Tech-Financing
                    Customer Care at (844) 724-7376 or by emailing&nbsp;
                    <a href='mailto:unsubscribe@gocurrency.com'>unsubscribe@gocurrency.com&nbsp;</a>
                    directly if you no longer want to receive other forms of communications from us.
                </p>
                <h4 className={styles.textSecondary}>B. Express Tech-Financing Loan Request Services</h4>
                <p>
                    The Express Tech-Financing services provide for multiple loan conditional offers through their networks of nonaffiliated Lenders.
                    By submitting the loan request form and/or Express application, you authorize Express Tech-Financing to provide information
                    provided by you or that Express Tech-Financing has or may receive from others, to Lenders on their networks, along with any
                    additional Lenders or investors necessary to complete your loan transaction. You also authorize Express Tech-Financing and its
                    Lenders along with any additional Lenders or investors necessary to complete your loan transaction, to request from one or
                    multiple credit bureaus /reporting agencies, your credit bureau report including any ancillary credit scores or ratings and to
                    verify that your request is true and accurate. By clicking "Continue" (or any button indicating acceptance) you are certifying
                    that all of the information you have provided in your loan request is true, accurate and complete. At the present time, Fair Isaac
                    Corporation (the company that provides "FICO" credit scores) reports that, for mortgage or auto loans, it disregards those
                    inquiries made in the 30 days prior to the lender receiving the credit score and that beyond the 30 days, it consolidates all
                    credit inquiries that occur within a 14-day period into one inquiry for scoring purposes. Some Lenders will require additional
                    information prior to providing an offer. In these situations, the Lender(s) will contact you directly to discuss your loan request
                    and to discuss the specific terms of your offer(s). In the process, you may be given the option to provide your Social Security
                    Number and/or have your credit pulled in order to improve your Lender matches. The Lenders you are matched with may later require
                    a Social Security Number to proceed with your loan request.
                </p>
                <p>
                    To determine which Lenders may be matched with you, Lenders provide us criteria about the type of loan (for example, loan amount
                    or credit terms) and the type of loan customer (for example, state of residence or creditworthiness) in which the Lender is
                    interested. Express Tech- Financing will provide your information to Lenders whose criteria match your profile.
                </p>
                <p>
                    Express Tech-Financing is not affiliated with consumer loan Lenders in any way other than as a distributor of information
                    regarding Lenders' consumer loan products and services. All decisions regarding any consumer loan applications submitted to
                    Lenders are solely the right and responsibility of such Lenders.
                </p>
                <h4 className={styles.textSecondary}>C. Additional Terms Applicable to Auto Loan Request Services</h4>
                <p>
                    If you request an auto loan and qualify for the Dealer Referral Program, Express Tech-Financing may also provide your information
                    with one or more of the Lenders, providers or third-party marketing companies in that program. In addition, you may be matched
                    with one or more Lenders offering unsecured loans with which you could finance your automobile, titled vehicle or motorcycle. As
                    such, when you click on the submit button you are consenting, acknowledging and agreeing to a hard pull of credit by up to five
                    lenders in relationship to your automobile loan inquiry. Please note, that you are providing Written Consent for such inquiry and
                    that this may impact your credit score. FICO (Fair Isaac Corporation) recognizes that borrowers typically shop around, and
                    consumers are encouraged to find the best deal that works for them and their personal financial situation. That's why inquiries
                    from multiple auto loan applications generally will not hurt your score for 30 days. Once a month has passed, the FICO scoring
                    model treats multiple inquiries for one of those loan types as a single inquiry, provided the applications all took place within a
                    relatively short window of time such as 45 days. That consolidation of inquiries helps limit the FICO score damage that would
                    otherwise be caused by multiple credit checks, each counted individually. You may contact FICO online at&nbsp;
                    <a href='https://www.fico.com/en/contact-us'>https://www.fico.com/en/contact-us</a>
                    &nbsp; with questions.
                </p>
                <h4 className={styles.textSecondary}>
                    D. Additional Terms Applicable To Credit Card, Personal Loan And Commercial Loan Request Services
                </h4>
                <p>
                    If you request a credit card, personal loan or commercial loan, Express Tech-Financing may also provide your information with one
                    or more of the lenders or providers in that program.
                </p>
                <p>
                    Notice For California Residents: A married consumer may request separate credit. A married consumer may include a spouse’s income
                    even when requesting for a separate account.
                </p>
                <p>
                    Notice For Ohio Residents: The Ohio laws against discrimination require that all creditors make credit equally available to all
                    credit worthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request.
                    The Ohio Civil Rights Commission administers compliance with the law.
                </p>
                <p>
                    Notice For Married Wisconsin Residents: No provision of a marital property agreement (pre-marital agreement), a unilateral
                    statement under Section 766.59 of the Wisconsin Statutes, or a court decree under Section 766.70 of the Wisconsin Statutes
                    adversely affects the interest of the creditor unless, prior to the time the credit is granted, the creditor is furnished a copy
                    of the marital property agreement, statement, or decree or has actual knowledge of the adverse provision when the obligation to
                    the creditor is incurred. If the loan for which you are applying is granted, your spouse will also receive notification that
                    credit has been extended to you.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>3. REQUEST FOR OTHER PRODUCTS AND SERVICES (INCLUDING FINDER/LOCATOR SERVICES)</strong>
                </h3>
                <p>
                    Express Tech-Financing offers requests for other products and services on the Websites. You may be able to access these products
                    and services when you complete a request and either (i) get matched with potential third party providers ("Provider" includes any
                    third-party affiliate or finder of a Provider) who will respond with conditional offers for products or services or (ii) get
                    provided with the name and information about potential Providers who may able to respond with the requested products or services
                    or information about the requested products or services without any offers. The Providers that can provide you with conditional
                    offers may not respond with offers until they speak with you to get additional information. NOTE: You are providing express
                    written consent for Express Tech-Financing and the selected Lender to share this private personally identifiable information for
                    these purposes, collection, litigation, regulatory request(s), law enforcement requests, internal analytics and/or for marketing
                    purposes.
                </p>
                <h4 className={styles.textSecondary}>A. Terms Applicable to All Requests for other Products and Services</h4>
                <p>
                    Express Tech-Financing does not make any decisions in connection with the products or services offered by Providers. Express Tech-
                    Financing does not endorse or recommend the products or services of any particular Provider on any of its Websites. Express
                    Tech-Financing is not an agent of either you or any Provider. You should rely on your own judgment in deciding which available
                    product or service and Provider best suits your needs and financial means. The Provider is solely responsible for its services to
                    you, and you agree that Express Tech-Financing shall not be liable for any damages or costs of any type arising out of or in any
                    way connected with your use of such Provider products or services. You understand that Providers may keep your request information
                    and any other information provided by Express Tech- Financing or received by them in the processing of your request, whether or
                    not you are qualified for the product or service with them or if you acquire a product or service with them. You agree to notify
                    any particular Provider directly if you no longer want to receive communications from them. Products and services may only be made
                    to residents of states where Providers are licensed or authorized to make such products or services available. Express
                    Tech-Financing and Providers expressly reserve the right to discontinue, suspend or terminate the offering of any product or
                    service in any specific state through the Websites at any time, without prior notice.
                </p>
                <p>
                    The data and other information you may provide Express Tech-Financing is not, and is not treated as, an application for that
                    product or service. Express Tech-Financing does not guarantee acceptance into any particular program or specific term or condition
                    with any Provider; approval standards are established and maintained solely by individual Providers. Likewise, Express
                    Tech-Financing does not guarantee that the terms or rates for the products or services offered and made available by Providers are
                    the best terms or lowest rates available in the market. A Provider's products or services may be subject to market conditions,
                    approval and qualification. The terms and rates actually provided by Providers may be higher or lower depending on your individual
                    financial profile, your location and other considerations. Unless expressly stated in writing, nothing contained herein shall
                    constitute an offer or promise for a product or service. Providers may not offer all products or services as well as not offer
                    products or services in all states. You may not be matched with the Provider making any specific offer. Express Tech-Financing is
                    paid a marketing lead generation fee by either the Provider or a third party "finder" (if the finder markets for or to the
                    Provider) for the goods, facilities and services Express Tech-Financing provided. Your use of the Websites and/or Express Tech-
                    Financing's services constitutes your agreement with this compensation arrangement. You will be responsible for paying for any
                    cost associated with the product or service.
                </p>
                <p>
                    By clicking on any button indicating an acceptance or agreement to terms, a continuance of processing or submission ("submission")
                    you understand that you are consenting, acknowledging and agreeing to the stated terms and conditions of that submission and that
                    you are submitting an inquiry as to a lending product through Express Tech-Financing to each of the Lenders to whom your loan
                    request is transmitted. You are also indicating that you are consenting, acknowledging and agreeing to receive important notices,
                    disclosures and other communications ("Disclosures and Communications") in electronic form (either by email, SMS message or via
                    the Internet). In addition, if you proceed with a Loan Application with any Express Tech-Financing Network Lenders, they can
                    provide you with the Disclosures and Communications in Electronic form. You understand that you will need, and have access to an
                    email address, Internet access and PDF software to review the Disclosures and Communications. In addition, you acknowledge that we
                    may access your credit file even if your Social Security Number is not provided and that you have received and reviewed and, where
                    applicable, signed the necessary required disclosures. By submitting the request, you are extending an express invitation to each
                    Provider to contact you by telephone or SMS message at the numbers (whether landline or cellular) you have provided so they may
                    assist you with your transaction, and you hereby consent to any such calls even if your phone number(s) are on any Do Not Call
                    list, or by email at the email address you provided or at another address that may be associated with you that we receive from
                    Providers or other parties and you hereby consent to any such email or message so it will not be considered spam or unauthorized
                    by any local, state or federal law or regulation. In addition, you consent, acknowledge and agree that Express Tech- Financing, a
                    Lender with whom you have been matched or an associated third party may use an automatic dialing system in connection with calls
                    made to any telephone number you entered, even if it is a cellular phone number or other service for which the called person(s)
                    could be charged for such call. By saving your information with Express Tech-Financing or by doing a submission, you give Express
                    Tech-Financing permission to retain all such provided information and to make live or recorded calls to discuss, provide or remind
                    you of any information in regards to your submission, including incomplete requests, the delivery of request matches, deadlines,
                    quality of services or other matters in connection with your request. For any product or service, you represent that all of the
                    information you have provided in your submission and request is true and complete. You have the right to receive any document in
                    non-electronic form and to withdraw your consent to electronic delivery at any time by contacting Express Tech-Financing Customer
                    Service at (844) 724-7376 or by emailing&nbsp;
                    <a href='mailto:Customerservice@gocurrency.com.'>Customerservice@gocurrency.com.</a>
                </p>
                <p>
                    By submitting the request form, you authorize Express Tech-Financing to provide information that you provided or that Express
                    Tech- Financing has or may receive from others to Providers or their finders, along with any additional providers necessary to
                    complete your transaction. By submitting any eligibility form, you authorize Express Tech-Financing to provide your information to
                    Providers or finders to discuss, review or provide product or services information and opportunities. You also authorize Express
                    Tech-Financing and its Providers and finders along with any additional providers necessary to complete your transaction, to
                    request from one or multiple credit bureaus /reporting agencies, your credit bureau report including any ancillary credit scores
                    or ratings.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>4. COPYRIGHT, TRADEMARK AND SERVICE MARK NOTICES</strong>
                </h3>
                <p>
                    All contents of the Websites are: Copyright &copy; 2016-2022 Express Tech-Financing, LLC and/or its affiliates, parents,
                    subsidiaries, suppliers, Lenders or Providers, 8930 East Raintree Drive, Scottsdale, AZ 85260 All rights reserved. Other product
                    and company names mentioned herein, including the names of Lenders, may be the trademarks of their respective owners.
                </p>
                <p>
                    Nothing on the Websites should be construed as granting, by implication, estoppel or otherwise, any license or right to use any
                    service mark or trademark (individually and collectively, "Mark" or "Marks") displayed on the Websites, without the prior written
                    permission of the Express Tech-Financing or the applicable Mark holder specific for each such use. The Marks may not be used to
                    disparage Express Tech-Financing, the Lenders, Providers, the applicable third party or the products or services of such parties,
                    nor shall they be used in any manner that may damage any goodwill in the Marks. Use of any Mark as part of a link to or from any
                    site is prohibited unless establishment of such a link is approved in advance by Express Tech-Financing in writing.
                </p>
                <p>
                    The Websites contain copyrighted material, trademarks and other proprietary information, including, but not limited to, text,
                    software, photos, video, graphics, music and sound, and the entire contents of the Websites are copyrighted as a collective work
                    under the United States copyright laws. Express Tech-Financing owns a copyright in the selection, coordination, arrangement and
                    enhancement of such content, as well as in the content original to it. You may not modify, publish, transmit, participate in the
                    transfer or sale, create derivative works or in any way exploit, any of the content, in whole or in part. Except as otherwise
                    expressly permitted under copyright law, no copying, redistribution, retransmission, publication or commercial or non-commercial
                    exploitation of downloaded material will be permitted without the express written permission of Express Tech-Financing and the
                    copyright owner. Elements of the Websites are protected by trade dress, trademark, unfair competition, and other state and federal
                    laws and may not be copied or imitated in whole or in part, by any means, including but not limited to, the use of framing or
                    mirrors.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>5. DISCLAIMERS AND LIABILITY</strong>
                </h3>
                <p>
                    Express Tech-Financing intends that the information contained in the Websites be accurate and reliable; however, errors sometimes
                    occur. The information is provided as a service to the general public over the age of eighteen and is subject to change without
                    prior notice. In addition, changes and improvements to the information provided herein and are provided "as-is" without warranty
                    of any kind, implied, expressed or otherwise. Under no circumstances will Express Tech-Financing be liable for any loss or damage
                    caused by your reliance on information obtained through the Websites. It is your responsibility to evaluate the accuracy,
                    completeness or usefulness of any information, opinion, advice or other content available through the Websites.
                </p>
                <p>
                    THE WEBSITES AND THE INFORMATION, SOFTWARE, PRODUCTS AND SERVICES ASSOCIATED WITH IT ARE PROVIDED "AS IS". EXPRESS TECH-FINANCING
                    AND/OR ITS SUPPLIERS, LENDERS OR PROVIDERS DISCLAIM ANY WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO ANY MATTER
                    WHATSOEVER RELATING TO THE WEBSITES AND ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES PROVIDED HEREIN, INCLUDING WITHOUT
                    LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT. USE OF THE
                    WEBSITES AND/OR EXPRESS TECH-FINANCING'S SERVICES IS AT YOUR OWN RISK. EXPRESS TECH- FINANCING AND/OR ITS SUPPLIERS, LENDERS OR
                    PROVIDERS ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR OTHER INJURY ARISING
                    OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR THE INABILITY TO USE THE WEBSITES AND/OR EXPRESS TECH-FINANCING'S SERVICES OR WITH
                    THE DELAY OR INABILITY TO USE THE WEBSITES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES OBTAINED THROUGH THE WEBSITES,
                    INCLUDING BUT NOT LIMITED TO RELIANCE BY YOU ON ANY INFORMATION OBTAINED AT THE WEBSITES, OR THAT RESULT FROM MISTAKES, OMISSIONS,
                    INTERRUPTIONS, DELETION OF FILES OR EMAILS, ERRORS, DEFECTS, VIRUSES, WORMS, TROJAN HORSES, TRAP DOORS, BACK DOORS, EASTER EGGS,
                    TIME BOMBS, CANCELBOTS OR OTHER CODE OR COMPUTER PROGRAMMING ROUTINES THAT CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES OR THAT
                    ARE INTENDED TO DAMAGE, DETRIMENTALLY INTERFERE WITH, SURREPTITIOUSLY INTERCEPT OR EXPROPRIATE ANY SYSTEM, DATA OR PERSONAL
                    INFORMATION, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD,
                    COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO EXPRESS TECH-FINANCING RECORDS, PROGRAMS OR SERVICES, OR
                    OTHERWISE ARISING OUT OF THE USE OF THE WEBSITES, WHETHER RESULTING IN WHOLE OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS
                    BEHAVIOR, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF EXPRESS TECH-FINANCING AND/OR ITS SUPPLIERS OR PROVIDERS HAVE BEEN
                    ADVISED OF THE POSSIBILITY OF DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION
                    MAY NOT APPLY TO YOU.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>6. ELECTRONIC COMMUNICATIONS/NOTICES AND INFORMATION DELIVERED ELECTRONICALLY</strong>
                </h3>
                <p>
                    Express Tech-Financing, Lenders and Providers may choose to electronically deliver all information related to its services and
                    your requests. Express Tech-Financing, Lenders and Providers electronic communications to you may transmit or convey information
                    about action taken on your request, portions of your request that may be incomplete or require additional explanation, any notices
                    required under applicable law, which may include any Federal Truth-in-Lending disclosures, State disclosures, other terms,
                    conditions and documents, and the privacy policies of Express Tech-Financing, Lenders and Providers.
                </p>
                <p>
                    You agree to receive all current and future notices, disclosures, communications and information, and to do business
                    electronically with Express Tech-Financing, Lenders and Providers. This means that Express Tech-Financing, Lenders and Providers
                    may communicate with you by sending a message to the email address or telephone number you provided or at another address or
                    telephone number that may be associated with you that we receive from Lenders, and Providers or other parties. You agree that you
                    meet the below technical requirements and are able to access and retain copies of notices and information sent or made available
                    electronically.
                </p>
                <p>Technical Requirements:</p>
                <p>
                    To access and retain information and notices we send or make available to you electronically, you will need: Internet access with
                    128-bit encryption Adobe Acrobat Reader 6 or higher Ability to Print Internet Explorer 6 or higher Netscape 8.04 and above (Use in
                    IE mode) Email Access or Firefox version 1.5 You have the right to receive any document in non-electronic form and to withdraw
                    your consent to electronic delivery at any time by contacting Express Tech-Financing Customer Service at (844) 724-7376 or by
                    emailing&nbsp;
                    <a href='mailto:Customerservice@gocurrency.com'>Customerservice@gocurrency.com.</a>
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>7. PRIVACY POLICY</strong>
                </h3>
                <p>
                    The Express Tech-Financing&nbsp;
                    <a href='https://signup.currencypay.com/merchant/privacy'>Privacy Policy</a>, hereby incorporated by reference into this
                    Agreement, explains the policy applicable to the information that is collected through the Websites or received directly from you.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>8. INDEMNITY</strong>
                </h3>
                <p>
                    As a condition of use of the Websites and/or Express Tech-Financing's services, you agree to indemnify and defend Express
                    Tech-Financing and its suppliers, Lenders or Providers from and against any and all liabilities, expenses (including attorneys'
                    fees) and damages arising out of claims resulting from your use of the Websites, including without limitation any claims alleging
                    facts that if true would constitute a breach by you of this Agreement.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>9. LIMITATION ON DAMAGES</strong>
                </h3>
                <p>
                    In no event will Express Tech-Financing have any liability to you in excess of actual compensatory damages. You irrevocably waive
                    any claim to direct, indirect, special, consequential, punitive or exemplary damages.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>10. LINKS TO THIRD PARTY WEBSITES</strong>
                </h3>
                <p>
                    The Websites may contain links to websites maintained by third parties. Such links are provided for your convenience and reference
                    only. Express Tech-Financing does not operate or control in any respect any information, software, products or services available
                    on such websites. Express Tech-Financing's inclusion of a link to a website does not imply any endorsement of the services or the
                    website, its contents, or its sponsoring organization. When you leave the Websites please note that Express Tech-Financing is not
                    responsible for the accuracy or content of the information provided by that website, nor is it liable for any direct or indirect
                    technical or system issues arising out of your access to or use of third party technologies or programs available through that
                    website.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>11. ERRORS AND DELAYS</strong>
                </h3>
                <p>
                    Express Tech-Financing is not responsible for any errors or delays in responding to a request or referral form caused by,
                    including but not limited to, an incorrect email address provided by you or other technical problems beyond our reasonable
                    control.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>12. DISPUTE RESOLUTION</strong>
                </h3>
                <p>
                    Any claim or controversy arising out of or relating to the use of the Websites, to the goods or services provided by Express
                    Tech-Financing, or to any acts or omissions for which you may contend Express Tech-Financing is liable, including but not limited
                    to any claim or controversy as to arbitrability ("Dispute"), shall be finally, and exclusively, settled by arbitration. The
                    arbitration shall be held before one arbitrator under the Supplementary Procedures for Consumer Related Disputes of the American
                    Arbitration Association ("AAA") dated September 15, 2005. The arbitration shall be venued in a location reasonably convenient to
                    the consumer. The arbitrator shall be selected pursuant to the AAA rules. Should no AAA rule regarding the selection of an
                    arbitrator be in effect, the parties shall agree on an arbitrator mutually agreeable to both parties. Should the AAA decline to
                    accept a matter submitted for individual (non-class) arbitration under these Terms of Use, the parties shall jointly select
                    another arbitrator. In the event the parties are unable to agree on a neutral arbitrator, the claimant may file suit in any court
                    of competent jurisdiction solely for the purpose of requesting the appointment of an arbitrator for individual (non-class)
                    arbitration, as provided for in Section 5 of the Federal Arbitration Act. To begin the arbitration process, a party must make a
                    written demand therefor.
                </p>
                <p>
                    Any judgment upon the award rendered by the arbitrators may be entered in any court of competent jurisdiction. The agreement to
                    arbitrate shall not be construed as an agreement to the joinder or consolidation of arbitration under this agreement with
                    arbitration of disputes or claims of any non-party, regardless of the nature of the issues or disputes involved. To the fullest
                    extent permitted by applicable law, no arbitration under this Agreement shall be joined to an arbitration involving any other
                    party subject to this Agreement, whether through class arbitration proceedings or otherwise.
                </p>
                <p>
                    THIS AGREEMENT PROVIDES THAT ALL DISPUTES BETWEEN YOU AND Express Tech-Financing WILL BE RESOLVED BY BINDING ARBITRATION. YOU THUS
                    GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS. YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN OR BRING CLASS
                    ACTIONS. YOUR RIGHTS WILL BE DETERMINED BY NEUTRAL ARBITRATORS AND NOT A JUDGE OR JURY. You are entitled to a fair hearing, and
                    the arbitration procedures are simpler and more limited than rules applicable in court. Arbitrator decisions are enforceable as
                    any court order and are subject to very limited review by a court. By using Express Tech-Financing's goods, facilities and
                    services, you consent to these restrictions.
                </p>
                <p>
                    Should a Dispute arise and should the arbitration provisions herein become inapplicable or unenforceable, or in any instance of
                    any lawsuit between you and Express Tech-Financing, the parties agree that jurisdiction over and venue of any suit shall be
                    exclusively in the state and federal courts sitting in Los Angeles, California. If either party employs attorneys to enforce any
                    right in connection with any Dispute or lawsuit, the prevailing party shall be entitled to recover reasonable attorneys' fees.
                </p>
                <h3 className={styles.textSecondary}>
                    <strong>13. OTHER TERMS</strong>
                </h3>
                <p>
                    This Agreement (which hereby incorporates by reference any other provisions applicable to use of the Websites, including, but not
                    limited to, any supplemental terms governing the use of certain specific material contained in the Websites and any operating
                    rules for the Websites established by Express Tech-Financing) constitutes the entire agreement between you and Express
                    Tech-Financing and it supersedes all prior or contemporaneous communications, promises and proposals, whether oral, written or
                    electronic, between you and Express Tech-Financing with respect to the Websites and information, software, products and services
                    associated with it. This Agreement shall be subject to and construed in accordance with the laws of the California, excluding its
                    conflict of laws principles. If any part of this Agreement is determined to be invalid or unenforceable pursuant to applicable law
                    including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or
                    unenforceable provision will be deemed superseded by a valid enforceable provision that most closely matches the intent of the
                    original provision, and the remainder of the Agreement shall continue in effect. A printed version of this Agreement and of any
                    notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this
                    Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and
                    maintained in printed form. All rights not expressly granted herein are reserved.
                </p>
                <p>PLEASE PRINT AND RETAIN A COPY OF THIS AGREEMENT FOR YOUR RECORDS.</p>
                <br />
                <p>
                    <strong>Payrix Solutions, LLC Terms Of Service</strong>
                </p>
                <br />
                <p>
                    These terms of service are an agreement (this Agreement) between the entity accepting this Agreement (Merchant) and Payrix
                    Solutions, LLC and CurrencyPay, LLC (collectively "Company"). Use of payment processing services shall indicate an agreement to
                    the following terms:&nbsp;
                    <a href='https://portal.payrix.com/terms'>https://portal.payrix.com/terms</a>.
                </p>
            </div>
        </>
    );
};
