import isClient from '../is-client';
import { ILoggerClient, ILoggerServer } from './logger-interfaces';

export { ILoggerClient, ILoggerServer };

export default async (
  INSTRUMENTATION_KEY?: string,
): Promise<ILoggerClient | ILoggerServer> => {
  if (isClient() && INSTRUMENTATION_KEY) {
    return (await import('./client')).default(INSTRUMENTATION_KEY);
  } else {
    return await import('./server');
  }
};
