import React from 'react';
import { removeSpecialCharsAndSpace } from '../../../utility/helper';
import * as styles from './styles.css';

interface IRadioProps {
  onClick: (e: any) => void;
  options: IRadioOptions[];
  isChecked: string | null;
}

interface IRadioOptions {
  label: string;
  value: string;
  name: string;
}

const Radio: React.FunctionComponent<IRadioProps> = ({
  options,
  onClick,
  isChecked,
}) => {
  const renderRadioButtons = (options: IRadioOptions[]) => {
    const result = [];
    for (const option of options) {
      result.push(
        <div className={styles.radio} key={option.value}>
          <label>
            <input
              type='radio'
              id={removeSpecialCharsAndSpace(option.value)}
              value={option.value}
              checked={isChecked === option.value}
              name={option.name}
              onChange={onClick}
            />
            <span className={styles.radioText}>{option.label}</span>
          </label>
          <br />
        </div>,
      );
    }
    return result;
  };
  return <div className='container'>{renderRadioButtons(options)}</div>;
};

export default Radio;
