import React, { Component } from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IEntity, IMember, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import { sendAbandon, shapeUtility } from '../../../utility/helper';
import { Button } from '../../ui/Button';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  currentAdditionalOwner: IMember | null;
  currentAdditionalOwnerIndex: number;
  currentTotalOwnership: number;
  totalCountOwners: number;
  preloadedFlags: any;
  processEnv: any;
}

interface IAdditionalOwnershipState {
  title: string;
  titleError: string;
  ownership: string;
  ownershipError: string;
  valid: boolean;
  showRequired: boolean;
  requiredMsg: string;
}

export class AdditionalOwnership extends Component<
  IMerchantSignupProps,
  IAdditionalOwnershipState
> {
  state: IAdditionalOwnershipState = {
    ownership: '',
    ownershipError: '',
    requiredMsg: '*Required',
    showRequired: false,
    title: '',
    titleError: '',
    valid: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (
      this.props.currentAdditionalOwnerIndex >= 1 &&
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.merchant &&
      this.props.form.entity.merchant.members &&
      this.props.form.entity.merchant.members.length >
        this.props.currentAdditionalOwnerIndex
    ) {
      const { ownership, title } = this.props.form.entity.merchant.members[
        this.props.currentAdditionalOwnerIndex
      ];
      this.setState({
        ownership: ownership.toString(),
        title,
      });
      this.inputValidation({
        ...this.state,
        ownership: ownership.toString(),
        title,
      });
    }
    if (
      this.props.currentAdditionalOwnerIndex < this.props.totalCountOwners &&
      this.props.currentAdditionalOwner
    ) {
      const { ownership, title } = this.props.currentAdditionalOwner;
      this.setState({
        ownership: ownership.toString(),
        title,
      });
      this.inputValidation({
        ...this.state,
        ownership: ownership.toString(),
        title,
      });
    }
  }

  updateForm = (state: IAdditionalOwnershipState) => {
    const { ownership, title } = state;
    const { form } = this.props;
    const entity: IEntity = shapeUtility(form);
    const newMember: IMember = {
      address: '',
      city: '',
      dob: '',
      email: '',
      first: '',
      last: '',
      line1: '',
      ownership: parseFloat(ownership),
      phone: '',
      primary: false,
      ssn: '',
      state: '',
      title,
      zip: '',
    };
    if (
      entity.merchant &&
      entity.merchant.members &&
      entity.merchant.members.length <
        this.props.currentAdditionalOwnerIndex + 1
    ) {
      entity.merchant.members.push(newMember);
    } else {
      entity.merchant.members[this.props.currentAdditionalOwnerIndex] = {
        ...entity.merchant.members[this.props.currentAdditionalOwnerIndex],
        ...{ ownership: parseFloat(ownership), primary: false, title },
      };
    }
    return { ...form, entity };
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    if (this.state.valid) {
      const updateForm: ISignUpForm = this.updateForm(this.state);
      sendAbandon(updateForm, this.props.flags, this.props.preloadedFlags);
      this.props.nextPage(updateForm);
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  handleOwnershipChange = (event: any) => {
    let currentTotalOwnership = this.props.currentTotalOwnership
      ? this.props.currentTotalOwnership
      : 0;
    this.setState({ ownership: event.target.value });
    const ownershipNum = parseFloat(event.target.value);
    if (
      !ownershipNum ||
      typeof ownershipNum !== 'number' ||
      ownershipNum <= 0 ||
      ownershipNum > 100
    ) {
      this.setState({ ownershipError: 'Invalid ownership' });
    } else {
      if (
        this.props.currentAdditionalOwnerIndex >= 1 &&
        this.props.form &&
        this.props.form.entity &&
        this.props.form.entity.merchant &&
        this.props.form.entity.merchant.members &&
        this.props.form.entity.merchant.members.length >
          this.props.currentAdditionalOwnerIndex
      ) {
        // changing existing additional ownership - remove the previous ownership
        const currentIndex = this.props.currentAdditionalOwnerIndex;
        const currentOwners = this.props.form.entity.merchant.members;
        let ownershipCount = 0;
        currentOwners.forEach((m: IMember) => (ownershipCount += m.ownership));
        currentTotalOwnership =
          ownershipCount - currentOwners[currentIndex].ownership;
      }
      const futureTotalOwnership = currentTotalOwnership + ownershipNum;
      if (futureTotalOwnership > 100) {
        this.setState({ ownershipError: 'Total ownership cannot exceed 100%' });
      } else {
        this.setState({ ownershipError: '' });
      }
    }
    return this.inputValidation({
      ...this.state,
      ownership: event.target.value,
    });
  };

  handleTitleChange = (event: any) => {
    if (event.target.value.length < 2) {
      this.setState({ title: event.target.value, titleError: 'Invalid Title' });
    } else {
      this.setState({ title: event.target.value, titleError: '' });
    }
    return this.inputValidation({ ...this.state, title: event.target.value });
  };

  inputValidation = async (state: IAdditionalOwnershipState) => {
    const { ownership, title } = state;
    let currentTotalOwnership = this.props.currentTotalOwnership
      ? this.props.currentTotalOwnership
      : 0;
    const ownershipNum = parseFloat(ownership);
    if (
      this.props.currentAdditionalOwnerIndex >= 1 &&
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.merchant &&
      this.props.form.entity.merchant.members &&
      this.props.form.entity.merchant.members.length >
        this.props.currentAdditionalOwnerIndex
    ) {
      // changing existing additional ownership - remove the previous ownership
      const currentIndex = this.props.currentAdditionalOwnerIndex;
      const currentOwners = this.props.form.entity.merchant.members;
      let ownershipCount = 0;
      currentOwners.forEach((m: IMember) => (ownershipCount += m.ownership));
      currentTotalOwnership =
        ownershipCount - currentOwners[currentIndex].ownership;
    }
    const futureTotalOwnership = currentTotalOwnership + ownershipNum;
    if (
      typeof ownershipNum === 'number' &&
      ownershipNum > 0 &&
      ownershipNum <= 100 &&
      futureTotalOwnership <= 100 &&
      title &&
      title.length >= 2 &&
      title.length <= 100
    ) {
      this.setState({ valid: true });
      return true;
    } else {
      this.setState({ valid: false });
      return false;
    }
  };

  render() {
    const { ownershipError, requiredMsg, titleError } = this.state;
    return (
      <>
        <TitleSubtitle
          title='Tell us about the additional business owner.'
          subtitle=''
        />
        <form>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left bfh-phone ${styles.inputfield}`}
              type='number'
              max={100}
              minLength={1}
              placeholder='Additional business ownership %'
              value={this.state.ownership}
              onChange={this.handleOwnershipChange}
            />
          </div>
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {ownershipError}
            </h6>
          </div>
          {!this.state.ownership && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <input
              className={`input form-control input-lg pl-4 mt-3 mb-2 text-left ${styles.inputfield}`}
              type='text'
              placeholder='Additional owner’s title'
              minLength={2}
              maxLength={100}
              value={this.state.title}
              onChange={this.handleTitleChange}
            />
          </div>
          {!this.state.title && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {titleError}
            </h6>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <br />
          <ProgressDots complete={4} incomplete={3} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdditionalOwnership);
