import Axios from 'axios';
import { withLDConsumer } from 'ldclient-react';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IGooglePlacesDetailResponse, ISignUpForm } from '../../../interfaces';
import * as mainStyles from '../../../styles/form.css';
import {
  formatPhone,
  sendAbandon,
  shapeEmail,
  shapeUtility,
  statesList,
} from '../../../utility/helper';
import {
  validateCity,
  validateEmail,
  validateLine1,
  validateState,
} from '../../../utility/validation';
import { Button } from '../../ui/Button';
import PhoneInput from '../Inputs/Phone';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';
import * as styles from './styles.css';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface ILegalBusinessInfoState {
  address: string;
  businessEmail: string;
  emailError: string;
  daytimePhone: string;
  phoneError: string;
  line1: string;
  city: string;
  valid: boolean;
  state: string;
  suggestions: IGooglePlacesDetailResponse[];
  useGooglePlaces: boolean;
  showGoogleResult: boolean;
  zip: string;
  zipError: string;
  showRequired: boolean;
  requiredMsg: string;
}

const SOLEPROPRIETOR = 'Sole Proprietor';
export class LegalBusinessInfo extends React.Component<
  IMerchantSignupProps,
  ILegalBusinessInfoState
> {
  state: ILegalBusinessInfoState = {
    address: '',
    businessEmail: '',
    city: '',
    daytimePhone: '',
    emailError: '',
    line1: '',
    phoneError: '',
    requiredMsg: '*Required',
    showGoogleResult: false,
    showRequired: false,
    state: '',
    suggestions: [],
    useGooglePlaces: true,
    valid: false,
    zip: '',
    zipError: '',
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (this.props.form && this.props.form.entity) {
      const {
        address,
        city,
        email,
        phone,
        line1,
        state,
        zip,
        type,
      } = this.props.form.entity;
      if (address) {
        this.setState({
          address,
        });
      } else {
        this.setState({
          address: '',
          showGoogleResult: false,
        });
      }
      const fPhone = formatPhone(phone);
      this.setState({
        businessEmail: type !== SOLEPROPRIETOR && email ? email : '',
        city: city ? city : '',
        daytimePhone: fPhone,
        line1: line1 ? line1 : '',
        state: state ? state : '',
        zip: zip ? zip : '',
      });
      this.inputValidation({
        ...this.state,
        ...{
          businessEmail: email ? email : '',
          city: city ? city : '',
          daytimePhone: fPhone,
          line1: line1 ? line1 : '',
          state: state ? state : '',
          zip: zip ? zip : '',
        },
      });
    }
  }

  updateForm = (currentState: ILegalBusinessInfoState) => {
    const {
      address,
      businessEmail,
      daytimePhone,
      line1,
      city,
      state,
      zip,
    } = currentState;
    const { form } = this.props;
    const entity = shapeUtility(form);
    const purePhone = daytimePhone ? daytimePhone.replace(/-|_/g, '') : '';
    entity.address = address ? address : entity.address;
    entity.email = businessEmail ? shapeEmail(businessEmail) : entity.email;
    entity.phone = purePhone ? purePhone : entity.phone;
    entity.line1 = line1 ? line1 : entity.line1;
    entity.city = city ? city : entity.city;
    entity.state = state ? state : entity.state;
    entity.zip = zip ? zip : entity.zip;
    return { ...form, entity };
  };

  handlerAddressChange = (index: number) => {
    let result = false;
    const { suggestions } = this.state;
    if (
      typeof index === 'number' &&
      suggestions &&
      index < suggestions.length
    ) {
      this.setState({
        address: suggestions[index].address,
        city: suggestions[index].city,
        line1: suggestions[index].line1,
        state: suggestions[index].state,
        zip: suggestions[index].zip,
      });
      this.inputValidation({
        ...this.state,
        ...{
          address: suggestions[index].address,
          city: suggestions[index].city,
          line1: suggestions[index].line1,
          state: suggestions[index].state,
          zip: suggestions[index].zip,
        },
      });
      result = true;
    }
    this.setState({
      showGoogleResult: false,
    });
    return result;
  };

  handleEmailChange = (event: any) => {
    let result = false;
    this.setState({ businessEmail: event.target.value });
    if (!event.target.value) {
      this.setState({
        emailError: '',
        valid: false,
      });
      return result;
    }
    if (validateEmail(event.target.value)) {
      this.setState({ emailError: '' });
      result = true;
    } else {
      this.setState({ emailError: '* Invalid email' });
    }
    this.inputValidation({
      ...this.state,
      ...{ businessEmail: event.target.value },
    });
    return result;
  };

  handlePhoneChange = ({
    value,
    formattedValue,
  }: {
    value: string;
    formattedValue: string;
  }) => {
    let result = false;
    if (!value) {
      this.setState({ daytimePhone: value, phoneError: '', valid: false });
    } else if (value?.length >= 10) {
      this.setState({
        daytimePhone: formattedValue,
        phoneError: '',
        valid: true,
      });
      result = true;
    } else {
      this.setState({
        daytimePhone: formattedValue,
        phoneError: '* Invalid phone',
        valid: false,
      });
    }
    this.inputValidation(this.state);
    return result;
  };

  handleZipChange = (event: any) => {
    let result = false;
    this.setState({ zip: event.target.value });
    if (!event.target.value) {
      this.setState({
        valid: false,
        zipError: '',
      });
      return result;
    }
    if (event.target.value && event.target.value.length >= 5) {
      this.setState({ zip: event.target.value.substring(0, 5) });
      this.setState({ zipError: '' });
      result = true;
    } else {
      this.setState({ zipError: '* Invalid zip code' });
    }
    this.inputValidation({ ...this.state, ...{ zip: event.target.value } });
    return result;
  };

  handleLine1Change = (event: any) => {
    this.setState({ line1: event.target.value });
    if (!validateLine1(event.target.value)) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation({ ...this.state, ...{ line1: event.target.value } });
    return true;
  };

  handleCityChange = (event: any) => {
    this.setState({ city: event.target.value });
    if (!validateCity(event.target.value)) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation(this.state);
    return true;
  };

  handleBizStateChange = (event: any) => {
    this.setState({ state: event.target.value });
    if (!event.target.value || event.target.value.length >= 3) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation({ ...this.state, state: event.target.value });
    return true;
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    this.inputValidation(this.state);
    if (this.state.valid) {
      const updateForm: ISignUpForm = this.updateForm(this.state);
      sendAbandon(updateForm, this.props.flags, this.props.preloadedFlags);
      this.props.nextPage(updateForm);
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  handleGooglePlaceChange = async (event: any) => {
    const input = event.target.value;
    const response = await Axios.get(`/api/google/places/${input}`);
    this.setState({
      address: event.target.value,
      city: '',
      line1: '',
      state: '',
      valid: false,
      zip: '',
    });
    if (response.data && response.data.length > 0) {
      const suggestions = response.data.map((result: any) => {
        return this.shapePlaceSuggestion(result ? result.result : '');
      });
      this.setState({ suggestions });
      return true;
    } else {
      this.setState({ useGooglePlaces: false });
      return false;
    }
  };

  inputValidation = async (currentstate: ILegalBusinessInfoState) => {
    const {
      businessEmail,
      daytimePhone,
      line1,
      city,
      state,
      zip,
    } = currentstate;
    let result = false;
    if (!businessEmail || !daytimePhone || !line1 || !city) {
      this.setState({ valid: false });
      return result;
    }

    const purePhone = daytimePhone ? daytimePhone.replace(/-|_/g, '') : '';
    if (
      validateEmail(businessEmail) &&
      validateState(state) &&
      purePhone &&
      purePhone.length >= 10 &&
      purePhone.length <= 15 &&
      validateLine1(line1) &&
      validateCity(city) &&
      zip.length === 5
    ) {
      this.setState({ valid: true });
      result = true;
    } else {
      this.setState({ valid: false });
    }
    return result;
  };

  shapePlaceSuggestion(result: any) {
    const suggestion = {} as IGooglePlacesDetailResponse;
    if (result.formatted_address) {
      suggestion.address = result.formatted_address;
      const parseAddr = suggestion.address.split(', ');
      if (parseAddr && parseAddr.length >= 4) {
        suggestion.line1 = parseAddr[0];
        suggestion.city = parseAddr[1];
        const stateAndZip = parseAddr[2].split(' ');
        if (stateAndZip && stateAndZip.length === 2) {
          suggestion.state = stateAndZip[0];
          suggestion.zip = stateAndZip[1];
        }
      }
    }
    this.setState({
      showGoogleResult: true,
    });
    return suggestion;
  }

  render() {
    const { emailError, phoneError, zipError } = this.state;
    const stateOptions = statesList.map((stateObj: any, index: number) => {
      return (
        <option key={index} value={stateObj.Abbrev}>
          {stateObj.State}
        </option>
      );
    });
    const suggestionsListComponent = this.state.suggestions.map(
      (suggestion: IGooglePlacesDetailResponse, index: number) => {
        return (
          <li key={index} onClick={this.handlerAddressChange.bind(this, index)}>
            &nbsp; <i className='fas fa-map-marker-alt' /> &nbsp; &nbsp;{' '}
            {suggestion.address}
          </li>
        );
      },
    );

    const googleInput = (
      <>
        <div className='input-group h-4'>
          <DebounceInput
            className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
            type='text'
            maxLength={100}
            minLength={1}
            debounceTimeout={500}
            placeholder='Business address'
            value={this.state.address}
            onChange={this.handleGooglePlaceChange}
            autoComplete='off'
          />
        </div>
        {!this.state.address && this.state.showRequired && (
          <div className={mainStyles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{this.state.requiredMsg}</h6>
          </div>
        )}
        <div
          className={
            this.state.showGoogleResult
              ? `input-group h-4`
              : `input-group h-4 ${mainStyles.disappear}`
          }
        >
          <ul
            className={
              'form-control input-lg text-left ' + ' ' + mainStyles.suggestions
            }
          >
            {suggestionsListComponent}
          </ul>
        </div>
        <div>
          <h4 className={`${mainStyles.msg} ${mainStyles.textSecondary}`}>
            Business address cannot be a PO Box.
          </h4>
        </div>
      </>
    );

    const manualInput = (
      <>
        <div className='input-group h-4'>
          <input
            className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
            type='text'
            maxLength={100}
            minLength={2}
            placeholder='Business street'
            value={this.state.line1}
            onChange={this.handleLine1Change}
          />
        </div>
        {!validateLine1(this.state.line1) && this.state.showRequired && (
          <div className={mainStyles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{this.state.requiredMsg}</h6>
          </div>
        )}
        <div>
          <h4 className={`${mainStyles.msg} ${mainStyles.textSecondary}`}>
            Business address cannot be a PO Box.
          </h4>
        </div>
        <div className='input-group h-4'>
          <input
            className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
            type='text'
            maxLength={100}
            minLength={2}
            placeholder='Business city'
            value={this.state.city}
            onChange={this.handleCityChange}
          />
        </div>
        {!validateCity(this.state.city) && this.state.showRequired && (
          <div className={mainStyles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{this.state.requiredMsg}</h6>
          </div>
        )}
        <div className='input-group h-4'>
          <select
            className={`form-control input-lg pl-3 mt-3 ${mainStyles.inputfield} ${styles.state}`}
            value={this.state.state}
            onChange={this.handleBizStateChange}
          >
            {stateOptions}
          </select>
          <input
            className={`form-control input-lg pl-4 mt-3 text-left bfh-phone ${mainStyles.inputfield}`}
            type='number'
            max={99999}
            minLength={2}
            placeholder='Zip'
            value={this.state.zip}
            onChange={this.handleZipChange}
          />
        </div>
        <div className={`text-right mr-5 pt-2 pr-5 ${mainStyles.msg} `}>
          <h6 className='text-danger mb-0'>{zipError}</h6>
        </div>
        <div className={`row align-items-start ${mainStyles.layout}`}>
          {(!this.state.state || this.state.state === '0' || !this.state.zip) &&
            this.state.showRequired && (
              <div
                className={`col text-danger pl-0 text-left ${mainStyles.layoutMsg2}`}
              >
                <h6 className='text-danger ml-1 mb-0 text-left'>
                  {this.state.requiredMsg}
                </h6>
              </div>
            )}
        </div>
      </>
    );

    return (
      <>
        <TitleSubtitle title={`Tell us about your business.`} subtitle='' />
        <form>
          <div className='input-group h-4'>
            <input
              className={`input form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              placeholder='Business email'
              minLength={2}
              maxLength={100}
              value={this.state.businessEmail}
              onChange={this.handleEmailChange}
            />
          </div>
          {!this.state.businessEmail && this.state.showRequired && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>
                {this.state.requiredMsg}
              </h6>
            </div>
          )}
          <div>
            <h6 className={`text-danger ml-1 ${mainStyles.msg}`}>
              {emailError}
            </h6>
          </div>
          <div className='input-group h-4'>
            <PhoneInput
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left bfh-phone ${mainStyles.inputfield}`}
              id={'dayTimePhone'}
              onChange={this.handlePhoneChange}
              placeholder='Daytime phone'
              required={true}
              value={this.state.daytimePhone}
            />
          </div>
          {!this.state.daytimePhone && this.state.showRequired && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>
                {this.state.requiredMsg}
              </h6>
            </div>
          )}
          <div>
            <h6 className={`text-danger ml-1 ${mainStyles.msg} mb-0`}>
              {phoneError}
            </h6>
          </div>
          {this.state.useGooglePlaces ? googleInput : manualInput}
          <div className={mainStyles.buttonContainer}>
            <div className={mainStyles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={mainStyles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={3} incomplete={4} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(LegalBusinessInfo));
