import { withLDConsumer } from 'ldclient-react';
import React from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IMember, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import {
  formatPhone,
  sendAbandon,
  shapeEmail,
  shapeUtility,
} from '../../../utility/helper';
import { validateEmail, validateName } from '../../../utility/validation';
import { Button } from '../../ui/Button';
import PhoneInput from '../Inputs/Phone';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
  currentAdditionalOwner: IMember | null;
  currentAdditionalOwnerIndex: number;
  totalCountOwners: number;
}

interface IAdditionalOwnerContactState {
  email: string;
  emailError: string;
  phone: string;
  phoneError: string;
  first: string;
  last: string;
  valid: boolean;
  showRequired: boolean;
  requiredMsg: string;
}

export class AdditionalOwnerContact extends React.Component<
  IMerchantSignupProps,
  IAdditionalOwnerContactState
> {
  state: IAdditionalOwnerContactState = {
    email: '',
    emailError: '',
    first: '',
    last: '',
    phone: '',
    phoneError: '',
    requiredMsg: '*Required',
    showRequired: false,
    valid: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (
      this.props.currentAdditionalOwnerIndex >= 1 &&
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.merchant &&
      this.props.form.entity.merchant.members &&
      this.props.form.entity.merchant.members.length >
        this.props.currentAdditionalOwnerIndex
    ) {
      const {
        email,
        phone,
        first,
        last,
      } = this.props.form.entity.merchant.members[
        this.props.currentAdditionalOwnerIndex
      ];
      const fPhone = formatPhone(phone);
      this.setState({
        email,
        first,
        last,
        phone: fPhone,
      });
      if (email && phone && first && last) {
        this.setState({ valid: true });
      }
    }
    if (
      this.props.currentAdditionalOwnerIndex < this.props.totalCountOwners &&
      this.props.currentAdditionalOwner
    ) {
      const { email, phone, first, last } = this.props.currentAdditionalOwner;
      const fPhone = formatPhone(phone);
      this.setState({
        email,
        first,
        last,
        phone: fPhone,
      });
      if (email && phone && first && last) {
        this.setState({ valid: true });
      }
    }
  }

  handleEmailChange = (event: any) => {
    let result = false;
    this.setState({ email: event.target.value });
    if (!event.target.value) {
      this.setState({
        emailError: '',
        valid: false,
      });
      return result;
    }
    if (validateEmail(event.target.value)) {
      this.setState({ emailError: '' });
      result = true;
    } else {
      this.setState({ emailError: '* Invalid email' });
    }
    this.inputValidation({ ...this.state, email: event.target.value });
    return result;
  };

  handlePhoneChange = ({
    value,
    formattedValue,
  }: {
    value: string;
    formattedValue: string;
  }) => {
    let result = false;
    if (!value) {
      this.setState({ phone: value, phoneError: '', valid: false });
    } else if (value?.length >= 10) {
      this.setState({ phone: formattedValue, phoneError: '', valid: true });
      result = true;
    } else {
      this.setState({
        phone: formattedValue,
        phoneError: '* Invalid phone',
        valid: false,
      });
    }
    this.inputValidation(this.state);
    return result;
  };

  handleFNameChange = (event: any) => {
    this.setState({ first: event.target.value });
    if (!validateName(event.target.value)) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation({ ...this.state, first: event.target.value });
    return true;
  };

  handleLNameChange = (event: any) => {
    this.setState({ last: event.target.value });
    if (!validateName(event.target.value)) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation({ ...this.state, last: event.target.value });
    return true;
  };

  updateForm = (state: IAdditionalOwnerContactState) => {
    const { email, first, last, phone } = state;
    const { form } = this.props;
    const entity = shapeUtility(form);
    const purePhone = phone ? phone.replace(/-|_/g, '') : '';
    const newMember: IMember = {
      address: '',
      city: '',
      dob: '',
      email: shapeEmail(email),
      first,
      last,
      line1: '',
      ownership: 0,
      phone: purePhone,
      primary: false,
      ssn: '',
      state: '',
      title: '',
      zip: '',
    };
    if (
      entity.merchant &&
      entity.merchant.members &&
      entity.merchant.members.length <
        this.props.currentAdditionalOwnerIndex + 1
    ) {
      entity.merchant.members.push(newMember);
    } else {
      entity.merchant.members[this.props.currentAdditionalOwnerIndex] = {
        ...entity.merchant.members[this.props.currentAdditionalOwnerIndex],
        ...{
          email: shapeEmail(email),
          first,
          last,
          phone: purePhone,
        },
      };
    }
    return { ...form, entity };
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    this.inputValidation(this.state);
    if (this.state.valid) {
      const updateForm: ISignUpForm = this.updateForm(this.state);
      sendAbandon(updateForm, this.props.flags, this.props.preloadedFlags);
      this.props.nextPage(updateForm);
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  inputValidation = async (state: IAdditionalOwnerContactState) => {
    let result = false;
    const { email, phone, first, last } = state;
    if (!email || !phone || !first || !last) {
      this.setState({ valid: false });
      return result;
    }
    const purePhone = phone ? phone.replace(/-|_/g, '') : '';
    if (
      validateEmail(email) &&
      purePhone &&
      purePhone.length >= 10 &&
      purePhone.length <= 15 &&
      validateName(first) &&
      validateName(last)
    ) {
      this.setState({ valid: true });
      result = true;
    } else {
      this.setState({ valid: false });
    }
    return result;
  };

  render() {
    const { emailError, phoneError, requiredMsg } = this.state;
    return (
      <>
        <TitleSubtitle title='Tell us about the additional business owner.' />
        <form>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${styles.inputfield}`}
              type='text'
              maxLength={100}
              minLength={2}
              placeholder='First name'
              value={this.state.first}
              onChange={this.handleFNameChange}
            />
          </div>
          {!validateName(this.state.first) && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${styles.inputfield}`}
              type='text'
              maxLength={100}
              minLength={2}
              placeholder='Last name'
              value={this.state.last}
              onChange={this.handleLNameChange}
            />
          </div>
          {!validateName(this.state.last) && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <input
              className={`input form-control input-lg mt-3 mb-2 pl-4 text-left ${styles.inputfield}`}
              type='text'
              placeholder='Contact email'
              minLength={2}
              maxLength={100}
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {emailError}
            </h6>
          </div>
          {!this.state.email && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <PhoneInput
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left bfh-phone ${styles.inputfield}`}
              id={'additionalContactPhone'}
              onChange={this.handlePhoneChange}
              placeholder='Contact phone'
              required={true}
              value={this.state.phone}
            />
          </div>
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {phoneError}
            </h6>
          </div>
          {!this.state.phone &&
            !this.state.phoneError &&
            this.state.showRequired && (
              <div className={styles.msg}>
                <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
              </div>
            )}
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <br />
          <ProgressDots complete={4} incomplete={3} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(AdditionalOwnerContact));
