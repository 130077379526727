import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import * as mainStyles from '../styles.css';

interface IOfferCode {
  errors: any;
  offerCode: string;
  handleChange: any;
  next: any;
  submitted: boolean;
}

export const OfferCode: React.FunctionComponent<IOfferCode> = props => {
  const { errors, submitted } = props;
  return (
    <div>
      <h1
        className={`mt-4 ${mainStyles.textPrimary} ${mainStyles.regularWeight} text-center`}
      >
        Let's Talk
      </h1>
      <h3
        className={`${mainStyles.textFont} ${mainStyles.textSecondary} mx-5 text-center`}
      >
        Sign up for CurrencyPay and experience the benefit of the most robust
        transaction enablement platform available.
      </h3>
      <br />
      <form onSubmit={props.next}>
        <div className='input-group h-4'>
          <DebounceInput
            className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
            type='text'
            maxLength={14}
            placeholder='14 Digit Offer Code'
            value={props.offerCode}
            name='offerCode'
            onChange={props.handleChange}
          />
        </div>
        <div className={mainStyles.msg}>
          <h6 className='text-danger ml-1 mb-5'>
            {errors && errors.offerCode && submitted ? errors.offerCode : ''}
          </h6>
        </div>
        <h3
          className={`${mainStyles.textFont} ${mainStyles.textSecondary} mx-5 text-center`}
        >
          Code can be found on the front of the offer card.
        </h3>
        <button
          className={`btn btn-primary btn-lg btn-block mx-auto ${mainStyles.nextBtn}`}
          type='submit'
        >
          Next
        </button>
      </form>
    </div>
  );
};
