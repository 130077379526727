import axios from 'axios';
import redux from 'redux';

import { ISignUpForm } from '../interfaces';
import * as types from './action-types';

export function beginFetchForm() {
  return {
    type: types.FETCH_FORM,
  };
}

export function fetchFormSuccess(data: ISignUpForm) {
  return {
    form: data,
    type: types.FETCH_FORM_SUCCESS,
  };
}

export function fetchFormError(error: Error) {
  return {
    error,
    type: types.FETCH_FORM_ERROR,
  };
}

export function fetchForm(form: ISignUpForm) {
  return async (dispatch: redux.Dispatch) => {
    dispatch(beginFetchForm());
    if (form) {
      dispatch(fetchFormSuccess(form));
    }
  };
}

export function updateForm(fields: any) {
  return {
    fields,
    type: types.UPDATE_FORM,
  };
}
