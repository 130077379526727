import { withLDConsumer } from 'ldclient-react';
import React from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IEntity, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import {
  formatPhone,
  sendAbandon,
  shapeUtility,
} from '../../../utility/helper';
import { Button } from '../../ui/Button';
import PhoneInput from '../Inputs/Phone';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IBusinessStatementState {
  businessDBA: string;
  businessPhone: string;
  phoneError: string;
  valid: boolean;
}

export class BusinessStatement extends React.Component<
  IMerchantSignupProps,
  IBusinessStatementState
> {
  state: IBusinessStatementState = {
    businessDBA: '',
    businessPhone: '',
    phoneError: '',
    valid: true,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (this.props.form && this.props.form.entity) {
      const { merchant, customerPhone } = this.props.form.entity;
      const fPhone = formatPhone(customerPhone);
      this.setState({
        businessDBA: merchant?.dba ? merchant.dba : '',
        businessPhone: fPhone,
      });
    }
  }

  handlePhoneChange = ({
    value,
    formattedValue,
  }: {
    value: string;
    formattedValue: string;
  }) => {
    let result = false;
    if (!value) {
      this.setState({ businessPhone: value, phoneError: '', valid: true });
    } else if (value?.length >= 10) {
      this.setState({
        businessPhone: formattedValue,
        phoneError: '',
        valid: true,
      });
      result = true;
    } else {
      this.setState({
        businessPhone: formattedValue,
        phoneError: '* Invalid phone',
        valid: false,
      });
    }
    return result;
  };

  handleDBAChange = (event: any) => {
    this.setState({ businessDBA: event.target.value });
    return !!event.target.value;
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    if (this.state.valid) {
      const { form } = this.props;
      const entity: IEntity = shapeUtility(form);
      // optional field - empty str acceptable
      const purePhone = this.state.businessPhone
        ? this.state.businessPhone.replace(/-|_/g, '')
        : '';
      entity.customerPhone = purePhone;
      entity.merchant.dba = this.state.businessDBA?.replace(
        /[^0-9a-zA-Z ,_\-'&]/g,
        '',
      );
      sendAbandon(
        { ...form, entity },
        this.props.flags,
        this.props.preloadedFlags,
      );
      this.props.nextPage({ ...form, entity });
      return true;
    }
    return false;
  };

  render() {
    const { phoneError } = this.state;
    return (
      <>
        <TitleSubtitle title='Tell us about your business.' subtitle='' />
        <form>
          <div className='input-group mb-3 h-4'>
            <input
              className={`form-control input-lg pl-4 my-3 text-left ${styles.inputfield}`}
              type='text'
              maxLength={50}
              minLength={2}
              placeholder='DBA/Legal business name (optional)'
              value={this.state.businessDBA}
              onChange={this.handleDBAChange}
            />
          </div>
          <div className='input-group mb-3 h-4'>
            <PhoneInput
              className={`form-control input-lg pl-4 my-3 text-left bfh-phone ${styles.inputfield}`}
              id={'businessPhone'}
              onChange={this.handlePhoneChange}
              placeholder='Customer service phone (optional)'
              required={false}
              value={this.state.businessPhone}
            />
          </div>
          <div className={styles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{phoneError}</h6>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={3} incomplete={4} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(BusinessStatement));
