import React from 'react';
import { ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import { sendAbandon, shapeUtility } from '../../../utility/helper';
import { Button } from '../../ui/Button';
import { Radio } from '../../ui/Radio';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  nextPage: any;
  flags?: any;
  form: ISignUpForm;
  preloadedFlags: any;
  previousPage: any;
}

interface ITerminalState {
  sendFreeTerminal: string | null;
}

class Terminal extends React.Component<IMerchantSignupProps, ITerminalState> {
  state: ITerminalState = { sendFreeTerminal: null };
  handleChange = (e: any) => {
    this.setState({
      sendFreeTerminal: e.target.value,
    });
    return true;
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { form } = this.props;
    const entity = shapeUtility(form);
    entity.sendFreeTerminal =
      this.state.sendFreeTerminal === 'true' ? true : false;
    sendAbandon(
      { ...form, entity },
      this.props.flags,
      this.props.preloadedFlags,
    );
    this.props.nextPage({ ...form, entity });
    return entity.sendFreeTerminal;
  };

  render() {
    const options = [
      {
        label: 'Yes',
        name: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        name: 'No',
        value: 'false',
      },
    ];
    return (
      <div>
        <TitleSubtitle
          {...this.props}
          title='Will you need a payment terminal?'
          subtitle=''
        />
        <form>
          <Radio
            {...this.props}
            options={options}
            onClick={this.handleChange}
            isChecked={this.state.sendFreeTerminal}
          />
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={5} incomplete={2} {...this.props} />
        </form>
      </div>
    );
  }
}

export default Terminal;
