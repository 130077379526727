export const FETCH_FORM = 'FETCH_FORM';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const FETCH_FORM_ERROR = 'FETCH_FORM_ERROR';
export const UPDATE_FORM = 'UPDATE_FORM';

export const FETCH_FLAGS = 'FETCH_FLAGS';
export const FETCH_FLAGS_SUCCESS = 'FETCH_FLAGS_SUCCESS';
export const FETCH_FLAGS_ERROR = 'FETCH_FLAGS_ERROR';

export const FETCH_SUBMIT_STATUS = 'FETCH_SUBMIT_STATUS';
export const FETCH_SUBMIT_STATUS_SUCCESS = 'FETCH_SUBMIT_STATUS_SUCCESS';
export const FETCH_SUBMIT_STATUS_ERROR = 'FETCH_SUBMIT_STATUS_ERROR';

export const SET_PROCESS_ENV = 'SET_ENV';
