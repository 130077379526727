import * as types from '../actions/action-types';

const initialState = {
  error: null,
  status: '',
};

export default function source(state = initialState, action: any) {
  const { status, error } = action;
  switch (action.type) {
    case types.FETCH_SUBMIT_STATUS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case types.FETCH_SUBMIT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status,
      };
    case types.FETCH_SUBMIT_STATUS_ERROR:
      return {
        ...state,
        error,
        loading: false,
        status: '',
      };
    default:
      return state;
  }
}
