import React, { FunctionComponent } from 'react';
import NumberFormat from 'react-number-format';

export interface ISsnInput {
  className: string;
  id: string;
  onChange: (value: string) => void;
  placeholder: string;
  required: boolean;
  value: string;
}

const SsnInput: FunctionComponent<ISsnInput> = ({
  className,
  id,
  onChange,
  placeholder,
  required,
  value,
}) => (
  <NumberFormat
    className={className}
    allowNegative={false}
    allowEmptyFormatting={true}
    format={!value ? undefined : '###-##-####'}
    id={id}
    isNumericString={true}
    mask={value ? '_' : undefined}
    placeholder={placeholder}
    onValueChange={({ value }) => onChange(value)}
    required={required}
    value={value}
  />
);

export default SsnInput;
