import React from 'react';
import { CPAY_RESPONSE_STATUS } from '../../../utility/constants';
import { TitleSubtitle } from '../TitleSubtitle';

interface ISuccessProps {
  status?: string;
}

const Success = (props: ISuccessProps) => {
  const shouldDisplayDupeMsg = props.status === CPAY_RESPONSE_STATUS.DUPLICATE;
  const dupeMsg = () => (
    <p>
      Please contact us at <a href='tel:+18447247376'>(844) 724-7376</a> with
      any questions or for further assistance.
    </p>
  );
  return (
    <>
      <TitleSubtitle
        title={
          shouldDisplayDupeMsg
            ? 'The specified account already exists.'
            : 'Thank you for your interest in payment processing.'
        }
        subtitle={
          shouldDisplayDupeMsg
            ? dupeMsg()
            : 'We will contact you shortly with the next step.'
        }
        subtitle2={ '' }
      />
    </>
  );
};

export default Success;
