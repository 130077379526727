// these variables are attached to the store state as state.processEnv
// so they can be used both on the server and the client

export const createProcessEnv = (env: any) => {
  return {
    GOOGLE_PLACES_API_KEY: env.GOOGLE_PLACES_API_KEY || 'NA',
    INSTRUMENTATION_KEY: env.APPINSIGHTS_INSTRUMENTATIONKEY || 'NA',
    LAUNCH_DARKLY: {
      clientSideKey: env.LD_CLIENT_KEY || '5a53dbb2324bce2167e30aab',
      serverSideKey: env.LD_SDK_KEY || 'NA',
      user: { key: 'companyWide' },
    },
    NODE_ENV: env.NODE_ENV || 'development',
    currencypayApi:
      env.CURRENCYPAY_API || `https://currencypayapi-staging.azurewebsites.net`,
  };
};
