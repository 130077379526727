import { withLDConsumer } from 'ldclient-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IEntity, IMember, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import { sendAbandon, shapeUtility } from '../../../utility/helper';
import { Button } from '../../ui/Button';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IPrimaryOwnershipState {
  title: string;
  titleError: string;
  ownership: string;
  ownershipError: string;
  valid: boolean;
  showRequired: boolean;
  requiredMsg: string;
}

export class PrimaryOwnership extends Component<
  IMerchantSignupProps,
  IPrimaryOwnershipState
> {
  state: IPrimaryOwnershipState = {
    ownership: '',
    ownershipError: '',
    requiredMsg: '*Required',
    showRequired: false,
    title: '',
    titleError: '',
    valid: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    this.inputValidation({ ...this.state });
    if (
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.merchant &&
      this.props.form.entity.merchant.members &&
      this.props.form.entity.merchant.members[0]
    ) {
      const { ownership, title } = this.props.form.entity.merchant.members[0];
      this.setState({
        ownership: ownership.toString(),
        title,
      });
      this.inputValidation({
        ...this.state,
        ownership: ownership.toString(),
        title,
      });
    }
  }

  updateForm = (state: IPrimaryOwnershipState) => {
    const { ownership, title } = state;
    const { form } = this.props;
    const entity: IEntity = shapeUtility(form);
    if (
      entity.merchant &&
      entity.merchant.members &&
      entity.merchant.members[0]
    ) {
      entity.merchant.members[0].primary = true;
      entity.merchant.members[0].ownership = parseFloat(ownership);
      entity.merchant.members[0].title = title;
    } else {
      entity.merchant.members = [] as IMember[];
      entity.merchant.members.push({
        address: '',
        city: '',
        dob: '',
        email: '',
        first: '',
        last: '',
        line1: '',
        ownership: parseFloat(ownership),
        phone: '',
        primary: true,
        ssn: '',
        state: '',
        title,
        zip: '',
      });
    }
    return { ...form, entity };
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    if (this.state.valid) {
      const updateForm: ISignUpForm = this.updateForm(this.state);
      sendAbandon(updateForm, this.props.flags, this.props.preloadedFlags);
      this.props.nextPage(updateForm);
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  handleOwnershipChange = (event: any) => {
    this.setState({ ownership: event.target.value });
    const ownershipNum = parseFloat(event.target.value);
    if (ownershipNum <= 0 || ownershipNum > 100) {
      this.setState({ ownershipError: '* Invalid ownership' });
    } else {
      this.setState({ ownershipError: '' });
    }
    return this.inputValidation({
      ...this.state,
      ownership: event.target.value,
    });
  };

  handleTitleChange = (event: any) => {
    if (event.target.value.length < 2) {
      this.setState({ title: event.target.value, titleError: 'Invalid Title' });
    } else {
      this.setState({ title: event.target.value, titleError: '' });
    }
    return this.inputValidation({ ...this.state, title: event.target.value });
  };

  inputValidation = async (state: IPrimaryOwnershipState) => {
    const { ownership, title } = state;
    const ownershipNum = parseFloat(ownership);
    if (
      typeof ownershipNum === 'number' &&
      ownershipNum > 0 &&
      ownershipNum <= 100 &&
      title &&
      title.length >= 2 &&
      title.length <= 100
    ) {
      this.setState({ valid: true });
      return true;
    } else {
      this.setState({ valid: false });
      return false;
    }
  };

  render() {
    const { ownershipError, requiredMsg, titleError } = this.state;
    return (
      <>
        <TitleSubtitle title='Tell us about your ownership of the business.' />
        <form>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left bfh-phone ${styles.inputfield}`}
              type='number'
              max={100}
              minLength={1}
              placeholder='% Ownership'
              value={this.state.ownership}
              onChange={this.handleOwnershipChange}
            />
          </div>
          {!this.state.ownership && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {ownershipError}
            </h6>
          </div>
          <div className='input-group h-4'>
            <input
              className={`input form-control input-lg pl-4 mt-3 mb-2 text-left ${styles.inputfield}`}
              type='text'
              placeholder='Your title'
              minLength={2}
              maxLength={100}
              value={this.state.title}
              onChange={this.handleTitleChange}
            />
          </div>
          {!this.state.title && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {titleError}
            </h6>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={4} incomplete={3} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(PrimaryOwnership));
