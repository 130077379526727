import Axios from 'axios';
import { withLDConsumer } from 'ldclient-react';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IGooglePlacesDetailResponse, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import {
  formatSsn,
  isValidDate,
  maxDaysInMonth,
  sendAbandon,
  shapeUtility,
  statesList,
} from '../../../utility/helper';
import {
  validateCity,
  validateLine1,
  validateState,
} from '../../../utility/validation';
import { Button } from '../../ui/Button';
import NumericInput from '../Inputs/Numeric';
import SsnInput from '../Inputs/SSN';
import { Norton } from '../Norton';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IPrimaryOwnershipPersonalState {
  address: string;
  month: string;
  day: string;
  year: string;
  line1: string;
  city: string;
  valid: boolean;
  sameAddr: boolean;
  showCheckbox: boolean;
  showMsg: boolean;
  ssn: string;
  ssnError: string;
  state: string;
  suggestions: IGooglePlacesDetailResponse[];
  useGooglePlaces: boolean;
  showGoogleResult: boolean;
  zip: string;
  zipError: string;
  dobError: string;
  showRequired: boolean;
  requiredMsg: string;
}

const SOLEPROPRIETOR = 'Sole Proprietor';

export class PrimaryOwnershipPersonal extends React.Component<
  IMerchantSignupProps,
  IPrimaryOwnershipPersonalState
> {
  state: IPrimaryOwnershipPersonalState = {
    address: '',
    city: '',
    day: '',
    dobError: '',
    line1: '',
    month: '',
    requiredMsg: '*Required',
    sameAddr: false,
    showCheckbox: true,
    showGoogleResult: false,
    showMsg: false,
    showRequired: false,
    ssn: '',
    ssnError: '',
    state: '',
    suggestions: [],
    useGooglePlaces: true,
    valid: false,
    year: '',
    zip: '',
    zipError: '',
  };

  componentDidMount() {
    let sameAddress = false;
    this.props.formActions.fetchForm();
    if (
      this.props.form &&
      this.props.form.entity &&
      this.props.form.entity.merchant &&
      this.props.form.entity.merchant.members &&
      this.props.form.entity.merchant.members[0]
    ) {
      const {
        address,
        city,
        dob,
        ssn,
        line1,
        state,
        zip,
        ownership,
      } = this.props.form.entity.merchant.members[0];
      this.setState({ showMsg: ownership && ownership < 75 ? true : false });
      if (address) {
        this.setState({
          address,
        });
      } else {
        this.setState({
          address: '',
          showGoogleResult: false,
        });
      }
      let currentState = {} as IPrimaryOwnershipPersonalState;
      if (dob) {
        const dobDate = new Date(dob);
        const dobDay = dobDate.getUTCDate().toString();
        const dobMonth = (dobDate.getUTCMonth() + 1).toString();
        const dobYear = dobDate.getUTCFullYear().toString();
        this.setState({
          day: dobDay,
          month: dobMonth,
          year: dobYear,
        });
        currentState = {
          ...this.state,
          day: dobDay,
          month: dobMonth,
          year: dobYear,
        };
      }
      if (
        city &&
        state &&
        line1 &&
        zip &&
        city === this.props.form.entity.city &&
        state === this.props.form.entity.state &&
        line1 === this.props.form.entity.line1 &&
        zip === this.props.form.entity.zip
      ) {
        this.setState({
          sameAddr: true,
        });
        sameAddress = true;
      } else if (this.props.form.entity.type === SOLEPROPRIETOR) {
        this.setState({
          sameAddr: false,
          showCheckbox: false,
        });
        sameAddress = false;
      }
      const fSSN = formatSsn(ssn);
      this.setState({
        address: address ? address : '',
        city: city ? city : '',
        line1: line1 ? line1 : '',
        ssn: fSSN,
        state: state ? state : '',
        zip: zip ? zip : '',
      });
      currentState = {
        ...currentState,
        address: address ? address : '',
        city: city ? city : '',
        line1: line1 ? line1 : '',
        sameAddr: sameAddress,
        ssn: fSSN,
        state: state ? state : '',
        zip: zip ? zip : '',
      };
      this.inputValidation(currentState);
    }
  }

  updateForm = (currentState: IPrimaryOwnershipPersonalState) => {
    const {
      address,
      year,
      day,
      month,
      ssn,
      line1,
      city,
      state,
      zip,
    } = currentState;
    const pureSSN = ssn ? ssn.replace(/-/g, '') : '';
    const monthIndex = parseInt(month, 10) - 1;
    const dayNum = parseInt(day, 10);
    const yearNum = parseInt(year, 10);
    const { form } = this.props;
    const entity = shapeUtility(form);
    if (
      entity &&
      entity.merchant &&
      entity.merchant.members &&
      entity.merchant.members[0]
    ) {
      entity.merchant.members[0].email = form.email;
      entity.merchant.members[0].phone = form.phone;
      entity.merchant.members[0].first = form.first;
      entity.merchant.members[0].last = form.last;
      entity.merchant.members[0].dob = new Date(
        yearNum,
        monthIndex,
        dayNum,
      ).toISOString();
      entity.merchant.members[0].state = state;
      entity.merchant.members[0].city = city;
      entity.merchant.members[0].zip = zip;
      entity.merchant.members[0].line1 = line1;
      entity.merchant.members[0].ssn = pureSSN;
      if (this.state.sameAddr && form.entity.type !== SOLEPROPRIETOR) {
        entity.merchant.members[0].state = entity.state;
        entity.merchant.members[0].city = entity.city;
        entity.merchant.members[0].zip = entity.zip;
        entity.merchant.members[0].line1 = entity.line1;
      } else if (form.entity.type === SOLEPROPRIETOR) {
        entity.state = entity.merchant.members[0].state;
        entity.city = entity.merchant.members[0].city;
        entity.line1 = entity.merchant.members[0].line1;
        entity.zip = entity.merchant.members[0].zip;
      }
      if (address) {
        entity.merchant.members[0].address = address;
      }
    }
    return { ...form, entity };
  };

  handlerAddressChange = (index: number) => {
    let result = false;
    const { suggestions } = this.state;
    if (
      typeof index === 'number' &&
      suggestions &&
      index < suggestions.length
    ) {
      this.setState({
        address: suggestions[index].address,
        city: suggestions[index].city,
        line1: suggestions[index].line1,
        state: suggestions[index].state,
        zip: suggestions[index].zip,
      });
      this.inputValidation({
        ...this.state,
        ...{
          address: suggestions[index].address,
          city: suggestions[index].city,
          line1: suggestions[index].line1,
          state: suggestions[index].state,
          zip: suggestions[index].zip,
        },
      });
      result = true;
    }
    this.setState({
      showGoogleResult: false,
    });
    return result;
  };

  handleGooglePlaceChange = async (event: any) => {
    const input = event.target.value;
    const response = await Axios.get(`/api/google/places/${input}`);
    this.setState({
      address: event.target.value,
      city: '',
      line1: '',
      state: '',
      valid: false,
      zip: '',
    });
    if (response.data && response.data.length > 0) {
      const suggestions = response.data.map((result: any) => {
        return this.shapePlaceSuggestion(result ? result.result : '');
      });
      this.setState({ suggestions });
      return true;
    } else {
      this.setState({ useGooglePlaces: false });
      return false;
    }
  };

  shapePlaceSuggestion(result: any) {
    const suggestion = {} as IGooglePlacesDetailResponse;
    if (result.formatted_address) {
      suggestion.address = result.formatted_address;
      const parseAddr = suggestion.address.split(', ');
      if (parseAddr && parseAddr.length >= 4) {
        suggestion.line1 = parseAddr[0];
        suggestion.city = parseAddr[1];
        const stateAndZip = parseAddr[2].split(' ');
        if (stateAndZip && stateAndZip.length === 2) {
          suggestion.state = stateAndZip[0];
          suggestion.zip = stateAndZip[1];
        }
      }
    }
    this.setState({
      showGoogleResult: true,
    });
    return suggestion;
  }

  handleDayChange = (input: string) => {
    let result = false;
    this.setState({ day: input });
    if (!input) {
      this.setState({
        dobError: '',
        valid: false,
      });
      return result;
    }
    const dayNum = parseInt(input, 10);
    const { month } = this.state;
    if (
      !dayNum ||
      typeof dayNum !== 'number' ||
      dayNum <= 0 ||
      dayNum > maxDaysInMonth[month] ||
      dayNum > 31
    ) {
      this.setState({ dobError: '* Invalid day' });
    } else {
      this.setState({ dobError: '' });
      result = true;
    }
    this.inputValidation({ ...this.state, day: input });
    return result;
  };

  handleMonthChange = (input: string) => {
    let result = false;
    this.setState({ month: input });
    if (!input) {
      this.setState({
        dobError: '',
        valid: false,
      });
      return result;
    }
    const monthNum = parseInt(input, 10);
    if (monthNum <= 0 || monthNum > 12) {
      this.setState({ dobError: '* Invalid month' });
    } else {
      this.setState({ dobError: '' });
      result = true;
    }
    this.inputValidation({ ...this.state, month: input });
    return result;
  };

  handleYearChange = (input: string) => {
    let result = false;
    this.setState({ year: input });
    if (!input) {
      this.setState({
        dobError: '',
        valid: false,
      });
      return result;
    }
    const yearNum = parseInt(input, 10);
    const currentYear = new Date().getUTCFullYear();
    if (yearNum > currentYear || yearNum < currentYear - 120) {
      this.setState({
        dobError: '* Invalid year',
        valid: false,
      });
    } else if (yearNum > currentYear - 18) {
      this.setState({
        dobError: '* Must be 18+ to apply',
        valid: false,
      });
    } else {
      this.setState({ dobError: '' });
      result = true;
    }
    this.inputValidation({ ...this.state, year: input });
    return result;
  };

  handleZipChange = (event: any) => {
    let result = false;
    this.setState({ zip: event.target.value });
    if (!event.target.value) {
      this.setState({
        valid: false,
        zipError: '',
      });
      return result;
    }
    if (event.target.value && event.target.value.length >= 5) {
      this.setState({ zip: event.target.value.substring(0, 5) });
      this.setState({ zipError: '' });
      result = true;
    } else {
      this.setState({ zipError: '* Invalid zip code' });
    }
    this.inputValidation({ ...this.state, zip: event.target.value });
    return result;
  };

  handleLine1Change = (event: any) => {
    this.setState({ line1: event.target.value });
    if (!validateLine1(event.target.value)) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation({ ...this.state, line1: event.target.value });
    return true;
  };

  handleCityChange = (event: any) => {
    this.setState({ city: event.target.value });
    if (!validateCity(event.target.value)) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation(this.state);
    return true;
  };

  handleStateChange = (event: any) => {
    this.setState({ state: event.target.value });
    if (!event.target.value || event.target.value.length >= 3) {
      this.setState({ valid: false });
      return false;
    }
    this.inputValidation({ ...this.state, state: event.target.value });
    return true;
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    this.inputValidation(this.state);
    if (this.state.valid) {
      const updateForm: ISignUpForm = this.updateForm(this.state);
      sendAbandon(updateForm, this.props.flags, this.props.preloadedFlags);
      this.props.nextPage(updateForm);
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  handleSSNChange = (value: string) => {
    let result = false;
    if (!value) {
      this.setState({
        ssn: '',
        ssnError: '',
        valid: false,
      });
      return result;
    }
    const fSSN = formatSsn(value);
    this.setState({ ssn: fSSN });
    if (value && value.length >= 9) {
      this.setState({ ssnError: '' });
      result = true;
    } else {
      this.setState({ ssnError: '* Invalid SSN' });
    }
    this.inputValidation({
      ...this.state,
      ssn: value,
    });
    return result;
  };

  handleSameAddr = (event: any) => {
    this.setState({ sameAddr: event.target.checked });
    this.inputValidation({ ...this.state, sameAddr: event.target.checked });
    return event.target.checked;
  };

  inputValidation = (currentState: IPrimaryOwnershipPersonalState) => {
    const { day, month, year, line1, city, state, ssn, zip } = currentState;
    const currentYear = new Date().getUTCFullYear();
    let result = false;
    if (!day || !month || !year) {
      this.setState({ valid: false });
      return result;
    }
    const numDay = parseInt(day, 10);
    const indexMonth = parseInt(month, 10) - 1;
    const numYear = parseInt(year, 10);

    if (
      !numDay ||
      numDay <= 0 ||
      numDay > maxDaysInMonth[month] ||
      numDay > 31
    ) {
      this.setState({ dobError: '* Invalid day', valid: false });
      return result;
    }

    if (indexMonth < 0 || indexMonth >= 12) {
      this.setState({ dobError: '* Invalid month', valid: false });
      return result;
    }

    if (numYear < currentYear - 120 || numYear > currentYear) {
      this.setState({ dobError: '* Invalid year', valid: false });
      return result;
    }

    const currentDate = new Date();
    const dob = new Date(numYear, indexMonth, numDay);
    const ageDifference = currentDate.getTime() - dob.getTime();
    const actualAge = Math.floor(ageDifference / 31536000000);
    if (actualAge < 18 || actualAge > 120) {
      this.setState({
        dobError: '* Must be 18+ to apply',
        valid: false,
      });
      return result;
    } else {
      this.setState({
        dobError: '',
      });
    }
    if (!isValidDate(numYear, indexMonth, numDay)) {
      this.setState({ dobError: 'Invalid Date', valid: false });
    } else {
      this.setState({ dobError: '' });
    }
    if (!this.state.sameAddr) {
      if (!line1 || !city || !state || !zip || !ssn) {
        this.setState({ valid: false });
        return result;
      } else if (
        !validateState(state) ||
        !validateLine1(line1) ||
        !validateCity(city) ||
        zip.length !== 5
      ) {
        this.setState({ valid: false });
        return result;
      }
    }
    const pureSSN = ssn ? ssn.replace(/-|_/g, '') : '';
    if (!pureSSN || pureSSN.length !== 9) {
      this.setState({ valid: false });
      return result;
    }
    this.setState({ valid: this.state.dobError === '' ? true : false });
    result = true;
    return result;
  };

  render() {
    const { dobError, ssnError, zipError, requiredMsg } = this.state;
    const stateOptions = statesList.map((stateObj: any) => {
      return (
        <option key={stateObj.Abbrev} value={stateObj.Abbrev}>
          {stateObj.State}
        </option>
      );
    });
    const suggestionsListComponent = this.state.suggestions.map(
      (suggestion: IGooglePlacesDetailResponse, index: number) => {
        return (
          <li
            key={`google_places_key_${index}`}
            onClick={this.handlerAddressChange.bind(this, index)}
          >
            &nbsp; <i className='fas fa-map-marker-alt' /> &nbsp; &nbsp;{' '}
            {suggestion.address}
          </li>
        );
      },
    );

    const googleInput = (
      <>
        <div className='input-group h-4'>
          <DebounceInput
            className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${
              styles.inputfield
            } ${this.state.sameAddr ? styles.disappear : ``}`}
            type='text'
            maxLength={100}
            minLength={1}
            debounceTimeout={500}
            placeholder='Your home address'
            value={this.state.address}
            onChange={this.handleGooglePlaceChange}
            autoComplete='off'
          />
        </div>
        {!this.state.sameAddr &&
          !this.state.address &&
          this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>
                {this.state.requiredMsg}
              </h6>
            </div>
          )}
        <div
          className={
            !this.state.sameAddr && this.state.showGoogleResult
              ? `input-group h-4`
              : `input-group h-4 ${styles.disappear}`
          }
        >
          <ul
            className={
              'form-control input-lg text-left ' + ' ' + styles.suggestions
            }
          >
            {suggestionsListComponent}
          </ul>
        </div>
      </>
    );

    const manualInput = (
      <>
        <div className='input-group h-4'>
          <input
            className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${
              styles.inputfield
            } ${this.state.sameAddr ? styles.disappear : ``}`}
            type='text'
            maxLength={100}
            minLength={2}
            placeholder='Your home street address'
            value={this.state.line1}
            onChange={this.handleLine1Change}
          />
        </div>
        {!this.state.sameAddr &&
          !validateLine1(this.state.line1) &&
          this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
        <div className='input-group h-4'>
          <input
            className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${
              styles.inputfield
            } ${this.state.sameAddr ? styles.disappear : ``}`}
            type='text'
            maxLength={100}
            minLength={2}
            placeholder='City'
            value={this.state.city}
            onChange={this.handleCityChange}
          />
        </div>
        {!this.state.sameAddr &&
          !validateCity(this.state.city) &&
          this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
        <div className='input-group h-4'>
          <select
            className={`form-control input-lg mt-3 ${styles.inputfield} ${
              styles.noMarginR
            } ${this.state.sameAddr ? styles.disappear : ``}`}
            value={this.state.state}
            onChange={this.handleStateChange}
          >
            {stateOptions}
          </select>
          <input
            className={`form-control input-lg mt-3 pl-4 text-left bfh-phone ${
              styles.inputfield
            } ${this.state.sameAddr ? styles.disappear : ``}`}
            type='number'
            max={99999}
            minLength={2}
            placeholder='Zip'
            value={this.state.zip}
            onChange={this.handleZipChange}
          />
        </div>
        <div
          className={`text-right mr-5 pt-2 pr-5 ${styles.msg} ${
            this.state.sameAddr ? styles.disappear : ``
          }`}
        >
          <h6 className={`text-danger ${styles.msg}`}>{zipError}</h6>
        </div>
        <div className={`row align-items-start ${styles.layout}`}>
          {!this.state.sameAddr &&
            (!this.state.state ||
              this.state.state === '0' ||
              !this.state.zip) &&
            this.state.showRequired && (
              <div
                className={`col text-danger pl-0 text-left ${styles.layoutMsg2}`}
              >
                <h6 className='text-danger ml-1 mb-0 text-left'>
                  {this.state.requiredMsg}
                </h6>
              </div>
            )}
        </div>
      </>
    );

    return (
      <>
        <TitleSubtitle title='Tell us about yourself.' />
        <form>
          <div className={`input-group h-4 ${styles.line1}`}>
            <h5 className={`${styles.textSecondary} ${styles.dob}`}>
              Date of birth
            </h5>
            <NumericInput
              className={`form-control input-lg mt-3 mb-2 mx-2 text-center bfh-phone ${styles.inputfield} ${styles.sml}`}
              minLength={1}
              maxLength={2}
              placeholder='MM'
              value={this.state.month}
              onChange={this.handleMonthChange}
            />
            <NumericInput
              className={`form-control input-lg mt-3 mb-2 mx-2 text-center bfh-phone ${styles.inputfield} ${styles.sml}`}
              minLength={1}
              maxLength={2}
              placeholder='DD'
              value={this.state.day}
              onChange={this.handleDayChange}
            />
            <NumericInput
              className={`form-control input-lg mt-3 mb-2 mx-2 text-center bfh-phone ${styles.inputfield}`}
              minLength={1}
              maxLength={4}
              placeholder='YYYY'
              value={this.state.year}
              onChange={this.handleYearChange}
            />
          </div>
          {(!this.state.month || !this.state.day || !this.state.year) &&
            this.state.showRequired && (
              <div className={styles.msg}>
                <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
              </div>
            )}
          <div className={`${styles.msg}`}>
            <h6 className='text-danger ml-5 mb-0'>{dobError}</h6>
          </div>
          <div className='input-group h-4'>
            <SsnInput
              className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${styles.inputfield}`}
              id={'primarySsn'}
              onChange={this.handleSSNChange}
              placeholder='SSN'
              required={true}
              value={this.state.ssn.replace(/-|_/g, '')}
            />
          </div>
          <div className='ssnDisclaimer'>
            <h4 className={`${styles.msg} ${styles.textSecondary}`}>
              Providing your SSN is required for identification purposes only
              and will not affect your credit score.
            </h4>
          </div>
          <div className={`${styles.msg}`}>
            <h6 className='text-danger ml-1 mb-0'>{ssnError}</h6>
          </div>
          {!this.state.ssn && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          {this.state.useGooglePlaces ? googleInput : manualInput}
          <div
            className={`input-group ml-1 my-3 h-4 ${styles.msg} ${
              this.state.showCheckbox ? `` : styles.disappear
            }`}
          >
            <label>
              <input
                type='checkbox'
                checked={this.state.sameAddr}
                onChange={this.handleSameAddr}
              />
              <h4
                className={`pl-3 ${styles.inlineBlock} ${styles.textSecondary}`}
              >
                Same as business address
              </h4>
            </label>
          </div>
          <div className={`mt-5`}>
            <h4
              className={`${styles.msg} ${styles.textSecondary} ${
                this.state.showMsg ? `` : styles.disappear
              } text-left`}
            >
              Please provide additional owner information on the next step.
            </h4>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={4} incomplete={3} {...this.props} />
          <Norton />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(PrimaryOwnershipPersonal));
