import React from 'react';
import { ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import {
  formatToPayrix,
  getEastCoastDateTime,
  sendAbandon,
  shapeUtility,
} from '../../../utility/helper';
import { Button } from '../../ui/Button';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';
const TERMS_CONDITIONS_DATE = '201907231658';

interface IMerchantSignupProps {
  flags?: any;
  form: ISignUpForm;
  nextPage: any;
  preloadedFlags: any;
  previousPage: any;
}

interface ITermsState {
  tcAcceptDate: string;
  tcDate: string;
  tcVersion: string;
}

class Terms extends React.Component<IMerchantSignupProps, ITermsState> {
  // tcAcceptDate is the datetime when the user clicks accept, tcDate is the date when the current version of the terms was published (I'm using the date when ticket CURPAY-1636 was created for v1.0)
  state: ITermsState = {
    tcAcceptDate: '',
    tcDate: '',
    tcVersion: '1.3', // NOTE - this needs to be updated manually whenever we change the terms & conditions
  };
  handleSubmit = async (e: any) => {
    e.preventDefault();
    const { form } = this.props;
    const entity = shapeUtility(form);
    const today = getEastCoastDateTime(new Date());

    await this.setState({
      tcAcceptDate: formatToPayrix(today),
      tcDate: TERMS_CONDITIONS_DATE,
    });

    entity.tcVersion = this.state.tcVersion;
    entity.tcDate = this.state.tcDate;
    entity.tcAcceptDate = this.state.tcAcceptDate;

    sendAbandon(
      { ...form, entity },
      this.props.flags,
      this.props.preloadedFlags,
    );

    this.props.nextPage({ ...form, entity });
    return true;
  };
  render() {
    return (
      <div>
        <TitleSubtitle title='Terms and conditions' subtitle={<br />} />
        <form>
          <h3 className={`container ${styles.textSecondary}`}>
            Please read and accept our T&amp;C{' '}
            <a href='/merchant/terms' target='_blank'>
              here
            </a>
            .
          </h3>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button
                onClick={this.handleSubmit}
                title='Agree'
                type='primary'
              />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={6} incomplete={1} {...this.props} />
        </form>
      </div>
    );
  }
}

export default Terms;
