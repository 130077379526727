import { AccountType } from '../interfaces';

export const SOLE_PROPRIETOR = 'Sole Proprietor';
export const NON_PROFIT_ORG = 'Non-Profit Organization';
export const CURRENCY_PAY_SIGNUP = 'currencypaysignup';

export const ACCOUNT_TYPES = {
  CORE: 'Core',
  LITE: 'Lite',
  PRO: 'Pro',
};

export const CPAY_RESPONSE_STATUS = {
  DUPLICATE: 'DUPLICATE',
  OK: 'ok',
};

export const PAYMENT_OPTIONS: { [key: string]: AccountType } = {
  FINANCING: 'CurrencyFinance',
  PROCESSING: 'CurrencyPay',
};

export enum pages {
  PrimaryOwnerContact = 1,
  PaymentOptions,
  AccountType,
  AnnualCreditCardSales,
  BusinessInformation,
  BusinessStatement,
  LegalBusinessInfo,
  MoreBusinessInformation,
  PrimaryOwnership,
  PrimaryOwnerPersonal,
  AdditionalOwnership,
  AdditionalOwnerContact,
  AdditionalOwnershipPersonal,
  BankingInformation,
  FirearmsComplianceAck,
  Terminal,
  Terms,
  SetLogin,
  Success,
  Feedback,
}
