import Axios from 'axios';
import { withLDConsumer } from 'ldclient-react';
import React from 'react';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { ISignUpForm } from '../../../interfaces';
import * as mainStyles from '../../../styles/form.css';
import { shapeEmail } from '../../../utility/helper';
import { validateEmail } from '../../../utility/validation';
import { Button } from '../../ui/Button';
import PhoneInput from '../Inputs/Phone';
import { Norton } from '../Norton';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  location: any;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IFeedbackState {
  firstName: string;
  lastName: string;
  companyName: string
  email: string;
  emailError: string;
  phone: string;
  phoneError: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  message: string;
  valid: boolean;
  showRequired: boolean;
  requiredMsg: string;
  nextDisabled: boolean;
  showLoading: boolean;
  submitError: string;
}

export class Feedback extends React.Component<
  IMerchantSignupProps,
  IFeedbackState
> {
  state: IFeedbackState = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    emailError: '',
    phone: '',
    phoneError: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    message: '',
    requiredMsg: '*Required',
    showRequired: false,
    valid: false,
    nextDisabled: false,
    showLoading: false,
    submitError: '',
  };

  componentDidMount() {}

  handleFNameChange = (event: any) => {
    this.setState({ firstName: event.target.value });
    return true;
  };

  handleLNameChange = (event: any) => {
    this.setState({ lastName: event.target.value });
    return true;
  };

  handleCompNameChange = (event: any) => {
    this.setState({ companyName: event.target.value });
    return true;
  };

  handleEmailChange = async (event: any) => {
    let result = false;
    const email = event.target.value;
    this.setState({ email });
    if (!email) {
      this.setState({
        emailError: '',
        valid: false,
      });
      return result;
    }
    if (validateEmail(email)) {
        this.setState({ emailError: '', nextDisabled: false });
        result = true;
    } else {
      this.setState({ emailError: '* Invalid email', nextDisabled: true });
    }
    this.inputValidation(this.state);
    return result;
  };

  handlePhoneChange = ({
    value,
    formattedValue,
  }: {
    value: string;
    formattedValue: string;
  }) => {
    let result = false;
    if (!value) {
      this.setState({ phone: value, phoneError: '', valid: false });
    } else if (value?.length >= 10) {
      this.setState({ phone: formattedValue, phoneError: '', valid: true });
      result = true;
    } else {
      this.setState({
        phone: formattedValue,
        phoneError: '* Invalid phone',
        valid: false,
      });
    }
    this.inputValidation(this.state);
    return result;
  };

  handleStreetChange = (event: any) => {
    this.setState({ street: event.target.value });
    return true;
  };

  handleCityChange = (event: any) => {
    this.setState({ city: event.target.value });
    return true;
  };

  handleStateChange = (event: any) => {
    this.setState({ state: event.target.value });
    return true;
  };

  handlePostalCodeChange = (event: any) => {
    this.setState({ postalCode: event.target.value });
    return true;
  };

  handleMessageChange = (event: any) => {
    let result = false;
    if (!event.target.value) {
      this.setState({ message: event.target.value, valid: false });
    } else {
      this.setState({ message: event.target.value, valid: true });
      result = true;
    }
    return result;
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    let result = false;
    await this.inputValidation(this.state);
    if (this.state.valid) {
      const { firstName, lastName, companyName, email, phone, street, city, state, postalCode, message } = this.state;
      const purePhone = phone ? phone.replace(/-|_/g, '') : '';
      this.setState({ showLoading: true });
      const { data } = await Axios.post(`/api/currencypay/feedback`, { firstName, lastName, companyName, email: shapeEmail(email), phone: purePhone, street, city, state, postalCode, message });
      this.setState({ showLoading: false });
      if (data.error) {
        if (data.error?.error?.message) 
          this.setState({ submitError: data.error?.error?.message });
          result = false;
      } 
      else if (this.state.submitError)
        this.setState({ submitError: '' });
      this.props.nextPage();
      result = true;
    } else {
      this.setState({
        showRequired: true,
      });
      result = false;
    }
    return result;
  };

  inputValidation = async (feedbackState: IFeedbackState) => {
    const { email, phone, message, emailError, phoneError } = feedbackState;
    if (!email || !phone || !message || emailError || phoneError) {
      this.setState({ valid: false, showRequired: true  });
      return false;
    }
    this.setState({ valid: true });
    return true;
  };

  render() {
    const { emailError, phoneError, requiredMsg, submitError } = this.state;
    return (
      <>
        <TitleSubtitle
          {...this.props}
          title='Let&rsquo;s get started.'
          subtitle='CurrencyPay&apos;s payment platform is integrated with multiple payment processing providers, such as PayPal, Global Payments, Square and CardConnect for processing payments online.'
          subtitle2='These strategic payment providers support buying and selling heavy equipment, machinery and other large ticket items, as well as online auction payment processing.'
        />
        {this.state.submitError && (
          <div className={mainStyles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{submitError}</h6>
          </div>
        )}
        <form>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={100}
              minLength={2}
              placeholder='First name'
              value={this.state.firstName}
              onChange={this.handleFNameChange}
            />
          </div>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={100}
              placeholder='Last name'
              value={this.state.lastName}
              onChange={this.handleLNameChange}
            />
          </div>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={100}
              placeholder='Company name'
              value={this.state.companyName}
              onChange={this.handleCompNameChange}
            />
          </div>
          <div className='input-group h-4'>
            <input
              className={`input form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              placeholder='Contact email'
              maxLength={100}
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
          <div className={mainStyles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{emailError}</h6>
          </div>
          {!this.state.email && this.state.showRequired && (
            <div className={mainStyles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          <div className='input-group h-4'>
            <PhoneInput
              className={`form-control input-lg pl-4 my-3 text-left bfh-phone ${mainStyles.inputfield}`}
              id={'businessPhone'}
              onChange={this.handlePhoneChange}
              placeholder='Phone number'
              required={true}
              value={this.state.phone}
            />
          </div>
          <div className={mainStyles.msg}>
            <h6 className='text-danger ml-1 mb-0'>{phoneError}</h6>
          </div>
          {!this.state.phone &&
            !this.state.phoneError &&
            this.state.showRequired && (
              <div className={mainStyles.msg}>
                <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
              </div>
            )}
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={100}
              placeholder='Address'
              value={this.state.street}
              onChange={this.handleStreetChange}
            />
          </div>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={50}
              placeholder='City'
              value={this.state.city}
              onChange={this.handleCityChange}
            />
          </div>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={50}
              placeholder='State'
              value={this.state.state}
              onChange={this.handleStateChange}
            />
          </div>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='text'
              maxLength={10}
              placeholder='Postal Code'
              value={this.state.postalCode}
              onChange={this.handlePostalCodeChange}
            />
          </div>
          <div className='input-group h-4'>
            <input
              className={`form-control input-lg pl-4 mt-3 mb-2 text-left ${mainStyles.inputfield}`}
              type='textarea'
              maxLength={1000}
              placeholder='Message'
              value={this.state.message}
              onChange={this.handleMessageChange}
            />
          </div>
          {!this.state.message &&
            this.state.showRequired && (
              <div className={mainStyles.msg}>
                <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
              </div>
          )}
          <div className={mainStyles.buttonContainer}>
            <div className={mainStyles.prev} />
            <div className={mainStyles.next}>
              <Button
                onClick={this.handleSubmit}
                title='Submit'
                type='primary'
                disabled={this.state.nextDisabled}
              />
            </div>
          </div>
          <Norton />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(Feedback));
