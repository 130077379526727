import { join } from 'path';

export const PORT = process.env.PORT || 8080;
export const NODE_ENV = process.env.NODE_ENV;
export const WEBPACK_OUTPUT_DIR = join(__dirname, '../../dist');
export const IMAGES_DIR = join(__dirname, '../../images');

export const INSTRUMENTATION_KEY =
  process.env.APPINSIGHTS_INSTRUMENTATIONKEY || 'NA';

export const GOOGLE_PLACES_API_KEY = process.env.GOOGLE_PLACES_API_KEY || 'NA';

export const currencypayApi = {
  uri: process.env.CURRENCYPAY_API
    ? `${process.env.CURRENCYPAY_API}`
    : `https://currencypayapi-staging.azurewebsites.net`,
};
