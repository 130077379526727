import React from 'react';
import * as styles from '../../../styles/form.css';

const Footer = () => {
  const today = new Date();
  return (
    <div className={`text-center ${styles.footer}`}>
      <span>&copy; 2008-{today.getFullYear()} Express Tech-Financing</span> |{' '}
      <a
        target='_blank'
        rel='noopener noreferrer'
        className='font-weight-bold'
        href='/merchant/privacy'
      >
        Privacy
      </a>{' '}
      |{' '}
      <a
        target='_blank'
        rel='noopener noreferrer'
        className='font-weight-bold'
        href='/merchant/terms'
      >
        Terms of Use
      </a>
    </div>
  );
};

export default Footer;
