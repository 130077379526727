import React, { FunctionComponent } from 'react';

export interface INumericInput {
  className: string;
  onChange: (value: string) => void;
  maxLength: number;
  minLength: number;
  placeholder: string;
  value: string;
}

const acceptsOnly = /^[0-9]*$/;

const NumericInput: FunctionComponent<INumericInput> = ({
  className,
  onChange,
  maxLength,
  minLength,
  placeholder,
  value,
}) => {
  const _onChange = ({ target: { value } }: { target: { value: string } }) => {
    if (value && (!acceptsOnly.test(value) || value.length > maxLength)) {
      return;
    }
    onChange(value);
  };
  return (
    <input
      className={className}
      type='text'
      minLength={minLength}
      maxLength={maxLength}
      pattern='\d*'
      placeholder={placeholder}
      value={value}
      onChange={_onChange}
    />
  );
};

export default NumericInput;
