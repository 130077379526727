import Axios from 'axios';
import { withLDConsumer } from 'ldclient-react';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions, submitStatusActions } from '../../../actions';
import { ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import { CPAY_RESPONSE_STATUS } from '../../../utility/constants';
import { formatPayload } from '../../../utility/helper';
import {
  isNotAlphaNumeric,
  validatePassword,
} from '../../../utility/validation';
import { Loading } from '../../Loading';
import { Button } from '../../ui/Button';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
  submitStatusActions: any;
}

export interface ISetLoginState {
  confirmPassword: string;
  username: string;
  password: string;
  passwordError: string;
  serverError: any;
  confirmPasswordError: string;
  usernameError: string;
  valid: boolean;
  showRequired: boolean;
  requiredMsg: string;
  showLoading: boolean;
}

export const serverErrMsg = () => {
  return (
    <p>
      Oops! We've encountered an error. Please try again or contact us at{' '}
      <a href='tel:+18447247376'>(844) 724-7376</a>.
    </p>
  );
};

export class SetLogin extends React.Component<
  IMerchantSignupProps,
  ISetLoginState
> {
  state: ISetLoginState = {
    confirmPassword: '',
    confirmPasswordError: '',
    password: '',
    passwordError: '',
    requiredMsg: '*Required',
    serverError: '',
    showLoading: false,
    showRequired: false,
    username: '',
    usernameError: '',
    valid: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (this.props.form) {
      const { username, password } = this.props.form;
      this.setState({
        password,
        username,
      });
      this.inputValidation({ ...this.state, password, username });
    }
  }
  handleTypeChange = (id: string) => {
    if (id) {
      const target = document.getElementById(id) as HTMLInputElement;
      if (target && target.type) {
        if (target.type === 'password') {
          target.type = 'text';
        } else {
          target.type = 'password';
        }
        return true;
      }
    }
    return false;
  };

  handleUsernameChange = (event: any) => {
    this.setState({
      username: event.target.value ? event.target.value.toLowerCase() : '',
    });
    if (isNotAlphaNumeric(this.state.username)) {
      this.setState({ usernameError: '* Alphanumeric values only' });
    } else {
      this.setState({ usernameError: '' });
    }
    if (!event.target.value) {
      this.setState({
        valid: false,
      });
      return false;
    }
    this.inputValidation({
      ...this.state,
      username: event.target.value ? event.target.value.toLowerCase() : '',
    });
    return true;
  };

  handlePasswordChange = (event: any) => {
    this.setState({ password: event.target.value });
    if (!event.target.value) {
      this.setState({
        valid: false,
      });
      return false;
    }
    this.inputValidation({ ...this.state, password: event.target.value });
    return true;
  };

  handleconfirmPasswordChange = (event: any) => {
    this.setState({ confirmPassword: event.target.value });
    if (!event.target.value) {
      this.setState({
        valid: false,
      });
      return false;
    }
    this.inputValidation({
      ...this.state,
      confirmPassword: event.target.value,
    });
    return true;
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();
    await this.inputValidation(this.state);
    if (this.state.valid) {
      const { username, password } = this.state;
      const { form } = this.props;
      this.setState({ showLoading: true });
      const payload = formatPayload(
        { ...form, username, password },
        this.props.flags,
        this.props.preloadedFlags,
      );
      const { data } = await Axios.post(`/api/currencypay/merchant`, payload);

      if (data.status === CPAY_RESPONSE_STATUS.DUPLICATE) {
        this.props.submitStatusActions.fetchSubmitStatus(data.status);
      }

      this.setState({ showLoading: false });
      if (data.error) {
        ReactGA.event({
          action: 'flowSubmit',
          category: 'cpaySignupFlow',
          label: 'submitError',
        });
      }
      if (data.error && data.error && data.error.statusCode === 409) {
        this.setState({
          serverError: '',
          usernameError: 'Username already in use. Try again!',
        });
        return false;
      } else if (data.error && data.error && data.error.statusCode !== 409) {
        this.setState({
          serverError: serverErrMsg(),
          usernameError: '',
        });
        return false;
      }
      this.props.nextPage({ ...form, username, password });
      return true;
    } else {
      ReactGA.event({
        action: 'flowSubmit',
        category: 'cpaySignupFlow',
        label: 'submit',
      });

      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  inputValidation = async (state: ISetLoginState) => {
    const { username, password, confirmPassword } = state;
    if (isNotAlphaNumeric(username)) {
      this.setState({
        valid: false,
      });
    }
    if (!password) {
      this.setState({
        valid: false,
      });
      return false;
    }
    if (!validatePassword(password)) {
      this.setState({
        passwordError: '* Password must meet below requirements',
        valid: false,
      });
      return false;
    }
    this.setState({
      passwordError: '',
    });
    if (!confirmPassword) {
      this.setState({
        valid: false,
      });
      return false;
    }
    if (password !== confirmPassword) {
      this.setState({
        confirmPasswordError: '* Passwords must match',
        valid: false,
      });
      return false;
    }
    this.setState({
      confirmPasswordError: '',
    });
    if (
      !isNotAlphaNumeric(username) &&
      password &&
      confirmPassword &&
      password === confirmPassword
    ) {
      this.setState({
        confirmPasswordError: '',
        passwordError: '',
        valid: true,
      });
      return true;
    }
  };

  render() {
    const {
      passwordError,
      confirmPasswordError,
      serverError,
      showLoading,
      usernameError,
    } = this.state;
    return (
      <>
        {showLoading ? <Loading /> : ''}
        <TitleSubtitle title='Create your account.' />
        <form>
          <div>
            <h6 className={`text-danger ml-5 ${styles.msg}`}>{serverError}</h6>
          </div>
          <div className='input-group h-4'>
            <DebounceInput
              className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${styles.inputfield}`}
              type='text'
              maxLength={50}
              minLength={2}
              debounceTimeout={100}
              placeholder='Username'
              value={this.state.username}
              onChange={this.handleUsernameChange}
            />
          </div>
          <div className={styles.msg}>
            <h6 className={`text-danger ml-1 mb-0`}>{usernameError}</h6>
          </div>
          {!this.state.username && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>
                {this.state.requiredMsg}
              </h6>
            </div>
          )}
          <div className='input-group h-4'>
            <DebounceInput
              id='passwordInput'
              className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${styles.inputfield}`}
              type='password'
              maxLength={100}
              minLength={1}
              debounceTimeout={500}
              placeholder='Password'
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
            <i
              className={`fas fa-eye-slash ${styles.eyes}`}
              onClick={this.handleTypeChange.bind(this, 'passwordInput')}
            />
          </div>
          {!this.state.password && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>
                {this.state.requiredMsg}
              </h6>
            </div>
          )}
          <div className='input-group h-4'>
            <DebounceInput
              id='confirmPwInput'
              className={`form-control input-lg mt-3 mb-2 pl-4 text-left ${styles.inputfield}`}
              type='password'
              maxLength={100}
              minLength={1}
              debounceTimeout={500}
              placeholder='Confirm password'
              value={this.state.confirmPassword}
              onChange={this.handleconfirmPasswordChange}
            />
            <i
              className={`fas fa-eye-slash ${styles.eyes}`}
              onClick={this.handleTypeChange.bind(this, 'confirmPwInput')}
            />
          </div>
          {!this.state.confirmPassword && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>
                {this.state.requiredMsg}
              </h6>
            </div>
          )}
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {passwordError}
            </h6>
          </div>
          <div>
            <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
              {confirmPasswordError}
            </h6>
          </div>
          <div className='d-block'>
            <h4 className={`${styles.msg} ${styles.textSecondary}`}>
              Password must contain at least 8 letters, 1 uppercase, 1
              lowercase, 1 number, and 1 special character.
            </h4>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={7} incomplete={0} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
    submitStatus: state.submitStatusReducer,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
    submitStatusActions: bindActionCreators(submitStatusActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(SetLogin));
