import LoggerClient from '@currency/logger-client';
import { ILoggerClient } from './logger-interfaces';

let logger: LoggerClient;

export default (INSTRUMENTATION_KEY: string): ILoggerClient => {
  if (!logger) {
    // SETUP SINK CONFIGURATIONS
    const config = {
      cloudRoleName: process.env.KUBE ? 'pay-signup' : 'currencyPaySignUp',
      instrumentationKey: INSTRUMENTATION_KEY,
    };
    logger = LoggerClient.getInstance(config);
  }
  return logger;
};
