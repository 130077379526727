import isClient from '../is-client';

/*
	See README for usage instructions
*/

export default async (LAUNCH_DARKLY: any) => {
  let createFFModule: any;

  if (isClient()) {
    createFFModule = await import('./client');
  } else {
    createFFModule = await import('./server');
  }

  return createFFModule.default(LAUNCH_DARKLY);
};
