import React from 'react';
import * as styles from '../../../styles/form.css';

interface IProgressDotsProps {
  complete: number;
  incomplete: number;
}

const ProgressDots = (props: IProgressDotsProps) => {
  const renderCompleteDots = (dots: number) => {
    const result = [];
    for (let i = 0; i < dots; i++) {
      result.push(
        <span key={i} className={`${styles.dot} ${styles.bluedot}`} />,
      );
    }
    return result;
  };
  const renderInCompleteDots = (dots: number) => {
    const result = [];
    for (let i = 0; i < dots; i++) {
      result.push(<span key={i} className={styles.dot} />);
    }
    return result;
  };
  return (
    <div className={`text-center ${styles.dots}`}>
      {renderCompleteDots(props.complete)}
      {renderInCompleteDots(props.incomplete)}
    </div>
  );
};

export default ProgressDots;
