import Axios from 'axios';
import { withLDConsumer } from 'ldclient-react';
import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import redux, { bindActionCreators } from 'redux';
import { formActions } from '../../../actions';
import { IEntity, ISignUpForm } from '../../../interfaces';
import * as styles from '../../../styles/form.css';
import {
  employeesList,
  formatEin,
  industryList,
  monthList,
  sendAbandon,
  shapeUtility,
  yearList,
} from '../../../utility/helper';
import { Button, Select } from '../../ui';
import { ProgressDots } from '../ProgressDots';
import { TitleSubtitle } from '../TitleSubtitle';

interface IMerchantSignupProps {
  error?: Error | null;
  flags?: any;
  form: ISignUpForm;
  formActions: any;
  nextPage: any;
  previousPage: any;
  preloadedFlags: any;
  processEnv: any;
}

interface IMoreBusinessInformationState {
  businessIndustry: string;
  ein: string;
  einError: string;
  isSoleProp: boolean;
  startMonth: string;
  startYear: string;
  numOfEmployees: string;
  accountType: string;
  valid: boolean;
  shouldNotRequireEIN: boolean;
  showRequired: boolean;
  requiredMsg: string;
  futureDateError: string;
}

const SOLEPROPRIETOR = 'Sole Proprietor';

export class MoreBusinessInformation extends Component<
  IMerchantSignupProps,
  IMoreBusinessInformationState
> {
  state: IMoreBusinessInformationState = {
    accountType: '',
    businessIndustry: '',
    ein: '',
    einError: '',
    futureDateError: '',
    isSoleProp: false,
    numOfEmployees: '',
    requiredMsg: '*Required',
    shouldNotRequireEIN: false,
    showRequired: false,
    startMonth: '',
    startYear: '',
    valid: false,
  };

  componentDidMount() {
    this.props.formActions.fetchForm();
    if (this.props.form?.entity) {
      const { entity } = this.props.form;
      const shouldNotRequireEIN = this.shouldNotRequireEIN(
        entity.type === SOLEPROPRIETOR,
        entity.numOfEmployees,
      );
      if (entity) {
        const fEIN = entity.ein ? formatEin(entity.ein) : '';
        let updateState = {} as IMoreBusinessInformationState;
        this.setState({
          accountType: entity.type ?? '',
          businessIndustry: entity.industry ?? '',
          ein: fEIN,
          isSoleProp: entity.type === SOLEPROPRIETOR,
          numOfEmployees: entity.numOfEmployees || '',
          shouldNotRequireEIN,
        });
        updateState = {
          ...this.state,
          accountType: entity.type ?? '',
          businessIndustry: entity.industry ?? '',
          ein: fEIN,
          isSoleProp: entity.type === SOLEPROPRIETOR,
          numOfEmployees: entity.numOfEmployees ?? '',
          shouldNotRequireEIN,
        };
        if (entity.merchant) {
          const { established } = entity.merchant;
          const startDate = new Date(established);
          const sMonth = startDate.getUTCMonth();
          const sYear = startDate.getUTCFullYear();
          const currentYear = new Date().getUTCFullYear();
          this.setState({
            startMonth: sMonth >= 0 && sMonth < 12 ? sMonth.toString() : '',
            startYear:
              sYear >= 1870 && sYear <= currentYear ? sYear.toString() : '',
          });
          updateState = {
            ...updateState,
            startMonth: sMonth >= 0 && sMonth < 12 ? sMonth.toString() : '',
            startYear:
              sYear >= 1870 && sYear <= currentYear ? sYear.toString() : '',
          };
        }
        this.inputValidation({
          ...this.state,
          ...updateState,
        });
      }
    }
  }

  updateForm = (state: IMoreBusinessInformationState) => {
    const {
      businessIndustry,
      startMonth,
      startYear,
      numOfEmployees,
      ein,
      shouldNotRequireEIN,
    } = state;
    const pureEIN = ein ? ein.replace(/-/g, '') : '';
    const { form } = this.props;
    const entity: IEntity = shapeUtility(form);
    if (startYear && startMonth) {
      entity.merchant.established = new Date(
        parseInt(startYear, 10),
        parseInt(startMonth, 10),
        1,
      ).toISOString();
    }
    entity.industry = businessIndustry;
    // null out ein when not required - to preserve SSN overwrite
    entity.ein = shouldNotRequireEIN ? '' : pureEIN;
    entity.numOfEmployees = numOfEmployees;
    return { ...form, entity };
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    if (this.state.valid) {
      const updateForm: ISignUpForm = this.updateForm(this.state);
      sendAbandon(updateForm, this.props.flags, this.props.preloadedFlags);
      this.props.nextPage(updateForm);
      return true;
    } else {
      this.setState({
        showRequired: true,
      });
      return false;
    }
  };

  handleBizStartMonthChange = (event: any) => {
    const month = parseInt(event.target.value, 10);
    this.setState({ startMonth: event.target.value });
    this.inputValidation({ ...this.state, startMonth: event.target.value });
    return month >= 0 && month <= 11;
  };

  handleBizStartYearChange = (event: any) => {
    const currentYear = new Date().getUTCFullYear();
    const year = parseInt(event.target.value, 10);
    this.setState({ startYear: event.target.value });
    this.inputValidation({ ...this.state, startYear: event.target.value });
    return year <= currentYear && year >= 1870;
  };

  handleBizNumofEmployeesChange = (event: any) => {
    const shouldNotRequireEIN = this.shouldNotRequireEIN(
      this.state.isSoleProp,
      event.target.value,
    );
    this.setState({
      numOfEmployees: event.target.value,
      shouldNotRequireEIN,
    });
    this.inputValidation({
      ...this.state,
      numOfEmployees: event.target.value,
      shouldNotRequireEIN,
    });
    return event.target.value !== '0';
  };

  handleEINChange = async (event: any) => {
    const { value: einInput } = event.target;
    let result = false;
    const regExp = /^[0-9|-]+$/;
    if (!einInput || !regExp.test(einInput)) {
      this.setState({
        ein: '',
        einError: '',
        valid: false,
      });
      return result;
    }
    const pureEIN =
      formatEin(einInput)
        ?.replace(/-/g, '')
        ?.substring(0, 9) ?? '';
    const formattedEIN = formatEin(pureEIN);
    this.setState({ ein: formattedEIN });
    if (pureEIN && pureEIN.length === 9) {
      this.setState({ einError: '' });
      const { data } = await Axios.get(`/api/currencypay/ein/${pureEIN}`);
      const isUsedEin = !data || data.matchCount !== 0;
      this.setState({
        einError: isUsedEin
          ? 'EIN already in use. Please try again or login to your account.'
          : '',
        valid: !isUsedEin,
      });
      result = !isUsedEin;
    } else {
      this.setState({ einError: '* Invalid EIN' });
    }
    this.inputValidation({
      ...this.state,
      ein: formattedEIN,
    });
    return result;
  };

  handleBizIndustryChange = (event: any) => {
    this.setState({ businessIndustry: event.target.value });
    this.inputValidation({
      ...this.state,
      businessIndustry: event.target.value,
    });
    return event.target.value !== '0';
  };

  inputValidation = async (state: IMoreBusinessInformationState) => {
    const {
      businessIndustry,
      startMonth,
      startYear,
      ein,
      einError,
      shouldNotRequireEIN,
      futureDateError,
    } = state;
    const pureEIN = ein ? ein.replace(/-/g, '') : '';
    const today = new Date();
    if (startYear && startMonth) {
      const sYear = parseInt(startYear, 10);
      const sMonth = parseInt(startMonth, 10);
      const currentYear = today.getUTCFullYear();
      const enteredDate = Date.UTC(sYear, sMonth);
      if (sMonth < 0 || sMonth >= 12 || sYear > currentYear || sYear < 1870) {
        this.setState({ valid: false });
        return false;
      }
      if (enteredDate > Date.UTC(currentYear, today.getUTCMonth())) {
        this.setState({
          futureDateError: 'Date cannot be in the future.',
          valid: false,
        });
        return false;
      } else if (futureDateError) {
        this.setState({ futureDateError: '' });
      }
    }
    if (!businessIndustry || businessIndustry === '0') {
      this.setState({ valid: false });
      return false;
    }
    // only validate if required
    if (
      !shouldNotRequireEIN &&
      (!pureEIN || pureEIN.length !== 9 || einError)
    ) {
      this.setState({ valid: false });
      return false;
    }
    this.setState({ valid: true });
    return true;
  };

  renderIndustryList = (industries: any) => {
    const renderedIndustryList: any[] = [];
    industries.map((i: any) => {
      renderedIndustryList.push(
        <option key={i.value} value={i.value}>
          {i.industry}
        </option>,
      );
    });
    return renderedIndustryList;
  };

  renderNumOfEmployees = (optionList: any[]) => {
    const result: any[] = [];
    optionList.map(option => {
      result.push(
        <option key={option.label} value={option.value}>
          {option.label}
        </option>,
      );
    });
    return result;
  };

  shouldNotRequireEIN = (
    isSoleProp: boolean,
    numOfEmployees: string,
  ): boolean => {
    return (
      isSoleProp &&
      (!numOfEmployees ||
        numOfEmployees === 'Just Me' ||
        numOfEmployees === '0')
    );
  };

  render() {
    const {
      einError,
      requiredMsg,
      shouldNotRequireEIN,
      futureDateError,
    } = this.state;
    const isSolePropietor = this.props?.form?.entity?.type === SOLEPROPRIETOR;
    return (
      <>
        <TitleSubtitle title='Tell us about your business.' />
        <form>
          <Select
            identifier='month'
            onChange={this.handleBizStartMonthChange}
            options={monthList}
            value={this.state.startMonth}
          />
          {!this.state.startMonth && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          {futureDateError && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{futureDateError}</h6>
            </div>
          )}
          <Select
            identifier='year'
            onChange={this.handleBizStartYearChange}
            options={yearList}
            value={this.state.startYear}
          />
          {!this.state.startYear && this.state.showRequired && (
            <div className={styles.msg}>
              <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
            </div>
          )}
          {/* To-Do: come back and refactor this to use Select Component */}
          <div className='input-group h-4'>
            <select
              id='noOfEmployees'
              className={`form-control input-lg mt-3 mb-2 ${styles.inputfield}`}
              value={this.state.numOfEmployees}
              onChange={this.handleBizNumofEmployeesChange}
            >
              <option value='0' key='0'>
                # of employees (optional)
              </option>
              {isSolePropietor && (
                <option key='Just me' value='Just Me'>
                  Just Me
                </option>
              )}
              {this.renderNumOfEmployees(employeesList)}
            </select>
          </div>
          <Select
            identifier='industry'
            onChange={this.handleBizIndustryChange}
            options={industryList}
            value={this.state.businessIndustry}
          />
          {(!this.state.businessIndustry ||
            this.state.businessIndustry === '0') &&
            this.state.showRequired && (
              <div className={styles.msg}>
                <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
              </div>
            )}
          {!shouldNotRequireEIN && (
            <div id='ein'>
              <div className='input-group h-4'>
                <DebounceInput
                  className={`input form-control input-lg mt-3 mb-2 pl-3 text-left ${styles.inputfield}`}
                  type='text'
                  placeholder='EIN (Tax ID)'
                  minLength={2}
                  debounceTimeout={500}
                  maxLength={10}
                  value={this.state.ein}
                  onChange={this.handleEINChange}
                />
              </div>
              {!this.state.ein && this.state.showRequired && (
                <div className={styles.msg}>
                  <h6 className='text-danger ml-1 mb-0'>{requiredMsg}</h6>
                </div>
              )}
              <div>
                <h6 className={`text-danger ml-1 mb-0 ${styles.msg}`}>
                  {einError}
                </h6>
              </div>
            </div>
          )}
          <div className={styles.buttonContainer}>
            <div className={styles.next}>
              <Button onClick={this.handleSubmit} title='Next' type='primary' />
            </div>
            <div className={styles.prev}>
              <Button
                onClick={this.props.previousPage}
                title='Previous'
                type='secondary'
              />
            </div>
          </div>
          <ProgressDots complete={3} incomplete={4} {...this.props} />
        </form>
      </>
    );
  }
}

export function mapStateToProps(state: any) {
  return {
    form: state.formReducer.form,
    preloadedFlags: state.featureFlags.preloadedFlags,
    processEnv: state.processEnv,
  };
}

export function mapDispatchToProps(dispatch: redux.Dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(MoreBusinessInformation));
