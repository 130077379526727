import React from 'react';
import * as mainStyles from '../styles.css';

export const ThankYou: React.FunctionComponent = () => {
  return (
    <div>
      <h1
        className={`mt-4 ${mainStyles.textPrimary} ${mainStyles.regularWeight} text-center`}
      >
        Thank You!
      </h1>
      <h3
        className={`${mainStyles.textFont} ${mainStyles.textSecondary} mx-5 text-center`}
      >
        A CurrencyPay representative will contact you shortly.
      </h3>
    </div>
  );
};
